import { apiCall } from './api/api'
import { t } from '../common/i18n/index'
import {
  HorarioTallerFront,
  Taller,
  TallerDistance,
  TallerLocation,
} from './types/Taller'
import {
  AllServices,
  SelectedService,
} from './context/reducers/citaPreviaReducer'

export function findLeadByCestaID(cestaID) {
  return apiCall(`${process.env.GATSBY_API_URL}/leads/${cestaID}`)
}

export function deleteAccount(idUser) {
  return apiCall(`${process.env.GATSBY_API_URL}/users/${idUser}`, {
    method: 'DELETE',
    auth: true,
  })
}

export function retrieveAllDropDownOptions(query) {
  return apiCall(`${process.env.GATSBY_API_URL}/tyres-landing?${query}`)
}

export function retrieveTiendas(lat, lon, service) {
  return apiCall(`${process.env.GATSBY_API_URL}/tiendas`, {
    method: 'POST',
    body: JSON.stringify({ lat, lon, service }),
  })
}

interface Talleres {
  talleres?: any
}

export function filterTiendas(
  query: string,
  lat: string | number,
  lon: string | number,
  talleres?: Talleres[]
): Promise<(Taller & TallerLocation & TallerDistance & HorarioTallerFront)[]> {
  return apiCall(`${process.env.GATSBY_API_URL}/tiendas/filter?${query}`, {
    method: 'POST',
    body: JSON.stringify({ lat, lon, talleres }),
  }).then(({ tiendas }) => {
    if (!tiendas.length) return []
    return tiendas
  })
}

export interface SearchByPostCodeResult {
  tiendas: (Taller & TallerLocation & TallerDistance & HorarioTallerFront)[]
  latitud: string
  longitud: string
}
export function searchByPostCode(
  postCode: string,
  forEcommerce: boolean,
  service?: SelectedService,
  moto?: boolean,
  specialService?: string
): Promise<SearchByPostCodeResult> {
  return apiCall(
    moto
      ? `${process.env.GATSBY_API_URL}/taller/moto/localizacion`
      : `${process.env.GATSBY_API_URL}/taller/localizacion`,
    {
      method: 'POST',
      body: JSON.stringify({
        postCode,
        forEcommerce,
        service,
        specialService,
      }),
    }
  ).then((result) => {
    if (!result.tiendas.length) throw Error('No hay talleres en esta ubicación')
    return result
  })
}

export function getNeuMaticosCount(filters) {
  return apiCall(`${process.env.GATSBY_API_URL}/neumaticos/selector/total`, {
    method: 'POST',
    body: JSON.stringify({ filters }),
  })
}

export function retrieveTuSolicitud() {
  throw new Error('This should not be used.')
}

export function requestNeumaticoAvailability(
  email,
  marca,
  modelo,
  modelID,
  productID,
  tyreSpecs,
  device: 'MOBILE' | 'TABLET' | 'DESKTOP'
) {
  return apiCall(`${process.env.GATSBY_API_URL}/neumatico-solicitado`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      marca,
      modelo,
      modelID,
      productID,
      tyreSpecs,
      device,
    }),
  })
}

export function requestNeumaticoMotoAvailability(
  email,
  marca,
  modelo,
  modelID,
  productID,
  tyreSpecs
) {
  return apiCall(`${process.env.GATSBY_API_URL}/neumatico-solicitado-moto`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      marca,
      modelo,
      modelID,
      productID,
      tyreSpecs,
    }),
  })
}

export function retrieveVehicleInformation(matriculaNumber) {
  return apiCall(`${process.env.GATSBY_API_URL}/matricula`, {
    method: 'POST',
    body: JSON.stringify({ matriculaNumber }),
  })
}

export function retrieveVehicleMarcas() {
  return apiCall(`${process.env.GATSBY_API_URL}/matricula/marcas`)
}

export function retrieveVehicleModelos(marca) {
  return apiCall(
    `${process.env.GATSBY_API_URL}/matricula/modelos?marca=${marca}`
  )
}

interface RetrieveVehicleModelosSpecificParams {
  idMarca: number
  modelo: string
  fechaDesde: string
}
interface RetrieveVehicleModelosSpecificResults {
  modelosOptions: {
    label: string
    value: string
  }[]
}
export function retrieveVehicleModelosSpecific(
  vehicleData: RetrieveVehicleModelosSpecificParams
): Promise<RetrieveVehicleModelosSpecificResults> {
  return apiCall(`${process.env.GATSBY_API_URL}/matricula/modelos`, {
    method: 'POST',
    body: JSON.stringify(vehicleData),
  })
}

export function retrieveVehicleMotores(marca, modelo) {
  return apiCall(
    `${process.env.GATSBY_API_URL}/matricula/motores?marca=${marca}&modelo=${modelo}`
  )
}

export function findAreaPrivadaServices() {
  return apiCall(
    `${process.env.GATSBY_API_URL}/talleres-servicios/area-privada`
  )
}

export function retrieveVehicleMotoresByModelo(modelo) {
  return apiCall(`${process.env.GATSBY_API_URL}/matricula/motores`, {
    method: 'POST',
    body: JSON.stringify({ modelo }),
  })
}

export function retrieveVehicleBySpecs(
  marca,
  modelo,
  motor,
  matricula,
  idUser
) {
  return apiCall(`${process.env.GATSBY_API_URL}/matricula/specs`, {
    method: 'POST',
    body: JSON.stringify({
      marca,
      modelo,
      motor,
      matriculaNumber: matricula,
      idUser,
    }),
  })
}

export function addVehicleToUserWs(data, images, idUsuario, matriculaNumber) {
  return apiCall(`${process.env.GATSBY_API_URL}/matricula/add-vehicle-ws`, {
    method: 'POST',
    body: JSON.stringify({
      vehicleData: data,
      images,
      idUsuario,
      matriculaNumber,
    }),
    error: t('alta_vehiculo.error_guardar_vehiculo'),
  })
}

export function addVehicleToUserDb(data, idUsuario, matriculaNumber) {
  return apiCall(`${process.env.GATSBY_API_URL}/matricula/add-vehicle-db`, {
    method: 'POST',
    body: JSON.stringify({ vehicleData: data, idUsuario, matriculaNumber }),
    error: t('alta_vehiculo.error_guardar_vehiculo'),
  })
}

export function findVehicleByModeloMotor(modelo: string, motor: string) {
  return apiCall(
    `${process.env.GATSBY_API_URL}/matricula/modelo/${encodeURIComponent(
      modelo
    )}/motor/${encodeURIComponent(motor)}`
  )
}

export function findClientVehicles(id) {
  return apiCall(`${process.env.GATSBY_API_URL}/vehicles/${id}`)
}

export function deleteVehicleFromUser(idUser, matricula) {
  return apiCall(
    `${process.env.GATSBY_API_URL}/vehicles/${idUser}/${matricula}`,
    {
      method: 'DELETE',
    }
  )
}

export function getRevisionesList(aceiteId, postCode) {
  return apiCall(
    `${process.env.GATSBY_API_URL}/revisiones/turismo/?aceiteId=${aceiteId}`,
    {
      method: 'POST',
      body: JSON.stringify({
        postCode,
      }),
    }
  )
}

export function getKitOzonoList() {
  return apiCall(`${process.env.GATSBY_API_URL}/kits-ozono`)
}

export function addCallback(phone, origen, device, userAgent, taller) {
  return apiCall(`${process.env.GATSBY_API_URL}/callback`, {
    method: 'POST',
    body: JSON.stringify({
      telefono: phone,
      origen,
      device,
      userAgent,
      taller: taller || null,
    }),
    error: t('callback.error'),
  })
}

export function retrieveFavouriteTaller(userID) {
  return apiCall(`${process.env.GATSBY_API_URL}/users/${userID}/talleres`, {
    auth: true,
  })
}

export function retrieveFacturas(userID) {
  return apiCall(`${process.env.GATSBY_API_URL}/users/${userID}/facturas`, {
    auth: true,
  })
}

export function retrievePedidos(userID) {
  return apiCall(`${process.env.GATSBY_API_URL}/users/${userID}/pedidos`, {
    auth: true,
  })
}

export function requestNewPassword(email, domain) {
  return apiCall(`${process.env.GATSBY_API_URL}/users/forgot-password`, {
    method: 'POST',
    body: JSON.stringify({ email, domain }),
  })
}

export function checkToken(token) {
  return apiCall(`${process.env.GATSBY_API_URL}/users/reset-password/${token}`)
}

export function resetPassword(password, confirmPassword, token) {
  return apiCall(
    `${process.env.GATSBY_API_URL}/users/reset-password/${token}`,
    {
      method: 'PUT',
      body: JSON.stringify({ password, confirmPassword }),
    }
  )
}

export const getAllTallerServices = (): Promise<AllServices> => {
  return apiCall(`${process.env.GATSBY_API_URL}/talleres-servicios`)
}

export default {
  findLeadByCestaID,
  deleteAccount,
  retrieveAllDropDownOptions,
  retrieveTiendas,
  filterTiendas,
  searchByPostCode,
  getNeuMaticosCount,
  retrieveTuSolicitud,
  requestNeumaticoAvailability,
  requestNeumaticoMotoAvailability,
  retrieveVehicleInformation,
  retrieveVehicleMarcas,
  retrieveVehicleModelos,
  retrieveVehicleModelosSpecific,
  retrieveVehicleMotores,
  retrieveVehicleMotoresByModelo,
  retrieveVehicleBySpecs,
  addVehicleToUserWs,
  addVehicleToUserDb,
  findVehicleByModeloMotor,
  findClientVehicles,
  deleteVehicleFromUser,
  getRevisionesList,
  addCallback,
  retrieveFavouriteTaller,
  retrieveFacturas,
  retrievePedidos,
  requestNewPassword,
  resetPassword,
  checkToken,
  findAreaPrivadaServices,
  getKitOzonoList,
}
