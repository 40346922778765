import * as api from '../../api/cesta'
import { ITime } from '../../api/citaPrevia'
import { AddProductOrigin } from '../../types/Cesta'
import { CategoriaNeumatico } from '../../types/Neumatico'
import { SelectedTaller } from '../../types/Taller'
import { selectNeumatico } from '../../utils/gtmCestaReporter'
import {
  CestaDiscount,
  CestaState,
  Product,
  Service,
} from '../reducers/cestaReducer'
import { Vehicle } from '../../types/Vehiculo'
import { Revision } from '../../types/Revision'

export const SET_STEP = 'CESTA::SET_STEP'
export const REMOVE_PRODUCT = 'CESTA::REMOVE_PRODUCT'
export const REMOVE_PRODUCT_OK = 'CESTA::REMOVE_PRODUCT::OK'
export const UPDATE_QUANTITY = 'CESTA::UPDATE_QUANTITY'
export const CHECK_SERVICE = 'CESTA::CHECK_SERVICE'
export const SWITCH_SERVICE = 'CESTA::SWITCH_SERVICE'
export const ADD_PRODUCT = 'CESTA::ADD_PRODUCT' // Triggers call to api
export const ADD_PRODUCT_OK = 'CESTA::ADD_PRODUCT_OK' // Loads product info and adds it
export const ADD_PRODUCT_FAILED = 'CESTA::ADD_PRODUCT_FAILED' // !?
export const UPDATE_PRODUCT = 'CESTA::UPDATE_PRODUCT' // Triggers call to api

export const LOAD_CESTA = 'CESTA::LOAD'
export const LOAD_CESTA_OK = 'CESTA::LOAD_OK'
export const LOAD_CESTA_FAILED = 'CESTA::LOAD_FAILED'

export const UPDATE_CESTA = 'CESTA::UPDATE'
export const UPDATE_CESTA_OK = 'CESTA::UPDATE_OK'
export const UPDATE_CESTA_FAILED = 'CESTA::UPDATE_FAILED'

export const ADD_CESTA_SELECTED_CP = 'CESTA::ADD_CESTA_SELECTED_CP'
export const ADD_CESTA_SELECTED_TALLER = 'CESTA::ADD_CESTA_SELECTED_TALLER'
export const ADD_CESTA_SELECTED_DATE = 'CESTA::ADD_SELECTED_DATE'

export const ADD_CESTA_SELECTED_TIME = 'CESTA::ADD_SELECTED_TIME'
export const ADD_CESTA_SELECTED_TIME_OK = 'CESTA::ADD_SELECTED_TIME_OK'
export const ADD_CESTA_SELECTED_TIME_FAILED = 'CESTA::ADD_SELECTED_TIME_FAILED'

export const SEND_UNAVAILABLE = 'CESTA::SEND_UNAVAILABLE'
export const SEND_UNAVAILABLE_OK = 'CESTA::SEND_UNAVAILABLE_OK'
export const SEND_UNAVAILABLE_FAILED = 'CESTA::SEND_UNAVAILABLE_FAILED'

export const ADD_CESTA_ORDER_NUMBER = 'CESTA::ADD_ORDER_NUMBER'

export const INIT_CESTA = 'CESTA::INITIALIZE_CESTA'

export const ADD_CESTA_CESTAID = 'ADD_CESTA_CESTAID'
export const ADD_CESTA_STEP = 'ADD_CESTA_STEP'
export const CLEAR_CESTA_STATE = 'CLEAR_CESTA_STATE'
export const ADD_NEWVEHICLE_DATA = 'ADD_NEWVEHICLE_DATA'

export const ADD_PAYMENT_TYPE = 'CESTA:ADD_PAYMENT_TYPE'

export const ADD_MATRICULA = 'CESTAS::ADD_MATRICULA'

export const ADD_CODIGO_PROMOCION = 'CESTAS::ADD_CODIGO_PROMOCION'

export const RESET_ERROR = 'CESTA::RESET_ERROR'

export const RESET_CESTA = 'CESTA::RESET_CESTA'

export const ADD_FROM_EXTERNAL_CESTA = 'CESTA::ADD_FROM_EXTERNAL'
export const ADD_SEARCH_TOKEN = 'CESTA::ADD_SEARCH_TOKEN'

export const ADD_CESTA_SELECTED_REVISION = 'CESTA::ADD_CESTA_SELECTED_REVISION'
// TODO: Cargar cesta on login

export function setStep(step: number) {
  return {
    type: SET_STEP,
    payload: step,
  }
}

export function updateQuantity(
  id_navision: string,
  quantity: number,
  typeOfChange: 'add' | 'remove',
  quantityDifference: number
) {
  return {
    type: UPDATE_QUANTITY,
    payload: {
      id_navision,
      quantity,
      quantityDifference,
      typeOfChange,
    },
  }
}

export function checkService(
  id_navision: string,
  isCurrentlySelected: boolean
) {
  return {
    type: CHECK_SERVICE,
    payload: { id_navision, isCurrentlySelected },
  }
}

export function switchService(id_navision: string) {
  return {
    type: SWITCH_SERVICE,
    payload: { id_navision },
  }
}

export function removeProduct(id_navision: string, product: Product) {
  return {
    type: REMOVE_PRODUCT,
    payload: { id_navision, product },
  }
}

export interface ProductInfoNeumatico {
  id: string
  type: 'neumatico'
  categoria: CategoriaNeumatico | 'Budget' | 'Quality' | 'Premium'
  marca: string
}

export interface ProductInfoRevision {
  id: string
  type: 'revision'
  revisionPlan: 'Basic' | 'Plus' | 'Premium' | 'Confort'
}

export interface ProductInfoCamara {
  id: string
  marca: string
  type: 'camara' | 'mousse'
}

export interface ProductInfoOtros {
  id: string
  type: 'otros'
}

export interface ProductInfoRevisionMoto {
  id: string
  type: 'revision_moto'
}

export interface ProductInfoBateria {
  id: string
  type: 'bateria'
}

export type ProductInfo =
  | ProductInfoNeumatico
  | ProductInfoRevision
  | ProductInfoCamara
  | ProductInfoOtros
  | ProductInfoRevisionMoto
  | ProductInfoBateria

/**
 * Añade un producto a la cesta.
 *
 * @param product producto adido
 * @param origin página desde la que estamos añadiendo (necesario como mínimo para reportar a Analytics <> ecommerce)
 * @param vehicle vehiculo al que refiere el producto (OPCIONAL)
 */
export function addProduct(
  product: ProductInfo,
  origin: AddProductOrigin,
  vehicle?: Vehicle
) {
  selectNeumatico(product)
  return {
    type: ADD_PRODUCT,
    payload: { idProduct: product.id, origin, vehicle },
  }
}

export function addProductOk(
  info,
  origin: AddProductOrigin,
  vehicle?: Vehicle
) {
  return {
    type: ADD_PRODUCT_OK,
    payload: {
      ...info,
      origin,
      vehicle,
    },
  }
}

export function addProductFailed(message) {
  return {
    type: ADD_PRODUCT_FAILED,
    payload: message,
  }
}

interface LoadCestaSuccess {
  generaFactura: boolean
  products: Product[]
  services: Service[]
  discount: CestaDiscount[]
}

export function loadCestaOk(info: LoadCestaSuccess) {
  return { type: LOAD_CESTA_OK, payload: info }
}

export function setCodigoPostalIntroducido(cp: string) {
  return {
    type: ADD_CESTA_SELECTED_CP,
    payload: { cp },
  }
}
export function updateProducts(selectedProducts: Product[]) {
  return {
    type: UPDATE_PRODUCT,
    payload: { selectedProducts },
  }
}
export function setSelectedTime(selectedProducts: Product[]) {
  return {
    type: ADD_CESTA_SELECTED_TIME,
    payload: { selectedProducts },
  }
}

export function setSelectedTimeOk() {
  return {
    type: ADD_CESTA_SELECTED_TIME_OK,
  }
}

export function setSelectedTaller(selectedTaller: SelectedTaller) {
  return {
    type: ADD_CESTA_SELECTED_TALLER,
    payload: { selectedTaller },
  }
}

export function setSelectedDate(selectedDate: ITime | null) {
  return {
    type: ADD_CESTA_SELECTED_DATE,
    payload: { selectedDate },
  }
}

export function setTimeCesta(
  selectedDate: ITime | null,
  codTienda: string,
  email: string,
  selectedProducts: Product[]
) {
  return (dispatch) => {
    dispatch({ type: ADD_CESTA_SELECTED_TIME, payload: { selectedProducts } })
    api
      .selectTimeCesta(codTienda, selectedDate.date, selectedDate.time, email)
      .then((result) => {
        dispatch({
          type: ADD_CESTA_SELECTED_TIME_OK,
          payload: { result },
        })
      })
      .catch((err) => {
        dispatch({ type: ADD_CESTA_SELECTED_TIME_FAILED, payload: err })
      })
  }
}

export function addCestaOrder(orderNumber: string) {
  return {
    type: ADD_CESTA_ORDER_NUMBER,
    payload: { orderNumber },
  }
}

export function initializeCesta() {
  return { type: INIT_CESTA }
}

export async function deleteCesta(cestaID: string) {
  await api.deleteCesta(cestaID)
}

export function addMatricula(matricula: string) {
  return {
    type: ADD_MATRICULA,
    payload: {
      matricula,
    },
  }
}

export function addCodigoPromocional(codigo_promocion: string) {
  return {
    type: ADD_CODIGO_PROMOCION,
    payload: {
      codigo_promocion,
    },
  }
}

export function addPaymentType(paymentType) {
  return {
    type: ADD_PAYMENT_TYPE,
    payload: { paymentType },
  }
}

export function resetCestaError() {
  return {
    type: RESET_ERROR,
  }
}

export function resetCesta() {
  return {
    type: RESET_CESTA,
  }
}

export function sendUnavailableNeumaticos(unavailableProducts, userInfo) {
  return {
    type: SEND_UNAVAILABLE,
    payload: {
      unavailableProducts,
      userInfo,
    },
  }
}

export function checkIfCestaHasProductsMoto(cestaState: CestaState) {
  const productos = cestaState.products
  return productos.length && productos[0].categoria === 'moto'
}

export function setNewVehicleData(newVehicleData: Vehicle) {
  return {
    type: ADD_NEWVEHICLE_DATA,
    payload: { newVehicleData },
  }
}

export function addFromExternal(productId: string, codTaller: string) {
  return {
    type: ADD_FROM_EXTERNAL_CESTA,
    payload: { productId, codTaller },
  }
}

export function addSearchToken(token: string) {
  return {
    type: ADD_SEARCH_TOKEN,
    payload: {
      token,
    },
  }
}

export function setSelectedRevision(selectedRevision: Revision) {
  return {
    type: ADD_CESTA_SELECTED_REVISION,
    payload: { selectedRevision },
  }
}
