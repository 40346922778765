import { PostRevisionLead } from '../../api/postRevisionLead'

export const REVISION_LEAD = 'REVISION_MOTO::POST_LEAD'
export const REVISION_LEAD_OK = 'REVISION_MOTO::POST_LEAD_OK'
export const REVISION_LEAD_FAILED = 'REVISION_MOTO::POST_LEAD_FAILED'
export const REVISION_LEAD_RESET = 'REVISION_MOTO::POST_LEAD_RESET'

export function revisionLead(data: PostRevisionLead) {
  return { type: REVISION_LEAD, payload: { data } }
}

export function revisionLeadOk() {
  return { type: REVISION_LEAD_OK, payload: {} }
}

export function revisionLeadKo(message: string) {
  return { type: REVISION_LEAD_FAILED, payload: { error: message } }
}
export function revisionLeadReset() {
  return { type: REVISION_LEAD_RESET }
}
