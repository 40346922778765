import {
  GetRevisionModelosApiRequest,
  RevisionModelo,
} from '../../api/getModelosByMarcaAndCilindrada'

export const REVISION_MODELOS = 'REVISION_MOTO::GET_MODELOS'
export const REVISION_MODELOS_OK = 'REVISION_MOTO::GET_MODELOS_OK'
export const REVISION_MODELOS_FAILED = 'REVISION_MOTO::GET_MODELOS_FAILED'
export const REVISION_MODELOS_RESET = 'REVISION_MOTO::GET_MODELOSS_RESET'

export function revisionModelos(data: GetRevisionModelosApiRequest) {
  return { type: REVISION_MODELOS, payload: { data } }
}

export function revisionModelosOk(datos: RevisionModelo[]) {
  return { type: REVISION_MODELOS_OK, payload: datos }
}

export function revisionModelosKo(message) {
  return { type: REVISION_MODELOS_FAILED, payload: { error: message } }
}

export function revisionModelosReset() {
  return { type: REVISION_MODELOS_RESET }
}
