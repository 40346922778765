import {
  all,
  call,
  put,
  race,
  select,
  take,
  takeLatest,
} from 'redux-saga/effects'
import { loadDefaultOptions, loadOpciones } from '../../api/selector'
import {
  InStepDetails,
  Step,
  StepDetails,
} from '../../components/FunnelSelector/SelectorSteps'
import { reportSelectorSteps } from '../../utils/gtmSelectorReporter'
import route from '../../utils/route'
import * as RouteActions from '../actions/routeActions'
import { search } from '../actions/searchActions'
import * as SelectorActions from '../actions/selectorActions'
import { SelectorState } from '../reducers/selectorReducer'
import {
  selectMedidas,
  selectRunflat,
  selectSelector,
  selectTipoVehiculo,
} from '../selectors/selectorSelectors'
import { navigate } from 'gatsby'

function* fetchMedidas() {
  const state = yield select()
  const tipoVehiculo = selectTipoVehiculo(state)
  const medidas = selectMedidas(state)

  // SelectorModal needs to restore the state before opening when the
  // modal is closed without searching again. It does this by dispatching
  // a RESTORE action.
  // Here we need to race between that possible RESTORE and any loadOpciones
  // execution, because otherwise the selector may RESTORE the previous state
  // and only later a running execution of loadOpciones terminates, loading
  // the options for that (now cancelled) selection and messing everything up.
  // The race here cancels any in-progres loadOpciones, averting that crysis.
  const { opciones } = yield race({
    opciones: call(loadOpciones, medidas, selectRunflat(state), tipoVehiculo),
    cancelled: take(SelectorActions.RESTORE),
  })
  if (opciones) {
    const _opts = opciones as Partial<SelectorState> | null
    const medida = Object.keys(_opts)
      .filter((o) => o !== 'runflat')
      .find((m) => _opts[m].value === null)

    if (medida && _opts[medida].options) {
      const selectorState = selectSelector(state)
      const dfault = selectorState[medida].default || 0
      if (_opts[medida].options.find((o) => o.value === dfault)) {
        _opts[medida].default = selectSelector(state)[medida].default
      }
    }
    yield put(SelectorActions.loadOptions(_opts))
  }
}

function* selectMedidasNeumatico() {
  yield fetchMedidas()
  navigate(StepDetails[Step.SHOW_RESULTADOS].routes.desktop)
}

function* fetchDefaults(action: ReturnType<typeof SelectorActions.reset>) {
  const defaults = yield loadDefaultOptions(action.payload.tipoVehiculo)
  if (action.payload.keepValues) {
    action.payload.keepMedidas.forEach((m) => {
      defaults[m.name].value = m.value
    })
  }

  yield put({
    type: SelectorActions.RESET_OK,
    payload: defaults,
  })
}

function* maybeUpdateSearch(
  action: ReturnType<typeof RouteActions.onPreRoute>
) {
  const resultadosPath = route('selector.resultados')
  const { from, to } = action.payload
  const fromResultados = from && from.pathname.startsWith(resultadosPath)
  const toResultados = to && to.pathname.startsWith(resultadosPath)
  if (!fromResultados && toResultados) {
    yield put(search())
  }
}

interface Action {
  type: 'SELECTOR::REPORT_STEP'
  payload: {
    step: InStepDetails
    device: 'mobile' | 'desktop'
  }
}
function* reportSteps(action: Action) {
  const state = yield select()
  const selectorState = selectSelector(state)
  reportSelectorSteps(
    selectorState,
    action.payload.device,
    action.payload.step.step,
    selectorState.marcas
  )
}

function* sagas() {
  return all([
    yield takeLatest(SelectorActions.SELECT_MEDIDA, fetchMedidas),
    yield takeLatest(
      SelectorActions.SELECT_MEDIDAS_NEUMATICO,
      selectMedidasNeumatico
    ),
    yield takeLatest(SelectorActions.RESET, fetchDefaults),
    yield takeLatest(RouteActions.PRE_ROUTE, maybeUpdateSearch),
    yield takeLatest(SelectorActions.REPORT_STEP, reportSteps),
  ])
}

export default sagas
