import {
  SEARCH,
  SEARCH_OK,
  SEARCH_FAILED,
  SearchOptions,
} from '../actions/searchActions'
import { LOGOUT } from '../actions/userActions'
import { SearchResults } from '../../api/search'

export const key = 'search'

export const initialState = {
  loading: false as boolean,
  error: null as null | string,
  query: {
    order: '',
    direction: 'ASC',
    pagination: {
      pageSize: 10,
      page: 0,
    },
  } as SearchOptions,
  results: {
    pages: 0,
    total: 0,
    neumaticos: [],
    token: null,
  } as SearchResults,
}

export type SearchState = typeof initialState

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return { ...initialState }

    case SEARCH:
      return {
        ...state,
        query: action.payload,
        loading: true,
      }

    case SEARCH_OK:
      return {
        ...state,
        loading: false,
        results: action.payload,
      }

    case SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
