import * as api from '../../api/revisiones'
import * as CestaActions from '../actions/cestaActions'
import * as AltaVehiculoActions from '../actions/altaVehiculoActions'
import {
  resetAltaVehiculoLoading,
  setAltaVehiculoLoading,
  setAltaVehiculoStep,
} from './altaVehiculoActions'
import logic from '../../logic'
import { registroLeadMatricula } from '../../api/reports'
import { Aceite, AceiteSeleccionado } from '../../types/Aceite'
import {
  Revision,
  RevisionComingFrom,
  RevisionStatus,
} from '../../types/Revision'
import { Vehicle } from '../../types/Vehiculo'

export const START_OIL_JOURNEY = 'REVISION::START_OIL_JOURNEY'
export const START_OIL_JOURNEY_OK = 'REVISION::START_OIL_JOURNEY_OK'
export const START_FIND_OIL = 'REVISION::START_FIND_OIL'
export const SET_VEHICLE_INFO = 'REVISION::SET_VEHICLE_INFO'
export const SET_OIL_INFO = 'REVISION::SET_OIL_INFO'
export const ADD_POST_CODE = 'REVISION::ADD_POST_CODE'
export const ADD_REVISION_CARINFO = 'ADD_REVISION_CARINFO'
export const ADD_REVISION_CARINFO_OK = 'ADD_REVISION_CARINFO_OK'
export const ADD_REVISION_CARINFO_NO_REVISION =
  'ADD_REVISION_CARINFO_NO_REVISION'
export const ADD_REVISION_CARINFO_FAILED = 'ADD_REVISION_CARINFO_FAILED'
export const ADD_OIL_SELECTION = 'ADD_OIL_SELECTION'
export const ADD_REVISION_COMBUSTIBLE = 'ADD_REVISION_COMBUSTIBLE'
export const CHANGE_REVISION_STEP = 'CHANGE_REVISION_STEP'
export const ADD_SELECTED_REVISION = 'ADD_SELECTED_REVISION'
export const ADD_MATRICULA_DISTANCE = 'ADD_MATRICULA_DISTANCE'
export const ADD_REVISIONES_COMING_FROM = 'ADD_REVISIONES_COMING_FROM'
export const RESET_REVISIONES_STATE = 'RESET_REVISIONES_STATE'
export const RESET_REVISIONES_SUCCESS = 'RESET_REVISIONES_SUCCESS'
export const RESET_REVISIONES_ERROR = 'RESET_REVISIONES_ERROR'
export const RESET_REVISIONES_LOADING = 'RESET_REVISIONES_LOADING'
export const SET_REVISIONES_LOADING = 'SET_RESET_REVISIONES_LOADING'
export const SET_PRESET_DATA = 'SET_PRESET_DATA'

export const SET_CAR_MODELO_MATRICULA = 'SET_CAR_MODELO_MATRICULA'
export const ADD_OIL_SELECTION_INFO = 'ADD_OIL_SELECTION_INFO'

export function startOilJourney() {
  return {
    type: START_OIL_JOURNEY,
  }
}
export function startOilJourneyOK() {
  return {
    type: START_OIL_JOURNEY_OK,
  }
}

export function setVehicleInfo(vehicle: Vehicle) {
  return {
    type: SET_VEHICLE_INFO,
    payload: { vehicle },
  }
}

export function setOilInfo(aceite: Aceite) {
  return {
    type: SET_OIL_INFO,
    payload: { aceite },
  }
}

export function addCarInfoFailed(msg: string) {
  return {
    type: ADD_REVISION_CARINFO_FAILED,
    payload: { err: msg },
  }
}

export function addCarInfoNoRevision(
  status: RevisionStatus,
  fuelType: string | null
) {
  return {
    type: ADD_REVISION_CARINFO_NO_REVISION,
    payload: {
      status,
      combustible: fuelType || '',
    },
  }
}

export function addPostCode(postCode: string) {
  return {
    type: ADD_POST_CODE,
    payload: { postCode },
  }
}

export function startFindOil() {
  return {
    type: START_FIND_OIL,
  }
}
export function setRevisionStep(step: number) {
  return {
    type: CHANGE_REVISION_STEP,
    payload: { step },
  }
}

export function setRevisionComingFrom(comingFrom: RevisionComingFrom) {
  return {
    type: ADD_REVISIONES_COMING_FROM,
    payload: { comingFrom },
  }
}

export function setRevision(id: string) {
  return {
    type: ADD_SELECTED_REVISION,
    payload: { revisionSeleccionada: id },
  }
}

export function setMatriculaDistance(
  matricula: string,
  kms: string,
  postCode?: string
) {
  return {
    type: ADD_MATRICULA_DISTANCE,
    payload: { matricula, kms, postCode },
  }
}

export function setCarInfo(
  matricula: string,
  kms: string,
  vehicleOils: VehicleOil[],
  modelo: string
) {
  return {
    type: ADD_REVISION_CARINFO_OK,
    payload: { matricula, kms, listaAceites: vehicleOils, modelo },
  }
}

export function resetRevisiones() {
  return {
    type: RESET_REVISIONES_STATE,
  }
}

export function resetRevisionSuccess() {
  return {
    type: RESET_REVISIONES_SUCCESS,
  }
}

export function resetRevisionError() {
  return {
    type: RESET_REVISIONES_ERROR,
  }
}

export function setRevisionLoading() {
  return {
    type: SET_REVISIONES_LOADING,
  }
}

export function resetRevisionLoading() {
  return {
    type: RESET_REVISIONES_LOADING,
  }
}

export function setOilSelection(
  aceiteSeleccionado: string,
  revisiones: Revision[]
) {
  return {
    type: ADD_OIL_SELECTION,
    payload: { aceiteSeleccionado, revisiones },
  }
}

export function setOilSelectionInfo(info: AceiteSeleccionado) {
  return {
    type: ADD_OIL_SELECTION_INFO,
    payload: { info },
  }
}

export function setPresetRevisionInfo(
  matricula: string,
  vehicle: Vehicle,
  kms: string,
  aceite: VehicleOil
) {
  return {
    type: SET_PRESET_DATA,
    payload: { matricula, vehicle, kms, aceite },
  }
}

export interface VehicleOil {
  titulo_es: string
  titulo_ca: string
  descripcion_ca: string
  descripcion_es: string
  id_site_revision_aceite: number
  recomendado: number
  tipo_aceite: string
}

interface Response {
  vehicleOils: VehicleOil[]
  newVehicleData: Vehicle
  status: 'OK' | '2YEARS' | 'NO_OIL'
  modelo: string
  fuelType: string
}

export function findOil(vehicle: object, matricula: string, kms: string) {
  return (dispatch) => {
    dispatch({
      type: ADD_REVISION_CARINFO,
    })
    dispatch(setAltaVehiculoLoading())
    api
      .findOils(vehicle, matricula, kms)
      .then((data: Response) => {
        const { vehicleOils, newVehicleData, status, modelo, fuelType } = data
        dispatch(resetAltaVehiculoLoading())
        if (status === 'OK') {
          if (vehicleOils.length > 0) {
            dispatch(setCarInfo(matricula, kms, vehicleOils, modelo))
            dispatch(setRevisionStep(3))
          }
        } else {
          if (status === '2YEARS' || status === 'NO_OIL') {
            dispatch({
              type: ADD_REVISION_CARINFO_NO_REVISION,
              payload: {
                status,
                combustible: fuelType || '',
              },
            })
            dispatch(setRevisionStep(2))
            dispatch(setAltaVehiculoStep(6))
          } else {
            dispatch({
              type: ADD_REVISION_CARINFO_FAILED,
              payload: {
                error: 'Esta matricula no existe',
              },
            })
          }
        }
      })
      .catch((err) => {
        dispatch(resetAltaVehiculoLoading())
        dispatch({
          type: ADD_REVISION_CARINFO_FAILED,
          payload: {
            error: err.message,
          },
        })
      })
  }
}

export function verifyPresetRevisiones(
  matricula: string,
  aceite: string,
  kms: string | undefined,
  postCode: string | undefined,
  dispositivo: 'MOBILE' | 'TABLET' | 'DESKTOP'
) {
  return (dispatch) => {
    api
      .verifyPresetRevisiones(matricula, kms, aceite)
      .then(async (res) => {
        const { matricula, aceite, vehicle } = res
        if (!matricula || !vehicle) {
          dispatch(AltaVehiculoActions.resetAltaVehiculos())
          dispatch(resetRevisiones())
          dispatch(setRevisionStep(1))
        } else if (!aceite) {
          dispatch(findOil(vehicle, matricula, kms))
        } else {
          dispatch(setPresetRevisionInfo(matricula, vehicle, kms, aceite))
          const revisiones = await logic.getRevisionesList(
            aceite.id_site_revision_aceite,
            postCode
          )
          dispatch(
            setOilSelection(`${aceite.id_site_revision_aceite}`, revisiones)
          )
          const dataRegisterLeadMatricula = {
            matricula,
            aceite_escogido: aceite.tipo_aceite,
            dispositivo,
            id_site_usuario: undefined,
            postCode: undefined,
          }
          registroLeadMatricula(dataRegisterLeadMatricula)
          dispatch(resetRevisionLoading())
          dispatch(setRevisionStep(4))
        }
      })
      .catch((err) => {
        dispatch(resetAltaVehiculoLoading())
        dispatch({
          type: ADD_REVISION_CARINFO_FAILED,
          payload: {
            error: err.message,
          },
        })
      })
  }
}
