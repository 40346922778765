import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import * as AireAcondicionadoActions from '../actions/aireAcondicionadoActions'
import { selectAireAcondicionado } from '../selectors/aireAcondicionadoSelectors'
import * as CestaActions from '../actions/cestaActions'
import { DataSteps, Steps } from '../../components/cesta/Steps'
import { navigate } from 'gatsby'
import { Vehicle } from '../../types/Vehiculo'
import * as CitaPreviaActions from '../actions/citaPreviaActions'
import route from '../../utils/route'
import {
  AreaPrivadaCitaPreviaEnum,
  areaPrivadaCitaSteps,
} from '../../components/CitaPrevia/CitaPreviaSteps'
import { selectUserInfo } from '../selectors/userSelectors'
import { setUserData } from '../actions/citaPreviaActions'
import {
  AireAcondicionadoState,
  AIRE_ACONDICIONADO_ID,
} from '../reducers/aireAcondicionadoReducer'
import { Device } from '../../types/Device'
import { resetAireAcondicionado } from '../actions/aireAcondicionadoActions'

const citaPreviaCompraAc = {
  service: 'ac2',
  vehicle: 'Coche',
  nombre_es: 'Carga Aire R-1234YF',
  nombre_ca: 'Càrrega Aire R-1234YF',
  nombre_pt: null,
  more_info_es: null,
  more_info_ca: null,
  more_info_pt: null,
}

function* addAireAcondicionadoCesta(vehicle: Vehicle, postCode: string) {
  try {
    yield put(CestaActions.setCodigoPostalIntroducido(postCode))
    yield put(
      CestaActions.addProduct(
        {
          id: AIRE_ACONDICIONADO_ID,
          type: 'otros',
        },
        'ac',
        vehicle
      )
    )
    yield put(CestaActions.setStep(Steps.TU_SOLICITUD))
    yield navigate(DataSteps[Steps.TU_SOLICITUD].route)
    yield put(AireAcondicionadoActions.resetAireAcondicionado())
  } catch (err) {
    yield put(AireAcondicionadoActions.findAireAcondicionadoFailed(err.message))
  }
}

function* goToCitaPrevia(vehicle: Vehicle, device: Device) {
  const state = yield select()
  const userInfo = yield selectUserInfo(state)
  const url = route('aire-acondicionado.asistente.index')

  yield put(CitaPreviaActions.setSelectedService(citaPreviaCompraAc, url))
  yield put(
    setUserData({
      email: userInfo.email,
      name: userInfo.userName,
      phone: userInfo.phone,
      comment: '',
      device,
      marca: vehicle.marca,
      matricula: vehicle.cod_matricula,
      modelo: vehicle.modelo,
      origin: url,
    })
  )
  yield put(
    CitaPreviaActions.setCitaPreviaStep(
      AreaPrivadaCitaPreviaEnum.OPTIONAL_COMMENT
    )
  )

  yield navigate(
    areaPrivadaCitaSteps[AreaPrivadaCitaPreviaEnum.OPTIONAL_COMMENT].route
  )
  yield put(resetAireAcondicionado())
}
function* findAireAcondicionado() {
  const state = yield select()
  const acState: AireAcondicionadoState = yield selectAireAcondicionado(state)
  const { vehicle, postCode, device } = acState
  try {
    if (vehicle.ano_fabric >= 2018 || vehicle.ano_fabric === 0) {
      yield call(goToCitaPrevia, vehicle, device)
    } else {
      yield call(addAireAcondicionadoCesta, vehicle, postCode)
    }
  } catch (err) {
    yield put(AireAcondicionadoActions.findAireAcondicionadoFailed(err.message))
  }
}

function* sagas() {
  return all([
    yield takeLatest(
      AireAcondicionadoActions.AIRE_ACONDICIONADO_FIND,
      findAireAcondicionado
    ),
  ])
}

export default sagas
