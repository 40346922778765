import { ServiceTaller } from '../reducers/areaPrivadaReducer'
import { Aceite } from '../../types/Aceite'
import { Vehicle } from '../../types/Vehiculo'
import { NeumaticoMatricula } from '../../types/Neumatico'
import { ICitaPrevia } from '../../types/CitaPrevia'
import { FavouriteTaller } from '../../types/Taller'

export const FIND_VEHICLES = 'AREA_PRIVADA::FIND_VEHICLES'
export const FIND_VEHICLES_OK = 'AREA_PRIVADA::FIND_VEHICLES_OK'
export const FIND_VEHICLES_FAILED = 'AREA_PRIVADA::FIND_VEHICLES_FAILED'
export const DELETE_VEHICLES = 'AREA_PRIVADA::DELETE_VEHICLES'
export const DELETE_VEHICLES_OK = 'AREA_PRIVADA::DELETE_VEHICLES_OK'
export const DELETE_VEHICLES_FAILED = 'AREA_PRIVADA::DELETE_VEHICLES_FAILED'
export const RESET_ERROR = 'AREA_PRIVADA::RESET_ERROR'
export const SET_TALLER = 'AREA_PRIVADA::SET_TALLER'
export const SET_TALLER_OK = 'AREA_PRIVADA::SET_TALLER_OK'
export const FIND_CITAS_PREVIAS = 'AREA_PRIVADA::FIND_CITAS_PREVIAS'
export const FIND_CITAS_PREVIAS_OK = 'AREA_PRIVADA::FIND_CITAS_PREVIAS_OK'
export const FIND_CITAS_PREVIAS_FAILED =
  'AREA_PRIVADA::FIND_CITAS_PREVIAS_FAILED'
export const FIND_SERVICES = 'AREA_PRIVADA::FIND_SERVICES'
export const FIND_SERVICES_OK = 'AREA_PRIVADA::FIND_SERVICES_OK'
export const FIND_SERVICES_FAILED = 'AREA_PRIVADA::FIND_SERVICES_FAILED'
export const FIND_ACEITE = 'AREA_PRIVADA::FIND_ACEITE'
export const FIND_ACEITE_OK = 'AREA_PRIVADA::FIND_ACEITE_OK'
export const FIND_ACEITE_FAILED = 'AREA_PRIVADA::FIND_ACEITE_FAILED'
export const SET_CAR_REVISION = 'AREA_PRIVADA::SET_CAR_REVISION'
export const SET_CAR_REVISION_OK = 'AREA_PRIVADA::SET_CAR_REVISION_OK'
export const SET_CAR_REVISION_FAILED = 'AREA_PRIVADA::SET_CAR_REVISION_FAILED'

export const FIND_NEUMATICO = 'AREA_PRIVADA::FIND_NEUMATICO'
export const FIND_NEUMATICO_OK = 'AREA_PRIVADA::FIND_NEUMATICO_OK'
export const FIND_NEUMATICO_FAILED = 'AREA_PRIVADA::FIND_NEUMATICO_FAILED'

export function findVehicles() {
  return {
    type: FIND_VEHICLES,
  }
}
export function findVehiclesOk(vehicles: Vehicle[]) {
  return {
    type: FIND_VEHICLES_OK,
    payload: {
      vehicles,
    },
  }
}
export function findVehiclesFailed(message) {
  return {
    type: FIND_VEHICLES_FAILED,
    payload: { error: message },
  }
}
export function setTaller() {
  return {
    type: SET_TALLER,
  }
}
export function setTallerOk(taller: FavouriteTaller) {
  return {
    type: SET_TALLER_OK,
    payload: {
      favouriteTaller: taller,
    },
  }
}

export function findCitasPrevias() {
  return { type: FIND_CITAS_PREVIAS }
}

export function findCitasPreviasOk(citas: ICitaPrevia[]) {
  return { type: FIND_CITAS_PREVIAS_OK, payload: { citasPrevias: citas } }
}

export function findCitasPreviasFailed(message) {
  return { type: FIND_CITAS_PREVIAS_FAILED, payload: { error: message } }
}

export function findServices() {
  return { type: FIND_SERVICES }
}

export function findServicesOK(services: ServiceTaller[]) {
  return { type: FIND_SERVICES_OK, payload: { services } }
}

export function findServicesFailed(message) {
  return { type: FIND_SERVICES_FAILED, payload: message }
}

export function deleteVehicle(matricula: string) {
  return { type: DELETE_VEHICLES, payload: { matricula } }
}

export function deleteVehicleOk(vehicles: Vehicle[]) {
  return { type: DELETE_VEHICLES_OK, payload: { vehicles } }
}

export function deleteVehicleFailed(message) {
  return { type: DELETE_VEHICLES_FAILED, payload: { error: message } }
}

export function findAceite(matricula: string) {
  return { type: FIND_ACEITE, payload: { matricula } }
}
export function findAceiteOk(aceite: Aceite) {
  return { type: FIND_ACEITE_OK, payload: { aceite } }
}

export function findAceiteFailed() {
  return { type: FIND_ACEITE_FAILED }
}

export function resetError() {
  return { type: RESET_ERROR }
}

export function setCarRevision(vehicle: Vehicle, aceite: Aceite) {
  return { type: SET_CAR_REVISION, payload: { vehicle, aceite } }
}

export function setCarRevisionOK() {
  return { type: SET_CAR_REVISION_OK }
}

export function setCarRevisionFailed(message) {
  return { type: SET_CAR_REVISION_FAILED, payload: { error: message } }
}

export function findNeumatico(matricula: string) {
  return { type: FIND_NEUMATICO, payload: { matricula } }
}
export function findNeumaticoOk(neumatico: NeumaticoMatricula) {
  return { type: FIND_NEUMATICO_OK, payload: { neumatico } }
}

export function findNeumaticoFailed() {
  return { type: FIND_NEUMATICO_FAILED }
}
