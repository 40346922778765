import { GetMarcas } from '../../api/getMarcas'

export const BATERIAS_MARCA_LIST = 'BATERIAS_MARCA::LIST'
export const BATERIAS_MARCA_LIST_OK = 'BATERIAS_MARCA::LIST_OK'
export const BATERIAS_MARCA_LIST_KO = 'BATERIAS_MARCA::LIST_FAILED'
export const BATERIAS_MARCA_LIST_RESET = 'BATERIAS_MARCA::LIST_RESET'

export function marcaAction() {
  return { type: BATERIAS_MARCA_LIST }
}
export function marcaOkAction(data: GetMarcas) {
  return { type: BATERIAS_MARCA_LIST_OK, payload: data }
}

export function marcaKoAction(message: string) {
  return { type: BATERIAS_MARCA_LIST_KO, payload: { error: message } }
}
export function marcaResetAction() {
  return { type: BATERIAS_MARCA_LIST_RESET }
}
