import * as Actions from '../actions/selectorMotoActions'
import { t } from '../../i18n'
import { OptionMedida, ResponseGetMedidas } from '../../api/selectorMoto'
import { CategoriaNeumatico } from '../../types/Neumatico'

export const version = 4
export const key = 'selectorMoto'

export enum Step {
  INITIAL,
  SELECT_ANCHO,
  SELECT_ALTO,
  SELECT_LLANTA,
  SELECT_MARCA_MODELO,
  SELECT_CILINDRADA_MODELO,
  SELECT_MODELO_MODELO,
  SELECT_FABRICACION_MODELO,
  SELECT_APLICACION_MODELO,
  SELECT_MARCAS,
  SELECT_PREFERENCIAS,
  INSERT_DATOS_LEAD,
  SHOW_RESULTADOS,
  // REGISTER,
  SHOW_CAMARAS,
}

export type URLOrigen = string

export type MedidaName = 'ancho' | 'alto' | 'llanta'
export type FeatureNameModeloMoto =
  | 'marca'
  | 'cilindrada'
  | 'modelo'
  | 'fabricacion'
  | 'aplicacion'
export type Aplicacion = 'delantera' | 'trasera'
export const defaultOptionsAplicacion = [
  { label: t('selector-moto.aplicacion.delantera'), value: 'delantera' },
  { label: t('selector-moto.aplicacion.trasera'), value: 'trasera' },
] as {
  label: string
  value: Aplicacion
}[]

export const DEFAULT_VALUES_MEDIDAS_MOTO = {
  ancho: '',
  alto: '',
  llanta: '',
}

export const DEFAULT_VALUES_FEATURES_MODELO = {
  marca: '',
  cilindrada: '',
  modelo: '',
  fabricacion: '',
  aplicacion: 'delantera',
}

export const OrderedMedidas = ['ancho', 'alto', 'llanta'] as MedidaName[]

export const OrderedFeaturesModeloMoto = [
  'marca',
  'cilindrada',
  'modelo',
  'fabricacion',
] as FeatureNameModeloMoto[]

export function siguientesMedidas(medida: MedidaName): MedidaName[] {
  const siguientes = []
  let found = false
  OrderedMedidas.forEach((element) => {
    if (found) siguientes.push(element)
    if (element === medida) found = true
  })
  return siguientes
}

function computeMedidasToReset(medida: MedidaName, state: SelectorMotoState) {
  const resets = {} as { [k in MedidaName]?: Selectable<number | string> }
  const siguientes = siguientesMedidas(medida)
  for (let i = 0; i < siguientes.length; i++) {
    const m = siguientes[i]
    resets[m] = {
      loading: i === 0,
      options: [],
      value: null,
      default: state[m].default,
    }
  }
  return resets
}

export function siguientesFeatures(
  medida: FeatureNameModeloMoto
): FeatureNameModeloMoto[] {
  const siguientes = []
  let found = false
  OrderedFeaturesModeloMoto.forEach((element) => {
    if (found) siguientes.push(element)
    if (element === medida) found = true
  })
  return siguientes
}

function computeFeaturesToReset(
  feature: FeatureNameModeloMoto
): Partial<SelectorModeloMoto> {
  const resets = {} as Partial<SelectorModeloMoto>
  const siguientes = siguientesFeatures(feature)
  for (let i = 0; i < siguientes.length; i++) {
    const f = siguientes[i]
    resets[f] = {
      loading: i === 0,
      options: [],
      value: null,
      default: DEFAULT_VALUES_FEATURES_MODELO[f],
    }
  }

  return resets
}

export function validateBuscarPorMedidas(data: SelectorMotoState): boolean {
  return !!(data.ancho.value && data.llanta.value)
}

export function validateBuscarPorModelo(data: SelectorMotoState): boolean {
  return !!(
    data.selectorModelo.marca.value &&
    data.selectorModelo.cilindrada &&
    data.selectorModelo.modelo.value &&
    data.selectorModelo.aplicacion.value
  )
}

type Medida<T, V> = {
  name: T
  value: V
}

export type MedidaFeature = {
  name: FeatureNameModeloMoto
  value: string
}

export type IMedida =
  | Medida<'ancho', string>
  | Medida<'alto', string>
  | Medida<'llanta', string>

export interface Selectable<T> {
  loading: boolean
  value: null | T
  default: null | T
  options: {
    label: string
    value: T
  }[]
}

export type ListaMarcas = {
  label: string
  value: number
  clasificacion: CategoriaNeumatico
}[]

export type SelectorModeloMoto = {
  marca: Selectable<string>
  cilindrada: Selectable<string>
  modelo: Selectable<string>
  fabricacion: Selectable<string>
  aplicacion: Selectable<Aplicacion>
}

export type registroMarca = {
  ancho: string
  alto: string
  llanta: string
  marca: number
}

export const initialState = {
  _version: version,
  step: Step.INITIAL as Step,
  pristine: true as boolean,
  buscar_por_medidas: true as boolean,
  error_search_medidas_moto: false as boolean,
  loading_medidas: false as boolean,
  ancho: {
    loading: false,
    value: null,
    default: null,
    options: [],
  } as Selectable<string>,
  alto: {
    loading: false,
    value: null,
    default: null,
    options: [],
  } as Selectable<string>,
  llanta: {
    loading: false,
    value: null,
    default: null,
    options: [],
  } as Selectable<string>,
  marca: null as number | null,
  registroMarca: {
    ancho: null,
    alto: null,
    llanta: null,
    marca: null,
  } as registroMarca,
  filterables: {
    marcas: [] as OptionMedida[],
    tipos: [] as OptionMedida[],
    gamas: [] as OptionMedida[],
  },
  filtros: {
    marcas: [] as string[],
    tipos: [] as string[],
    gamas: [] as string[],
  },
  selectorModelo: {
    marca: {
      loading: false,
      value: null,
      default: null,
      options: [],
    } as Selectable<string>,
    cilindrada: {
      loading: false,
      value: null,
      default: null,
      options: [],
    } as Selectable<string>,
    modelo: {
      loading: false,
      value: null,
      default: null,
      options: [],
    } as Selectable<string>,
    fabricacion: {
      loading: false,
      value: null,
      default: null,
      options: [],
    } as Selectable<string>,
    aplicacion: {
      loading: false,
      value: null,
      default: null,
      options: defaultOptionsAplicacion,
    } as Selectable<Aplicacion>,
  } as SelectorModeloMoto,
  origen: '/' as URLOrigen,
}

export type SelectorMotoState = typeof initialState

export const reducer = (state = initialState, action): SelectorMotoState => {
  switch (action.type) {
    case Actions.SET_STEP_MOTO:
      return {
        ...state,
        step: action.payload,
      }

    case Actions.RESET_MOTO: {
      const _options = action.payload as Actions.ResetOptionsMoto
      const medidas = {}
      OrderedMedidas.map(
        (m) => (medidas[m] = { ...initialState[m], loading: true })
      )
      return {
        ...initialState,
        ...medidas,
        pristine: true,
        origen: _options.origen,
      }
    }
    case Actions.SET_ORIGEN_MOTO:
      return {
        ...state,
        origen: action.payload,
      }

    case Actions.RESET_OK_MOTO: {
      const _defaults = action.payload
      OrderedMedidas.forEach((m) => {
        _defaults[m].default = _defaults[m].value
      })
      return {
        ...state,
        ..._defaults,
      }
    }
    case Actions.SELECT_MEDIDA_MOTO: {
      const _medida = action.payload as IMedida
      const resets = computeMedidasToReset(_medida.name, state)
      return {
        ...state,
        pristine: false,
        [_medida.name]: {
          ...state[_medida.name],
          value: _medida.value,
        },
        ...(resets as any),
      }
    }
    case Actions.LOAD_OPTIONS_MOTO:
      return {
        ...state,
        ...action.payload,
      }

    case Actions.SELECT_MARCA_MOTO:
      return {
        ...state,
        marca: action.payload,
        filtros: {
          ...state.filtros,
          marcas: [action.payload.toString()],
        },
      }

    case Actions.RESET_MARCAS_MOTO:
      return {
        ...state,
        marca: null,
        filtros: {
          ...state.filtros,
          marcas: [],
        },
      }

    case Actions.CHANGE_FILTRO: {
      if (action.payload.value === null) return state
      const filtered = state.filtros[action.payload.name].filter(
        (id) => id !== action.payload.value
      )
      return {
        ...state,
        filtros: {
          ...state.filtros,
          [action.payload.name]: action.payload.checked
            ? [...filtered, action.payload.value]
            : filtered,
        },
      }
    }
    case Actions.RESTORE_FILTRO:
      return {
        ...state,
        filtros: {
          ...state.filtros,
          [action.payload.name]: action.payload.newValues || [],
        },
      }
    case Actions.RESTORE_MOTO:
      return {
        ...action.payload,
      }
    case Actions.LOAD_OPTIONS_FEATURES_MODELO:
      return {
        ...state,
        selectorModelo: {
          ...state.selectorModelo,
          ...action.payload,
        },
      }

    case Actions.SELECT_FEATURE_MODELO: {
      const feature = action.payload as MedidaFeature
      const res = computeFeaturesToReset(feature.name)
      return {
        ...state,
        selectorModelo: {
          ...state.selectorModelo,
          [feature.name]: {
            ...state.selectorModelo[feature.name],
            value: feature.value,
          },
          ...res,
        },
      }
    }
    case Actions.RESET_FEATURES_MODELO: {
      const features = { ...initialState.selectorModelo } as SelectorModeloMoto
      OrderedFeaturesModeloMoto.map((f) => (features[f].loading = true))
      return {
        ...state,
        selectorModelo: {
          ...features,
        },
      }
    }
    case Actions.BUSCAR_POR_MEDIDAS:
      return {
        ...state,
        buscar_por_medidas: true,
        error_search_medidas_moto: false,
      }

    case Actions.BUSCAR_POR_MODELO:
      return {
        ...state,
        buscar_por_medidas: false,
        error_search_medidas_moto: false,
      }
    case Actions.SEARCH_MEDIDAS_MOTO:
      return {
        ...state,
        error_search_medidas_moto: false,
        loading_medidas: true,
      }

    case Actions.SET_MEDIDAS: {
      const medidasFromModelo: ResponseGetMedidas = action.payload
      return {
        ...state,
        ancho: {
          ...state.ancho,
          value: medidasFromModelo.ancho,
        },
        alto: {
          ...state.alto,
          value: medidasFromModelo.alto,
        },
        llanta: {
          ...state.llanta,
          value: medidasFromModelo.llanta,
        },
      }
    }
    case Actions.ERROR_SEARCH_MEDIDAS_MOTO:
      return {
        ...state,
        error_search_medidas_moto: action.payload.error,
        loading_medidas: false,
      }
    case Actions.SET_REGISTRO_MARCA:
      return {
        ...state,
        registroMarca: action.payload,
      }

    default:
      return state
  }
}
