import { all, put, select, takeLatest } from 'redux-saga/effects'
import { searchNeumaticos } from '../../api/search'
import { reportSearch } from '../../../specific/helpers/gtmReporter'
import * as SearchActions from '../actions/searchActions'
import { SelectorState } from '../reducers/selectorReducer'
import { selectSelector } from '../selectors/selectorSelectors'
import { UserState } from '../reducers/userReducer'
import { selectUserInfo } from '../selectors/userSelectors'

function mapSelectorToSearch(
  state: SelectorState,
  userInfo: UserState,
  options: SearchActions.SearchOptions
): SearchActions.SearchParameters {
  const query = {
    pagination: options.pagination,
    order: options.order,
    direction: options.direction || '',
    web: process.env.GATSBY_WEB,
    filters: {},
    userInfo,
  } as SearchActions.SearchParameters

  if (state.ancho.value) query.filters.ancho = state.ancho.value
  if (state.serie.value) query.filters.serie = state.serie.value
  if (state.llanta.value) query.filters.llanta = state.llanta.value
  if (state.carga.value) query.filters.ic = state.carga.value
  if (state.velocidad.value) query.filters.cv = state.velocidad.value
  // Albert dice que si no marcas runflat NO tienen que salir los que no son runflat
  query.filters.runflat = !!state.runflat.value
  if (state.temporada.value === 'invierno') query.filters.invierno = true
  if (state.temporada.value === 'allseason') query.filters.allseason = true
  if (state.temporada.value === 'verano') query.filters.verano = true
  if (state.filtroMarcas.length > 0) {
    query.filters.idsmarca = state.filtroMarcas
  }
  return query
}

function* search(action: any) {
  const state = yield select()
  const selectorState = selectSelector(state)
  const userInfo = selectUserInfo(state)
  const query = mapSelectorToSearch(selectorState, userInfo, action.payload)
  reportSearch(selectorState)
  try {
    const results = yield searchNeumaticos(query)
    yield put({
      type: SearchActions.SEARCH_OK,
      payload: results,
    })
  } catch (err) {
    yield put({
      type: SearchActions.SEARCH_FAILED,
      payload: err.message,
    })
  }
}

function* sagas() {
  return all([yield takeLatest(SearchActions.SEARCH, search)])
}

export default sagas
