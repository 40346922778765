import { SearchParameters } from '../context/actions/searchActions'
import { CategoriaNeumatico } from '../types/Neumatico'
import { apiCall } from './api'
import { Promocion as PromocionCesta } from '../context/reducers/cestaReducer'
import { Promocion } from '../components/promotionBanner/PromotionBanner'
import { ConsumoLevels, LLuviaLevels, RuidoLevels } from '../types/Ecolabel'

const baseUrl = `${process.env.GATSBY_API_URL}`

export interface NeumaticoResult {
  allseason: boolean
  ancho: number
  categoria: CategoriaNeumatico | 'Premium' | 'Quality' | 'Budget'
  consumo: ConsumoLevels
  cv: string
  decibelios: number
  descuento: number
  ecotasa: number
  ic: number
  id: string
  idMarca: number
  invierno: boolean
  llanta: number
  lluvia: LLuviaLevels
  logo: string
  marca: string
  modelo: string
  nivelruido: RuidoLevels
  nombre_producto_es: string
  precio: number
  precioSinPromo: number
  promo: string
  promocion?: Promocion
  promocionCesta: PromocionCesta
  precioConEcotasa: number
  puntuacion: number
  runflat: boolean
  serie: number
  sku: string
  tipo_iva: number
  urlImagen: string
  verano: boolean
  equivalente?: string
  marcaje: string | null
  descripcion_es: string
  descripcion_ca: string
  descripcion_pt: string
  eprel: string
}
export interface SearchResults {
  neumaticos: NeumaticoResult[]
  pages: number
  total: number
  token: string | null
}

export function searchNeumaticos(
  query: SearchParameters
): Promise<SearchResults> {
  return apiCall(`${baseUrl}/neumaticos/selector`, {
    method: 'POST',
    body: JSON.stringify(query),
  })
}

export interface NavisionSearch {
  tipovehiculo: number
  ancho: number
  serie: number
  llanta: number
  ic: number
  cv: string
  runflat: boolean
  invierno: boolean
  allseason: boolean
  cantidad?: number
  marca?: number
  email: string
  phone: string
  zipcode: string
  origen: string
  device: 'MOBILE' | 'TABLET' | 'DESKTOP'
}
export function searchNavisionNeumaticos(data: NavisionSearch): Promise<{
  total: number
  neumaticos: NeumaticoResult[]
  priorityMarca: number | null
  token: string | null
}> {
  const navisionData = {
    ...data,
    useragent: navigator.userAgent,
  }
  return apiCall(`${process.env.GATSBY_API_URL}/neumaticos/selector/ws`, {
    method: 'POST',
    body: JSON.stringify(navisionData),
  })
}
