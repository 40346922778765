import { IAppState } from '../../../../common/context/context'
import { key, RevisionDatosState } from './revisionMotoMatriculaReducer'

export const selectRevisionMoto = (state: IAppState): RevisionDatosState =>
  state[key]

export const selectRevisionMotoIdNavision = (
  state: IAppState
): string | null => {
  const rmState = selectRevisionMoto(state)
  return rmState.datos?.idNavision
}

export const selectRevisionMotoIdLead = (state: IAppState): number | null => {
  const rmState = selectRevisionMoto(state)
  return rmState.datos?.idLead
}

export const selectRevisionMotoPrecio = (state: IAppState): number | null => {
  const rmState = selectRevisionMoto(state)
  return rmState.datos?.precio
}

export const selectRevisionMotoCilindrada = (
  state: IAppState
): string | null => {
  const rmState = selectRevisionMoto(state)
  return rmState.datos?.cilindrada
}

export const selectRevisionMotoMatricula = (
  state: IAppState
): string | null => {
  const rmState = selectRevisionMoto(state)
  return rmState.datos?.matricula
}
