import Step from '../../components/FunnelSelector/SelectorSteps'
import { CategoriaNeumatico } from '../../types/Neumatico'
import * as Actions from '../actions/selectorActions'
import { Marca } from '../../components/withMarcas'

export const version = 2
export const key = 'selector'

export type URLOrigen = string

export type MedidaName = 'ancho' | 'serie' | 'llanta' | 'carga' | 'velocidad'
export type Temporada = 'verano' | 'allseason' | 'invierno'

export const OrderedMedidas = [
  'ancho',
  'serie',
  'llanta',
  'carga',
  'velocidad',
] as MedidaName[]

export function siguientesMedidas(medida: MedidaName): MedidaName[] {
  const siguientes = []
  let found = false
  OrderedMedidas.forEach((element) => {
    if (found) siguientes.push(element)
    if (element === medida) found = true
  })
  return siguientes
}

function computeMedidasToReset(medida: MedidaName, state: SelectorState) {
  const resets = {} as { [k in MedidaName]?: Selectable<number | string> }
  const siguientes = siguientesMedidas(medida)
  for (let i = 0; i < siguientes.length; i++) {
    const m = siguientes[i]
    resets[m] = {
      loading: i === 0,
      options: [],
      value: null,
      default: state[m].default,
    }
  }
  return resets
}

type Medida<T extends MedidaName, V> = {
  name: T
  value: V
}

export type IMedida =
  | Medida<'ancho', number>
  | Medida<'serie', number>
  | Medida<'llanta', number>
  | Medida<'carga', number>
  | Medida<'velocidad', string>

export interface Selectable<T> {
  loading: boolean
  value: null | T
  default: null | T
  options: {
    label: string
    value: T
  }[]
}

export type ListaMarcas = {
  label: string
  value: number
  coche: boolean
  moto: boolean
  clasificacion: CategoriaNeumatico
}[]

export const initialState = {
  _version: version,
  step: Step.INITIAL as Step,
  pristine: true as boolean,

  ancho: {
    loading: false,
    value: null,
    default: null,
    options: [],
  } as Selectable<number>,
  serie: {
    loading: false,
    value: null,
    default: null,
    options: [],
  } as Selectable<number>,
  llanta: {
    loading: false,
    value: null,
    options: [],
  } as Selectable<number>,
  carga: {
    loading: false,
    value: null,
    default: null,
    options: [],
  } as Selectable<number>,
  velocidad: {
    loading: false,
    value: null,
    default: null,
    options: [],
  } as Selectable<string>,

  tipoVehiculo: null as null | number,

  temporada: {
    value: 'verano' as Temporada | null,
    selectable: true,
  },

  runflat: {
    selectable: true,
    value: false,
  } as { selectable: boolean; value: boolean },

  marca: null as number | null,

  marcas: null as Marca[] | null,

  filtroMarcas: [] as number[],

  filterables: {
    marcas: [] as number[],
    temporadas: [] as Temporada[],
    runflat: [] as number[],
  },

  origen: '/' as URLOrigen,
}

export type SelectorState = typeof initialState

export const reducer = (state = initialState, action): SelectorState => {
  switch (action.type) {
    case Actions.SET_STEP:
      return {
        ...state,
        step: action.payload,
      }

    case Actions.RESET:
      const _options = action.payload as Actions.ResetOptions
      const medidas = {}
      OrderedMedidas.map(
        (m) => (medidas[m] = { ...initialState[m], loading: true })
      )
      return {
        ...initialState,
        ...medidas,
        tipoVehiculo: _options.tipoVehiculo,
        pristine: true,
        temporada: _options.temporada,
        marca: _options.marca,
        filtroMarcas: [],
        origen: _options.origen,
      }

    case Actions.SET_ORIGEN:
      return {
        ...state,
        origen: action.payload,
      }

    case Actions.RESET_OK:
      const _defaults = action.payload
      OrderedMedidas.forEach((m) => {
        _defaults[m].default = _defaults[m].value
      })
      return {
        ...state,
        ..._defaults,
      }

    case Actions.SELECT_MEDIDA:
      const _medida = action.payload as IMedida
      const resets = computeMedidasToReset(_medida.name, state)
      return {
        ...state,
        pristine: false,
        [_medida.name]: {
          ...state[_medida.name],
          value: _medida.value,
        },
        ...(resets as any),
      }
    case Actions.SELECT_MEDIDAS_NEUMATICO:
      const _neumatico = action.payload.neumatico
      let tempordada = 'verano' as Temporada
      if (_neumatico.allseason) {
        tempordada = 'allseason'
      } else if (_neumatico.verano) {
        tempordada = 'verano'
      } else if (_neumatico.invierno) {
        tempordada = 'invierno'
      }
      return {
        ...state,
        pristine: false,
        ancho: {
          ...state.ancho,
          value: _neumatico.ancho,
          options: [],
        },
        serie: {
          ...state.serie,
          value: _neumatico.serie,
          options: [],
        },
        llanta: {
          ...state.llanta,
          value: _neumatico.llanta,
          options: [],
        },
        carga: {
          ...state.carga,
          value: _neumatico.ic,
          options: [],
        },
        velocidad: {
          ...state.velocidad,
          value: _neumatico.cv,
          options: [],
        },
        runflat: {
          ...state.runflat,
          value: _neumatico.runflat,
        },
        temporada: {
          ...state.temporada,
          value: tempordada,
        },
      }
    case Actions.LOAD_OPTIONS:
      return {
        ...state,
        ...action.payload,
      }

    case Actions.SELECT_ESTACION:
      return {
        ...state,
        temporada: {
          selectable: state.temporada.selectable,
          value: action.payload,
        },
      }

    case Actions.SELECT_RUNFLAT:
      return {
        ...state,
        runflat: {
          selectable: true,
          value: action.payload,
        },
      }

    case Actions.SELECT_MARCA:
      if (action.payload == 0) {
        return {
          ...state,
          marca: null,
          filtroMarcas: [],
        }
      }
      return {
        ...state,
        marca: action.payload,
        filtroMarcas: [],
      }

    case Actions.SET_MARCAS:
      return {
        ...state,
        marcas: action.payload,
      }

    case Actions.CHANGE_FILTRO_MARCAS:
      const filtered = state.filtroMarcas.filter(
        (id) => id !== action.payload.id
      )
      if (action.payload.id === null) return state
      return {
        ...state,
        filtroMarcas: action.payload.selected
          ? [...filtered, action.payload.id]
          : filtered,
      }

    case Actions.RESTORE:
      return {
        ...action.payload,
      }

    default:
      return state
  }
}
