import * as Actions from './bateriasFilterValuesAction'
import { FilterValues } from '../../api/getBaterias'

export const version = 1
export const key = 'bateriasFilterValues'

interface State {
  _version: number
  loading: boolean
  error: string | null
  values: FilterValues
}

export const initialState: State = {
  _version: version,
  loading: false,
  error: null,
  values: null,
}

export type BateriasFilterValuesState = typeof initialState
export const bateriasFilterValuesReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.BATERIAS_FILTER_VALUES:
      return {
        ...state,
        loading: true,
      }
    case Actions.BATERIAS_FILTER_VALUES_OK:
      return {
        ...state,
        loading: false,
        values: action.payload.data,
      }
    case Actions.BATERIAS_FILTER_VALUES_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case Actions.BATERIAS_FILTER_VALUES_RESET:
      return {
        ...initialState,
      }

    default:
      return state
  }
}
