import { apiCall } from '../../../common/api/api'

export interface GetRevisionModelosApiRequest {
  cilindrada: string
  marca: number
}

export interface RevisionModelo {
  ano_fabricacion: number
  id_moto: string
  modelo: string
}

export interface GetRevisionModelosResponse {
  result: RevisionModelo[]
}
export function getModelosByMarcaAndCilindrada(
  marca: number,
  clilindrada: string,
  electrica: boolean | null | undefined
): Promise<GetRevisionModelosResponse> {
  return apiCall(
    `${process.env.GATSBY_API_URL}/moto/modelo/${clilindrada}/${marca}?electrica=${electrica}`,
    {
      method: 'GET',
      auth: true,
    }
  )
}
