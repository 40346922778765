import { CestaState } from '../../context/reducers/cestaReducer'
import { hasServices } from '../../context/selectors/cestaSelectors'
import route from '../../utils/route'

export enum Steps {
  TU_SOLICITUD,
  OPCIONES,
  TALLERES,
  FECHA_HORA,
  REGISTRO,
  PAGO,
  TU_RESERVA,
}

export const DataSteps = [
  {
    step: Steps.TU_SOLICITUD,
    name: 'steps_cesta.tu_solicitud',
    route: route('cesta.solicitud'),
    prev: () => Steps.TU_SOLICITUD,
  },
  {
    step: Steps.OPCIONES,
    name: 'steps_cesta.opciones',
    route: route('cesta.opciones'),
    prev: () => Steps.TU_SOLICITUD,
  },
  {
    step: Steps.TALLERES,
    name: 'steps_cesta.talleres',
    route: route('cesta.talleres'),
    prev: (state: CestaState) =>
      hasServices(state) ? Steps.OPCIONES : Steps.TU_SOLICITUD,
  },
  {
    step: Steps.FECHA_HORA,
    name: 'steps_cesta.fecha_y_hora',
    route: route('cesta.fecha_hora'),
    prev: () => Steps.TALLERES,
  },
  {
    step: Steps.REGISTRO,
    name: 'steps_cesta.registro',
    route: route('cesta.registro'),
    prev: () => Steps.FECHA_HORA,
  },
  {
    step: Steps.PAGO,
    name: 'steps_cesta.pago',
    route: route('cesta.pago'),
    prev: () => Steps.FECHA_HORA,
  },
  {
    step: Steps.TU_RESERVA,
    name: 'steps_cesta.reserva',
    route: route('cesta.tu_reserva'),
    prev: () => -1,
  },
]

export function stepFromRoute(path: string) {
  for (let i = DataSteps.length - 1; i >= 0; i--) {
    const s = DataSteps[i]
    if (s.route === path) {
      return s
    }
  }

  return null
}
