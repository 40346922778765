import { SearchCamaraMotoResults } from '../../api/searchCamaraMoto'
import { LOGOUT } from '../actions/userActions'
import {
  CAMARA_RESET,
  SEARCH_CAMARA,
  SEARCH_CAMARA_FAILED,
  SEARCH_CAMARA_OK,
  SearchParametersCamaraMoto,
} from '../actions/searchCamaraMotoActions'

export const key = 'searchCamaraMoto'

export const initialState = {
  loading: false as boolean,
  error: null as null | string,
  query: {
    order: '',
    direction: 'ASC',
    pagination: {
      pageSize: 10,
      page: 0,
    },
  } as Partial<SearchParametersCamaraMoto>,
  results: {
    pages: 0,
    total: 0,
    camaras: [],
  } as SearchCamaraMotoResults,
}

export type SearchCamaraMotoState = typeof initialState

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
    case CAMARA_RESET:
      return { ...initialState }

    case SEARCH_CAMARA:
      return {
        ...state,
        query: action.payload,
        loading: true,
      }

    case SEARCH_CAMARA_OK:
      return {
        ...state,
        loading: false,
        results: action.payload,
      }

    case SEARCH_CAMARA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
