import { IAppState } from '../context'
import { UserState, key } from '../reducers/userReducer'

export interface MinimalUserInfo {
  isLead: boolean
  isLoggedIn: boolean
}

export function selectUserInfo(state: IAppState): UserState {
  return state[key]
}

export function selectFullName(state: IAppState): string {
  const userInfo = selectUserInfo(state)
  if (userInfo.userData) {
    return [userInfo.userData.name, userInfo.userData.surname].join(' ')
  }
  return userInfo.userName
}

export function selectMinimalUserInfo(state: IAppState): MinimalUserInfo {
  const userInfo = selectUserInfo(state)
  return {
    isLoggedIn: userInfo.loggedIn,
    isLead: !!userInfo.idLead,
  }
}

export function selectEmail(state: IAppState): string | null {
  const userInfo = selectUserInfo(state)
  if (userInfo.userData && userInfo.userData.email) {
    return userInfo.userData.email
  }
  if (userInfo.email) {
    return userInfo.email
  }
  return null
}

export function selectPhone(state: IAppState): string | null {
  const userInfo = selectUserInfo(state)
  if (userInfo.userData && userInfo.userData.phone) {
    return userInfo.userData.phone
  }
  if (userInfo.phone) {
    return userInfo.phone
  }
  return null
}

export function selectPostCode(state: IAppState): string | null {
  const userInfo = selectUserInfo(state)
  if (userInfo.userData && userInfo.userData.postCode) {
    return userInfo.userData.postCode
  }
  if (userInfo.postCode) {
    return userInfo.postCode
  }
  return null
}

export function selectIsLoggedIn(state: IAppState): boolean {
  const userInfo = selectUserInfo(state)
  return userInfo.loggedIn
}

export function selectPercentCompleted(state: IAppState): number {
  const userInfo = selectUserInfo(state)
  return userInfo.percentCompleted
}
