import { RevisionDatos } from '../../api/postRevisionDatos'
import * as Actions from './revisionMotoMatriculaAction'

export const version = 1
export const key = 'revisionMoto'

interface State {
  _version: number
  loading: boolean
  error: string | null
  datos: RevisionDatos | null
}

export const initialState: State = {
  _version: version,
  loading: false,
  error: null,
  datos: null,
}

export type RevisionDatosState = typeof initialState
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.REVISION_DATOS:
      return {
        ...state,
        loading: true,
      }
    case Actions.REVISION_DATOS_OK:
      return {
        ...state,
        loading: false,
        datos: action.payload,
      }
    case Actions.REVISION_DATOS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case Actions.REVISION_DATOS_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        datos: null,
      }

    default:
      return state
  }
}
