import { t } from '../../../common/i18n'
import { UserState } from '../reducers/userReducer'

export const SEARCH = 'SEARCH::SEARCH'
export const SEARCH_OK = 'SEARCH::SEARCH_OK'
export const SEARCH_FAILED = 'SEARCH::SEARCH_FAILED'

type OrderKey = '' | 'marca' | 'precio' | 'orden'
type DirectionKey = 'ASC' | 'DESC'
export interface OrderEntry {
  label: string
  value: string
  field: OrderKey
  direction?: DirectionKey
}
export const ORDER_OPTIONS = [
  {
    label: t('resultados_neumaticos.recomendado'),
    value: 'orden.asc',
    field: 'orden',
    direction: 'ASC',
  },
  {
    label: `${t('resultados_neumaticos.marcas')} A->Z`,
    value: 'marca.asc',
    field: 'marca',
    direction: 'ASC',
  },
  {
    label: `${t('resultados_neumaticos.marcas')} Z->A`,
    value: 'marca.desc',
    field: 'marca',
    direction: 'DESC',
  },
  {
    label: t('resultados_neumaticos.mas_caros'),
    value: 'precio.desc',
    field: 'precio',
    direction: 'DESC',
  },
  {
    label: t('resultados_neumaticos.mas_baratos'),
    value: 'precio.asc',
    field: 'precio',
    direction: 'ASC',
  },
] as OrderEntry[]

export interface SearchParameters {
  filters: {
    ancho?: number
    serie?: number
    llanta?: number
    ic?: number
    cv?: string
    runflat?: boolean
    invierno?: boolean
    allseason?: boolean
    verano?: boolean
    idsmarca?: number[]
  }
  order: OrderKey
  direction?: DirectionKey
  pagination: {
    pageSize: number
    page: number
  }
  userInfo: UserState
  web: 'rodi' | 'igic' | 'elpaso'
}

const defaultSearchOptions = {
  applyMarcas: false,
  order: '',
  direction: 'ASC',
  pagination: {
    pageSize: 10,
    page: 0,
  },
}
export type SearchOptions = typeof defaultSearchOptions

export function search(options: SearchOptions = defaultSearchOptions) {
  return {
    type: SEARCH,
    payload: {
      ...defaultSearchOptions,
      ...options,
    },
  }
}
