import * as Actions from '../actions/revisionActions'
import { AceiteSeleccionado } from '../../types/Aceite'
import {
  Revision,
  RevisionComingFrom,
  RevisionStatus,
} from '../../types/Revision'
import { Vehicle } from '../../types/Vehiculo'

export const version = 1
export const key = 'revision'

export const initialState = {
  _version: version,
  matricula: null as string | null,
  kms: null as string | null,
  postCode: null as string | null,
  listaAceites: [] as any[],
  aceiteSeleccionado: null as string | null,
  aceiteSeleccionadoInfo: null as AceiteSeleccionado | null,
  modelo: null as string | null,
  revisionSeleccionada: null as any,
  revisiones: [] as Revision[],
  step: 1 as number,
  comingFrom: null as RevisionComingFrom | null,
  loading: false as boolean,
  error: null as string | null,
  success: false as boolean,
  status: null as RevisionStatus,
  combustible: null as string | null,
  vehiculoInfo: null as Vehicle | null,
}

export type RevisionState = typeof initialState

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.START_FIND_OIL:
      return {
        ...state,
        loading: true,
      }
    case Actions.START_OIL_JOURNEY:
      return {
        ...state,
        loading: true,
      }
    case Actions.START_OIL_JOURNEY_OK:
      return {
        ...state,
        loading: false,
      }
    case Actions.SET_VEHICLE_INFO:
      return {
        ...state,
        vehiculoInfo: action.payload.vehicle,
      }
    case Actions.SET_OIL_INFO:
      return {
        ...state,
        aceiteSeleccionadoInfo: {
          id: action.payload.aceite.aplicacion,
          sae: action.payload.aceite.sae,
          normativa: action.payload.aceite.normativa,
        },
      }
    case Actions.ADD_POST_CODE:
      return {
        ...state,
        postCode: action.payload.postCode,
      }
    case Actions.ADD_REVISION_CARINFO:
      return {
        ...state,
        loading: true,
        success: false,
      }
    case Actions.ADD_REVISION_CARINFO_OK:
      return {
        ...state,
        loading: false,
        matricula: action.payload.matricula,
        kms: action.payload.kms,
        listaAceites: action.payload.listaAceites,
        modelo: action.payload.modelo,
        success: true,
        aceiteSeleccionado: (
          action.payload.listaAceites.find((oil) => oil.recomendado === 1) ||
          action.payload.listaAceites[0]
        ).id_site_revision_aceite,
      }
    case Actions.ADD_REVISION_CARINFO_NO_REVISION:
      return {
        ...state,
        loading: false,
        success: true,
        status: action.payload.status,
        combustible: action.payload.combustible,
      }
    case Actions.ADD_REVISION_CARINFO_FAILED:
      return {
        ...state,
        loading: false,
        success: false,
        error: action.payload.error,
      }
    case Actions.ADD_OIL_SELECTION:
      return {
        ...state,
        aceiteSeleccionado: action.payload.aceiteSeleccionado,
        revisiones: action.payload.revisiones,
      }
    case Actions.ADD_REVISION_COMBUSTIBLE:
      return {
        ...state,
        combustible: action.payload.combustible,
      }
    case Actions.ADD_SELECTED_REVISION:
      return {
        ...state,
        revisionSeleccionada: action.payload.revisionSeleccionada,
      }
    case Actions.ADD_MATRICULA_DISTANCE:
      return {
        ...state,
        matricula: action.payload.matricula,
        kms: action.payload.kms,
        postCode: action.payload.postCode,
      }
    case Actions.CHANGE_REVISION_STEP:
      return {
        ...state,
        step: action.payload.step,
      }
    case Actions.ADD_REVISIONES_COMING_FROM:
      return {
        ...state,
        comingFrom: action.payload.comingFrom,
      }
    case Actions.RESET_REVISIONES_STATE:
      return {
        ...initialState,
      }
    case Actions.RESET_REVISIONES_SUCCESS:
      return {
        ...state,
        success: false,
      }
    case Actions.RESET_REVISIONES_ERROR:
      return {
        ...state,
        error: null,
      }
    case Actions.RESET_REVISIONES_LOADING:
      return {
        ...state,
        loading: false,
      }
    case Actions.SET_REVISIONES_LOADING:
      return {
        ...state,
        loading: true,
      }
    case Actions.SET_PRESET_DATA:
      return {
        ...state,
        matricula: action.payload.matricula,
        kms: action.payload.kms,
        aceiteSeleccionado: action.payload.aceite.id_site_revision_aceite,
        modelo: `${action.payload.vehicle.marca} ${action.payload.vehicle.modelo}`,
      }
    case Actions.SET_CAR_MODELO_MATRICULA:
      return {
        ...state,
        modelo: action.payload.modelo,
        matricula: action.payload.matricula,
      }
    case Actions.ADD_OIL_SELECTION_INFO:
      return {
        ...state,
        aceiteSeleccionadoInfo: action.payload.info,
      }
    default:
      return state
  }
}
