import { Promocion } from '../context/reducers/cestaReducer'

export interface Revision {
  cambio_aceite: number
  fecha_creacion: ISO8601DateString
  filtro_aceite: number
  filtro_aire: number
  filtro_habitaculo: number
  id_site_producto: number
  id_site_producto_revision: number
  id_site_revision_aceite: number
  id_site_tipo_vehiculo: number
  liquido_limpia: number
  liquido_refrigerante: number
  reset: number
  revision30: number
  site_producto: any
  promocion: Promocion
  tipo: RevisionType
  ultima_modificacion: string
}

export type RevisionStatus = 'OK' | '2YEARS' | 'NO_OIL' | null

export const RevisionTypes = ['Basic', 'Plus', 'Premium', 'Confort'] as const
export type RevisionType = typeof RevisionTypes[number]

export enum RevisionComingFrom {
  AREA_PRIVADA = 'area-privada',
  HOME = 'home',
}
