import { apiCall } from '../../../common/api/api'
import { Marca } from '../type/marca.d'

export type GetMarcas = {
  data: Marca[]
}

export function getMarcas(): Promise<GetMarcas> {
  return apiCall(`${process.env.GATSBY_API_URL}/baterias/marcas`, {
    method: 'GET',
    auth: true,
  })
}
