import { t } from '../../i18n'
import { UserState } from '../reducers/userReducer'

export const SEARCH = 'SEARCH::SEARCH_MOTO'
export const RESET_SEARCH = 'SEARCH::RESET_SEARCH'
export const SEARCH_OK = 'SEARCH::SEARCH_MOTO_OK'
export const SEARCH_FAILED = 'SEARCH::SEARCH_MOTO_FAILED'
export const DEFAULT_PAGE_SIZE = 10

type OrderKey = '' | 'marca' | 'precio' | 'orden'
type DirectionKey = 'ASC' | 'DESC'
export interface OrderEntry {
  label: string
  value: string
  field: OrderKey
  direction?: DirectionKey
}
export const ORDER_OPTIONS = [
  {
    label: t('resultados_neumaticos.recomendado'),
    value: 'orden.asc',
    field: 'orden',
    direction: 'ASC',
  },
  {
    label: `${t('resultados_neumaticos.marcas')} A->Z`,
    value: 'marca.asc',
    field: 'marca',
    direction: 'ASC',
  },
  {
    label: `${t('resultados_neumaticos.marcas')} Z->A`,
    value: 'marca.desc',
    field: 'marca',
    direction: 'DESC',
  },
  {
    label: t('resultados_neumaticos.mas_caros'),
    value: 'precio.desc',
    field: 'precio',
    direction: 'DESC',
  },
  {
    label: t('resultados_neumaticos.mas_baratos'),
    value: 'precio.asc',
    field: 'precio',
    direction: 'ASC',
  },
] as OrderEntry[]

export interface SearchParametersMoto {
  medidas: {
    ancho: string
    alto: string
    llanta: string
  }
  filtros: {
    marcas: string[]
    tipos: string[]
    gamas: string[]
  }
  order: OrderKey
  direction?: DirectionKey
  pagination: {
    pageSize: number
    page: number
  }
  userInfo: UserState
  web: 'rodi' | 'rodimotor' | 'elpaso'
}

const defaultSearchMotoOptions = {
  // applyMarcas: false,
  order: '',
  direction: 'ASC',
  pagination: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
  },
} as Partial<SearchParametersMoto>

export function searchMoto(
  options: Partial<SearchParametersMoto> = defaultSearchMotoOptions
) {
  return {
    type: SEARCH,
    payload: {
      ...defaultSearchMotoOptions,
      ...options,
    },
  }
}

export function resetSearchMoto() {
  return {
    type: RESET_SEARCH,
    payload: {},
  }
}
