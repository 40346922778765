import { all, put, takeLatest } from 'redux-saga/effects'
import * as GlobalActions from '../actions/global'
import * as api from '../../api/appSetup'
import { init } from '../actions/appSetupActions'

function* initApp() {
  const initData = yield api.init()
  yield put(init(initData))
}

function* sagas() {
  return all([yield takeLatest(GlobalActions.INIT, initApp)])
}

export default sagas
