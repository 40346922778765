import { t } from '../../../../common/i18n'
import { BateriasParams, GetBateriasList } from '../../api/getBaterias'
import { OrderEntry } from '../../type/order'
export const BATERIAS_LIST = 'BATERIAS_LIST::LIST'
export const BATERIAS_LIST_OK = 'BATERIAS_LIST::LIST_OK'
export const BATERIAS_LIST_FAILED = 'BATERIAS_LIST::LIST_FAILED'
export const BATERIAS_LIST_RESET = 'BATERIAS_LIST::LIST_RESET'

const defaultList = {
  pagination: {
    pageSize: 10,
    page: 1,
  },
}
export const ORDER_OPTIONS = [
  {
    label: t('baterias.order.recomendado'),
    value: 'orden.asc',
    field: 'orden',
    direction: 'ASC',
  },
  {
    label: `${t('baterias.order.marcas')} A->Z`,
    value: 'marca.asc',
    field: 'marca',
    direction: 'ASC',
  },
  {
    label: `${t('baterias.order.marcas')} Z->A`,
    value: 'marca.desc',
    field: 'marca',
    direction: 'DESC',
  },
  {
    label: t('baterias.order.mas_caros'),
    value: 'precio.desc',
    field: 'precio',
    direction: 'DESC',
  },
  {
    label: t('baterias.order.mas_baratos'),
    value: 'precio.asc',
    field: 'precio',
    direction: 'ASC',
  },
] as OrderEntry[]

export function bateriasListAction(params: Partial<BateriasParams>) {
  return { type: BATERIAS_LIST, payload: { ...defaultList, ...params } }
}

export function bateriasListOkAction(data: GetBateriasList) {
  return { type: BATERIAS_LIST_OK, payload: { data } }
}

export function bateriasListKoAction(message: string) {
  return { type: BATERIAS_LIST_FAILED, payload: { error: message } }
}
export function bateriasListResetAction() {
  return { type: BATERIAS_LIST_RESET }
}
