export const getShortCompany = () => {
  if (process.env.GATSBY_WEB === 'elpaso') {
    return 'El Paso'
  }
  return 'Rodi'
}

export const getFullCompany = () => {
  if (process.env.GATSBY_WEB === 'elpaso') {
    return 'El Paso 2000'
  }
  return 'Rodi Motor Services'
}
