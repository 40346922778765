import { IAppState } from '../context'
import {
  IMedida,
  key,
  Selectable,
  SelectorState,
} from '../reducers/selectorReducer'
import { selectMinimalUserInfo } from './userSelectors'

const medidas = ['ancho', 'serie', 'llanta', 'carga', 'velocidad']

export function selectIsRodi() {
  return process.env.GATSBY_WEB === 'rodi'
}

export function selectIsRodimotor() {
  return process.env.GATSBY_WEB === 'rodimotor'
}

export function selectSelector(state: IAppState): SelectorState {
  return state[key]
}

/**
 * Array de medidas seleccionadas (objectos nombre/valor)
 * @param state Application state
 */
export function selectMedidas(state: IAppState): IMedida[] {
  const selectorState = selectSelector(state)
  const result = [] as IMedida[]
  const ms = selectorState.pristine ? medidas.slice(0, 1) : medidas
  ms.forEach((m) => {
    const state = selectorState[m] as Selectable<any>
    if (!state.loading && state.value !== null) {
      result.push({
        name: m,
        value: state.value,
      } as IMedida)
    }
  })

  return result
}

export function selectTipoVehiculo(state: IAppState) {
  const selectorState = selectSelector(state)
  return selectorState.tipoVehiculo
}

export function selectRunflat(state: IAppState) {
  const selectorState = selectSelector(state)
  return selectorState.runflat.value
}

export function selectOrigen(state: IAppState) {
  const selectorState = selectSelector(state)
  return selectorState.origen
}

export function selectSkipInfo(state: IAppState) {
  const selectorState = selectSelector(state)
  return {
    runflat: !selectorState.runflat.selectable,
    temporada: !selectorState.temporada.selectable,
    marca: selectorState.marca !== null,
    datos: !selectIsRodimotor() && !selectIsRodi(),
    ...selectMinimalUserInfo(state),
  }
}
