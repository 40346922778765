import {
  DEFAULT_VALUES_FEATURES_MODELO,
  DEFAULT_VALUES_MEDIDAS_MOTO,
  defaultOptionsAplicacion,
  IMedida,
  OrderedFeaturesModeloMoto,
  registroMarca,
  SelectorModeloMoto,
  SelectorMotoState,
} from '../context/reducers/selectorMotoReducer'
import { apiCall } from './api'
import { UserState } from '../context/reducers/userReducer'

const baseUrl = `${process.env.GATSBY_API_URL}`

export interface OptionMedida {
  label: string
  value: string
  principal?: boolean
  orden?: number
}

interface ReponseTyresMoto {
  ancho: OptionMedida[]
  alto: OptionMedida[]
  llanta: OptionMedida[]
  filterables: {
    marcas: OptionMedida[]
    tipos: OptionMedida[]
    gamas: OptionMedida[]
  }
}

export interface ResponseGetMedidas {
  ancho: string
  alto: string
  llanta: string
}

export const loadMedidasMoto = (
  medidas: IMedida[],
  selectorMotoState: SelectorMotoState
): Promise<Partial<SelectorMotoState>> => {
  const query = medidas.map((medida) => {
    return `${encodeURIComponent(medida.name)}=${encodeURIComponent(
      medida.value
    )}`
  })

  return apiCall(`${baseUrl}/tyres-with-values-moto?${query.join('&')}`).then(
    (items: ReponseTyresMoto) => {
      return {
        ancho: {
          loading: false,
          value: selectorMotoState.ancho.value,
          default: DEFAULT_VALUES_MEDIDAS_MOTO.ancho,
          options: items.ancho,
        },
        alto: {
          loading: false,
          value: selectorMotoState.alto.value,
          default: DEFAULT_VALUES_MEDIDAS_MOTO.alto,
          options: items.alto,
        },
        llanta: {
          loading: false,
          value: selectorMotoState.llanta.value,
          default: DEFAULT_VALUES_MEDIDAS_MOTO.llanta,
          options: items.llanta,
        },
        filterables: {
          marcas: items.filterables.marcas,
          tipos: items.filterables.tipos,
          gamas: items.filterables.gamas,
        },
        filtros: {
          marcas: [],
          tipos: [],
          gamas: [],
        },
      }
    }
  )
}

export const loadDefaultMedidasMoto = (): Promise<
  Partial<SelectorMotoState>
> => {
  return apiCall(
    `${process.env.GATSBY_API_URL}/tyres-default-values-moto`
  ).then((items: ReponseTyresMoto) => {
    return {
      ancho: {
        loading: false,
        value: DEFAULT_VALUES_MEDIDAS_MOTO.ancho,
        default: DEFAULT_VALUES_MEDIDAS_MOTO.ancho,
        options: items.ancho,
      },
      alto: {
        loading: false,
        value: DEFAULT_VALUES_MEDIDAS_MOTO.alto,
        default: DEFAULT_VALUES_MEDIDAS_MOTO.alto,
        options: items.alto,
      },
      llanta: {
        loading: false,
        value: DEFAULT_VALUES_MEDIDAS_MOTO.llanta,
        default: DEFAULT_VALUES_MEDIDAS_MOTO.llanta,
        options: items.llanta,
      },
      filterables: {
        marcas: items.filterables.marcas,
        tipos: items.filterables.tipos,
        gamas: items.filterables.gamas,
      },
      filtros: {
        marcas: [],
        tipos: [],
        gamas: [],
      },
    }
  })
}

interface ResponseModeloMoto {
  initialValues?: {
    marca: string
    cilindrada: string
    modelo: string
    fabricacion: string
    aplicacion: string
  }
  marcas: OptionMedida[]
  cilindradas: OptionMedida[]
  modelos: OptionMedida[]
  fabricacion: OptionMedida[]
}
export function loadValuesFeaturesModeloMoto(
  selectorModeloMoto: SelectorModeloMoto
) {
  const query = OrderedFeaturesModeloMoto.map((feature) => {
    return `${encodeURIComponent(feature)}=${encodeURIComponent(
      selectorModeloMoto[feature].value
    )}`
  })
  query.push(
    `${encodeURIComponent('aplicacion')}=${encodeURIComponent(
      selectorModeloMoto.aplicacion.value
    )}`
  )
  return apiCall(
    `${process.env.GATSBY_API_URL}/modelos/moto/values?${query.join('&')}`
  ).then((res: ResponseModeloMoto) => {
    return {
      marca: {
        loading: false,
        value: selectorModeloMoto.marca.value,
        default: null,
        options: res.marcas,
      },
      cilindrada: {
        loading: false,
        value: selectorModeloMoto.cilindrada.value,
        default: null,
        options: res.cilindradas,
      },
      modelo: {
        loading: false,
        value: selectorModeloMoto.modelo.value,
        default: null,
        options: res.modelos,
      },
      fabricacion: {
        loading: false,
        value: selectorModeloMoto.fabricacion.value,
        default: null,
        options: res.fabricacion,
      },
      aplicacion: {
        loading: false,
        value: selectorModeloMoto.aplicacion.value,
        default: DEFAULT_VALUES_FEATURES_MODELO.aplicacion,
        options: defaultOptionsAplicacion,
      },
    } as Partial<SelectorModeloMoto>
  })
}
export function loadDefaultFeaturesModeloMoto() {
  return apiCall(`${process.env.GATSBY_API_URL}/modelos/moto/default`).then(
    (res: ResponseModeloMoto) => {
      return {
        marca: {
          loading: false,
          value: res.initialValues.marca,
          default: DEFAULT_VALUES_FEATURES_MODELO.marca,
          options: res.marcas,
        },
        cilindrada: {
          loading: false,
          value: res.initialValues.cilindrada,
          default: DEFAULT_VALUES_FEATURES_MODELO.cilindrada,
          options: res.cilindradas,
        },
        modelo: {
          loading: false,
          value: res.initialValues.modelo,
          default: DEFAULT_VALUES_FEATURES_MODELO.modelo,
          options: res.modelos,
        },
        fabricacion: {
          loading: false,
          value: res.initialValues.fabricacion,
          default: DEFAULT_VALUES_FEATURES_MODELO.fabricacion,
          options: res.fabricacion,
        },
        aplicacion: {
          loading: false,
          value: res.initialValues.aplicacion,
          default: DEFAULT_VALUES_FEATURES_MODELO.aplicacion,
          options: defaultOptionsAplicacion,
        },
      } as Partial<SelectorModeloMoto>
    }
  )
}

export function getMedidasFromModeloMoto(
  selectorModeloMoto: SelectorModeloMoto
) {
  return apiCall(`${process.env.GATSBY_API_URL}/modelos/moto/getMedidasTyre`, {
    method: 'POST',
    body: JSON.stringify({
      marca: selectorModeloMoto.marca.value,
      cilindrada: selectorModeloMoto.cilindrada.value,
      modelo: selectorModeloMoto.modelo.value,
      fabricacion: selectorModeloMoto.fabricacion.value,
      aplicacion: selectorModeloMoto.aplicacion.value,
    }),
  }).then((response) => {
    return response.medidas as ResponseGetMedidas
  })
}

export function registerMarcaMoto(
  device: 'MOBILE' | 'TABLET' | 'DESKTOP',
  userAgent: string,
  registroMarca: registroMarca,
  userInfo: UserState
) {
  return apiCall(
    `${process.env.GATSBY_API_URL}/neumaticos-moto/register-marca`,
    {
      method: 'POST',
      body: JSON.stringify({
        marca: registroMarca.marca,
        ancho: registroMarca.ancho,
        alto: registroMarca.alto,
        llanta: registroMarca.llanta,
        email: userInfo.email,
        phone: userInfo.phone,
        zipcode: userInfo.postCode,
        origen: '/',
        device,
        useragent: userAgent,
      }),
    }
  )
}
