import { apiCall } from './api'
import { UserState } from '../context/reducers/userReducer'
import { Aceite } from '../types/Aceite'

const baseUrl = `${process.env.GATSBY_API_URL}`

export const getUserAceite = (
  matricula: string,
  userInfo: UserState
): Promise<Aceite> => {
  return apiCall(`${baseUrl}/aceite-por-matricula`, {
    method: 'POST',
    body: JSON.stringify({ matricula, userInfo }),
  })
}

export const getAceiteAlternativo = (matricula: string): Promise<Aceite[]> => {
  return apiCall(`${baseUrl}/aceite-alternativo`, {
    method: 'POST',
    body: JSON.stringify({ matricula }),
  })
}
