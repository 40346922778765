import * as Actions from '../actions/userActions'
import { LeadResult } from '../../api/user'

export const version = 1
export const key = 'userInfo'

export interface IUserData {
  id?: string
  name: string
  surname: string
  email: string
  dni: string
  documentType: string
  documentCountry: string
  birthday_day: string
  birthday_month: string
  birthday_year: string
  gender: string
  usuario_nav?: string
  gender_input: string
  phone: string
  promo_sms: number
  promo_email: number
  postCode: string
  permiso_comercial: boolean
}

export interface IUserLeads {
  id_site_lead?: string
  telefono: string
  email: string
  fecha_creacion: string
  codigo_postal: string
  permiso_comercial: boolean
}

export interface IAddressData {
  address: string
  block: string
  number: string
  city: string
  province: string
  postCode: string
  phone: string
}

export interface ValidationErrors {
  field: 'email' | 'phone'
  message: string
  type: string
}

export const initialState = {
  _version: version,
  loading: false as boolean,
  loggedIn: false as boolean,
  token: null as string | null,
  idUser: null as string | null,
  userName: null as string | null,
  email: null as string | null,
  postCode: null as string | null,
  phone: null as string | null,
  percentCompleted: 0 as number,
  error: null as null | string,
  errors: null as ValidationErrors[],
  userData: null as IUserData | null,
  addressData: null as IAddressData | null,
  userLead: null as IUserLeads | null,
  updateSuccess: false as boolean,
  idLead: null as null | number,
}

export type UserState = typeof initialState

export interface LeadInfo {
  email: string
  phone: string
  postCode: string
  wantsInfo: boolean
  origen: string
}

export interface RegisterInfo {
  email: string
  phone: string
  postCode: string
  name: string
}

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.LOGOUT:
      return {
        ...initialState,
      }
    case Actions.RESET_ERROR:
      return {
        ...state,
        error: null,
        errors: null,
      }
    case Actions.ADD_ALL_USER_INFO:
      return {
        ...state,
        ...action.payload,
      }
    case Actions.LOGIN:
    case Actions.IMPERSONATE:
      return {
        ...state,
        loading: true,
        loggedIn: false,
        error: null,
      }

    case Actions.LOGIN_OK:
      return {
        ...state,
        loading: false,
        loggedIn: true,
        ...action.payload.user,
      }

    case Actions.LOGIN_FAILED: {
      return {
        ...state,
        loading: false,
        loggedIn: false,
        error: action.payload,
      }
    }

    case Actions.GET_USER_DATA:
      return {
        ...state,
        loading: true,
        userData: null,
        addressData: null,
      }
    case Actions.GET_USER_DATA_OK:
      return {
        ...state,
        loading: false,
        error: null,
        ...action.payload,
      }
    case Actions.GET_USER_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        userData: null,
        addressData: null,
      }
    case Actions.SET_USER_DATA:
      return {
        ...state,
        loading: true,
        updateSuccess: false,
        error: null,
      }
    case Actions.SET_USER_DATA_OK:
      return {
        ...state,
        updateSuccess: true,
        loading: false,
        error: null,
        errors: null,
        ...action.payload,
      }
    case Actions.SET_USER_DATA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
        updateSuccess: false,
      }

    case Actions.SET_USER_DATA_VALIDATION_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      }
    case Actions.SET_USER_PASSWORD_FAILED:
      return {
        ...state,
        loading: false,
      }
    case Actions.CLEAR_SUCCESS:
      return {
        ...state,
        updateSuccess: false,
      }

    case Actions.LOAD_LEAD_INFO:
      const leadResult = action.payload as LeadResult
      return {
        ...state,
        idLead: leadResult.id_site_lead,
        email: leadResult.email,
        phone: leadResult.telefono,
        postCode: leadResult.codigo_postal,
        userLead: {
          ...leadResult,
        },
      }
    case Actions.STORE_USER_DATA:
      return {
        ...state,
        ...action.payload,
      }

    default:
      return state
  }
}
