const enabled = () => !!process.env.GATSBY_GTM_ID
const isBrowser = typeof window !== 'undefined'

const _debounceCache = {}
const debounce = (
  fn: (message: object) => void,
  ms: number,
  message: object
) => {
  if (typeof message === 'undefined') {
    console.log('UNDEFINED MESSAGE', message)
    return
  }
  const json = JSON.stringify(message)
  if (_debounceCache[json]) {
    return
  }

  fn(message)
  _debounceCache[json] = true
  setTimeout(() => {
    delete _debounceCache[json]
  }, ms)
}

if (isBrowser && process.env.GATSBY_GTM_ID === 'test') {
  window.dataLayer = {
    push: console.log,
  }
}

if (enabled() && isBrowser && typeof window.dataLayer === 'undefined') {
  window.dataLayer = []
}

const push = (info: object) => {
  if (!isBrowser) {
    return
  }

  if (enabled()) {
    debounce(window.dataLayer.push.bind(window.dataLayer), 1000, info)
  }
}

const dataLayer = {
  push,
  enabled,
}

export default dataLayer
