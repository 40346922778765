import { apiCall } from './api'

const baseUrl = `${process.env.GATSBY_API_URL}`

interface InitResponse {
  allowedMixedOrders: boolean
}
export const init = (): Promise<InitResponse> => {
  return apiCall(`${baseUrl}/site-init`)
}
