import { InContactForm } from '../components/contacto/FormContacto/FormContacto'
import { Product, Service } from '../context/reducers/cestaReducer'
import { LeadInfo, ValidationErrors } from '../context/reducers/userReducer'
import { _token, apiCall, setToken } from './api'
import codigosPostales from '../../specific/constants/cp'

const baseUrl = `${process.env.GATSBY_API_URL}`

interface UserResponse {
  id_site_usuario: number
  id_usuario_nav: string | null
  nombre: string
  apellidos: string | null
  email: string
  tipo_documento: string | null
  numero_documento: string | null
  pais_documento: string | null
  genero: string | null
  genero_descripccion: string | null
  fecha_nacimiento: string | null
  promo_email: number
  promo_sms: number
  permiso_comercial: boolean
}

interface AddressResponse {
  id_site_usuario_direcciones: number
  calle?: string
  numero?: string
  bloque?: string
  poblacion?: string
  provincia?: string
  codigo_postal?: string
  telefono?: string
}

export interface APIUserResponse {
  errors?: ValidationErrors[]
  user: UserResponse
  address: AddressResponse
  token?: string
  percentCompleted: number
  cesta: {
    products: Product
    services: Service
    cestaID: string
  }
}

export function registerUser({
  name,
  email,
  phone,
  postCode,
  password,
  checkboxComercial,
}): Promise<APIUserResponse> {
  // Include province in registration if we know the Zip Code
  const provinciaCP = codigosPostales.find((codigoPostal) =>
    postCode.startsWith(codigoPostal.cp)
  )
  const provincia = provinciaCP ? provinciaCP.provincia : ''

  return apiCall(`${process.env.GATSBY_API_URL}/users`, {
    method: 'POST',
    body: JSON.stringify({
      nombre: name,
      email,
      password,
      telefono: phone,
      codigo_postal: postCode,
      provincia,
      permiso_comercial: checkboxComercial,
    }),
  }).then((res) => {
    const { token } = res
    setToken(token)
    return res
  })
}

export function authenticate(
  email: string,
  password: string,
  cestaID?: string
): Promise<APIUserResponse> {
  return apiCall(`${process.env.GATSBY_API_URL}/users/auth`, {
    method: 'POST',
    body: JSON.stringify({ email, password, cestaID }),
  }).then((res) => {
    const { token } = res
    setToken(token)
    return res
  })
}

export function retrieveUserInfo(): Promise<APIUserResponse> {
  return apiCall(`${process.env.GATSBY_API_URL}/users/fetchInfo`, {
    auth: true,
    error: 'Ha habido un problema cargando tus datos',
  })
}

export function impersonate(token: string): Promise<APIUserResponse> {
  setToken(token)
  return retrieveUserInfo()
}

export function updateUser(
  idUser: string,
  data: any
): Promise<APIUserResponse> {
  return apiCall(`${process.env.GATSBY_API_URL}/users/${idUser}`, {
    method: 'PATCH',
    auth: true,
    body: JSON.stringify(data),
  })
}

export interface LeadResult {
  errors?: ValidationErrors[]
  id_site_cesta: null | number
  id_site_lead: number
  email: string
  telefono: string
  codigo_postal: string
  origen: string
  permiso_comercial: boolean
}

export function registerLead(info: LeadInfo): Promise<LeadResult> {
  return apiCall(`${baseUrl}/leads`, {
    method: 'POST',
    body: JSON.stringify({
      email: info.email,
      telefono: info.phone,
      codigo_postal: info.postCode,
      origen: info.origen,
      wantsInfo: info.wantsInfo,
    }),
  })
}

export interface APIHistoryResponse {}

export function findClientHistory(idUser: string) {
  return apiCall(
    `${process.env.GATSBY_API_URL}/users/${encodeURIComponent(idUser)}/history`,
    {
      auth: true,
    }
  )
}

export function findClientByNIF(
  nif: string,
  documentType: string,
  documentCountry: string,
  userID: string
) {
  return apiCall(`${process.env.GATSBY_API_URL}/users/add-client/ws`, {
    method: 'POST',
    body: JSON.stringify({
      numero_documento: nif,
      pais_documento: documentCountry || 'ES',
      tipo_documento: documentType || 'DNI',
      idUser: userID,
    }),
  })
}

export function addToNewsLetter(email: string, domain: string) {
  return apiCall(`${process.env.GATSBY_API_URL}/newsletter`, {
    method: 'POST',
    body: JSON.stringify({
      email,
      domain,
    }),
  })
}

export function checkNewsLetterToken(token) {
  return apiCall(`${baseUrl}/newsletter/${token}`)
}

export function getDistritoFromCpPt(postCode) {
  return apiCall(`${baseUrl}/distrito/${postCode}`)
}

export function unsubsScribeNewsLetter(token) {
  return apiCall(`${baseUrl}/unsubscribe-newsletter/${token}`)
}

export function sendContacto(data: InContactForm) {
  return apiCall(`${process.env.GATSBY_API_URL}/contactar`, {
    method: 'POST',
    body: JSON.stringify({
      data,
    }),
  })
}

export async function retrieveFactura(
  idUser: string,
  idFactura: string
): Promise<any> {
  return fetch(`${baseUrl}/users/${idUser}/get-factura/${idFactura}`, {
    method: 'GET',
    headers: new Headers({
      Authorization: 'Bearer ' + _token,
      'Content-Type': 'application/x-www-form-urlencoded',
    }),
  }).then((res) => {
    if (res.status !== 200) {
      return res.json()
    }
    return res
  })
}

export function retrievePedidoProducts(userID, pedidoID) {
  return apiCall(
    `${process.env.GATSBY_API_URL}/users/${userID}/pedidos-products/${pedidoID}`,
    {
      auth: true,
    }
  )
}
