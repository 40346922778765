import { all, put, takeLatest } from 'redux-saga/effects'
import { t } from '../../../../common/i18n'

import { getMarcas as getMarcasApi } from '../../api/getMarcas'
import { marcaKoAction, marcaOkAction } from './marcasAction'
import * as MarcaAction from './marcasAction'

export function* getMarcas() {
  try {
    const marcasData = yield getMarcasApi()
    yield put(marcaOkAction(marcasData))
  } catch (err) {
    yield put(marcaKoAction(t('No hay marcas')))
  }
}
function* sagas() {
  return all([yield takeLatest(MarcaAction.BATERIAS_MARCA_LIST, getMarcas)])
}

export default sagas
