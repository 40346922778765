import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import * as RevisionActions from '../actions/revisionActions'
import {
  addCarInfoFailed,
  addCarInfoNoRevision,
  setCarInfo,
  setOilSelection,
  setRevisionStep,
  startOilJourneyOK,
} from '../actions/revisionActions'
import { selectRevision } from '../selectors/revisionSelector'
import { getAllTallerServices, getRevisionesList } from '../../logic'
import { setAltaVehiculoStep } from '../actions/altaVehiculoActions'
import { findOils } from '../../api/revisiones'
import {
  fromRevision,
  setSelectedService,
  setVehicle,
} from '../actions/citaPreviaActions'
import { navigate } from 'gatsby'
import route from '../../utils/route'
import {
  AllServices,
  SelectedService,
  ServicioTaller,
} from '../reducers/citaPreviaReducer'

function* findOil() {
  try {
    const state = yield select()
    const revisionState = yield selectRevision(state)
    const { vehiculoInfo: vehicle, matricula, kms } = revisionState
    const result = yield findOils(vehicle, matricula, kms)
    const { vehicleOils, status, modelo, fuelType } = result
    if (status === 'OK') {
      if (vehicleOils.length > 0) {
        yield put(setCarInfo(matricula, kms, vehicleOils, modelo))
        yield put(setRevisionStep(3))
      }
    } else {
      if (status === '2YEARS') {
        yield put(addCarInfoNoRevision(status, fuelType))
        yield put(setRevisionStep(2))
        yield put(setAltaVehiculoStep(6))
      } else if (status === 'NO_OIL') {
        yield put(setVehicle(vehicle))
        const services: AllServices = yield getAllTallerServices()
        const mantenimientoService = services.serviciosCoche.find(
          (service) => service.codigo === 'mant'
        )

        yield put(
          setSelectedService({
            ...mantenimientoService,
            vehicle: mantenimientoService.tipo_vehiculo,
            service: mantenimientoService.codigo,
          })
        )
        yield put(fromRevision())
        navigate(route('cita-previa.seleccion'))
      } else {
        yield put(addCarInfoFailed('Esta matricula no existe'))
      }
    }
  } catch (err) {
    yield put(addCarInfoFailed(err))
  }
}
function* startOilJourney() {
  const state = yield select()
  const revisionState = yield selectRevision(state)

  const {
    aceiteSeleccionadoInfo: oil,
    vehiculoInfo: vehicle,
    postCode,
    kms,
  } = revisionState

  // If we don't have vehicle selected go to step2 to choose vehicle
  if (!vehicle) {
    yield put(setRevisionStep(2))
    yield put(startOilJourneyOK())
    return
  }

  // We will try to find previous used oil for the vehicle
  let selectedOil = null
  if (oil && oil.id !== 5) {
    selectedOil = oil.id
  }
  //else {
  //   const response = yield getAceiteAlternativo(vehicle.cod_matricula)
  //   if (response.alternativo) {
  //     yield put(setOilInfo(response.alternativo))
  //     selectedOil = response.alternativo.aplicacion
  //   }
  // }
  // If we can provide a previous oil application we send user to step 4 to select
  // the revision type
  if (selectedOil) {
    const revisiones = yield getRevisionesList(selectedOil, postCode)
    yield put(setOilSelection(`${selectedOil}`, revisiones))
    yield put(setRevisionStep(4))
    yield put(startOilJourneyOK())
    return
  }

  // In case of don't have previous oil but user filled the kms we start new saga
  // to find the recommended oils
  if (kms) {
    yield call(findOil)
    yield put(startOilJourneyOK())
    return
  }

  // If we don't hace any vehicle, oil or kms info we send user to step 1 to request the info
  yield put(setRevisionStep(1))
  yield put(startOilJourneyOK())
}
function* sagas() {
  return all([
    yield takeLatest(RevisionActions.START_OIL_JOURNEY, startOilJourney),
    yield takeLatest(RevisionActions.START_FIND_OIL, findOil),
  ])
}

export default sagas
