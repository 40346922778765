import { IUserData, UserState } from '../reducers/userReducer'
import {
  APIUserResponse,
  authenticate,
  impersonate,
  LeadResult,
  retrieveUserInfo,
  updateUser,
} from '../../api/user'
import { t } from '../../../common/i18n'
import dataLayer from '../../helpers/functions/dataLayer'

export const LOGOUT = 'USER::LOGOUT'
export const RESET_ERROR = 'USER::RESET_ERROR'

export const LOGIN = 'USER::LOGIN'
export const LOGIN_OK = 'USER::LOGIN_OK'
export const LOGIN_FAILED = 'USER::LOGIN_FAILED'
export const IMPERSONATE = 'USER::IMPERSONATE'

export const ADD_ALL_USER_INFO = 'ADD_ALL_USER_INFO'

export const GET_USER_DATA = 'USER::GET_USER_DATA'
export const GET_USER_DATA_OK = 'USER::GET_USER_DATA_OK'
export const GET_USER_DATA_FAILED = 'USER::GET_USER_DATA_FAILED'

export const SET_USER_DATA = 'USER::SET_USER_DATA'
export const SET_USER_DATA_OK = 'USER::SET_USER_DATA_OK'
export const SET_USER_DATA_FAILED = 'USER::SET_USER_DATA_FAILED'
export const SET_USER_DATA_VALIDATION_ERRORS =
  'USER::SET_USER_DATA_VALIDATION_ERRORS'

export const LOAD_LEAD_INFO = 'USER::LOAD_LEAD_INFO'
export const SET_USER_PASSWORD_FAILED = 'USER::CHANGE_USER_PASSWORD_FAILED'

export const CHECK_LOGIN = 'USER::CHECK_LOGIN'

export const CLEAR_SUCCESS = 'USER::CLEAR_SUCCESS'

export const STORE_USER_DATA = 'USER::STORE_USER_DATA'

export const logout = (redirect = true) => ({
  type: LOGOUT,
  redirect,
})

export function setUserErrorNull() {
  return {
    type: RESET_ERROR,
  }
}

export function checkLogin() {
  return { type: CHECK_LOGIN }
}

export function impersonateInit(token) {
  return {
    type: IMPERSONATE,
    payload: { token },
  }
}

export function loginOk(response: APIUserResponse, type: 'registro' | 'login') {
  const data = transformData(response)
  dataLayer.push({
    event: type,
    user_id: data.idUser,
  })
  return {
    type: LOGIN_OK,
    payload: { user: data, cesta: response.cesta },
  }
}

export function login(email: string, password: string, cestaID?: string) {
  return (dispatch) => {
    dispatch({
      type: LOGIN,
      payload: { email, password },
    })

    authenticate(email, password, cestaID)
      .then((res) => {
        dispatch(loginOk(res, 'login'))
      })
      .catch((err) => {
        if (err.message === 'WRONG_CREDENTIALS') {
          dispatch({
            type: LOGIN_FAILED,
            payload: t('login.credenciales_incorrectas'),
          })
        } else {
          dispatch({
            type: LOGIN_FAILED,
            payload: t('login.error_generico'),
          })
        }
      })
  }
}

export function impersonateUser(token: string) {
  return (dispatch) => {
    dispatch(logout(false))
    dispatch(impersonateInit(token))

    impersonate(token)
      .then((res) => {
        dispatch(loginOk(res, 'login'))
      })
      .catch((err) => {
        if (err.message === 'WRONG_CREDENTIALS') {
          dispatch({
            type: LOGIN_FAILED,
            payload: t('login.credenciales_incorrectas'),
          })
        } else {
          dispatch({
            type: LOGIN_FAILED,
            payload: t('login.error_generico'),
          })
        }
      })
  }
}

export function clearSuccess() {
  return {
    type: CLEAR_SUCCESS,
  }
}

export function getUserData() {
  return (dispatch) => {
    dispatch({
      type: GET_USER_DATA,
    })
    retrieveUserInfo()
      .then((res) => {
        dispatch({
          type: GET_USER_DATA_OK,
          payload: transformData(res),
        })
      })
      .catch((err) => {
        dispatch({
          type: GET_USER_DATA_FAILED,
          payload: {
            error: t('mis-datos.error_actualizar_datos'),
          },
        })
      })
  }
}

export function setUserData(idUser: string, newData: IUserData) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
    })
    updateUser(idUser, newData)
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: SET_USER_DATA_VALIDATION_ERRORS,
            payload: {
              errors: res.errors,
            },
          })
        } else {
          dispatch({
            type: SET_USER_DATA_OK,
            payload: transformData(res),
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: SET_USER_DATA_FAILED,
          payload: {
            error: t('mis-datos.error_actualizar_datos'),
          },
        })
      })
  }
}

export function changePassword(idUser: string, newData: IUserData) {
  return (dispatch) => {
    dispatch({
      type: SET_USER_DATA,
    })
    updateUser(idUser, newData)
      .then((res) => {
        dispatch({
          type: SET_USER_DATA_OK,
          payload: transformData(res),
        })
      })
      .catch((err) => {
        dispatch({
          type: SET_USER_PASSWORD_FAILED,
        })
      })
  }
}

export function loadLeadInfo(info: LeadResult) {
  return {
    type: LOAD_LEAD_INFO,
    payload: info,
  }
}

export function storeUserData(data) {
  return {
    type: STORE_USER_DATA,
    payload: data,
  }
}

export const transformData = (
  response: APIUserResponse
): Partial<UserState> => {
  const result = {
    idUser: response.user.id_site_usuario.toString(),
    userName: response.user.nombre,
    email: response.user.email,
    postCode: response.address.codigo_postal,
    phone: response.address.telefono,
  } as Partial<UserState>
  if (response.token) {
    result.token = response.token
  }
  result.userData = {
    name: response.user.nombre,
    surname: response.user.apellidos,
    email: response.user.email,
    dni: response.user.numero_documento,
    documentCountry: response.user.pais_documento,
    documentType: response.user.tipo_documento,
    birthday_day:
      response.user.fecha_nacimiento !== null
        ? response.user.fecha_nacimiento.split('-')[0]
        : null,
    birthday_month:
      response.user.fecha_nacimiento !== null
        ? response.user.fecha_nacimiento.split('-')[1]
        : null,
    birthday_year:
      response.user.fecha_nacimiento !== null
        ? response.user.fecha_nacimiento.split('-')[2]
        : null,
    gender: response.user.genero,
    usuario_nav: response.user.id_usuario_nav,
    gender_input: response.user.genero_descripccion,
    phone: response.address.telefono,
    promo_sms: response.user.promo_sms,
    promo_email: response.user.promo_email,
    postCode: response.address.codigo_postal,
    permiso_comercial: response.user.permiso_comercial,
  }
  result.addressData = {
    address: response.address.calle,
    block: response.address.bloque,
    number: response.address.numero,
    city: response.address.poblacion,
    province: response.address.provincia,
    postCode: response.address.codigo_postal,
    phone: response.address.telefono,
  }
  result.percentCompleted = response.percentCompleted

  return result
}
