import * as Actions from '../actions/citaPreviaActions'
import { ValidationErrors } from './userReducer'
import { ITime } from '../../api/citaPrevia'
import { SelectedTaller } from '../../types/Taller'
import { ServiceTaller } from '../../components/CitaPrevia/Servicios/Servicios'
import { Vehicle } from '../../types/Vehiculo'

export const version = 4

export interface ServicioTaller extends ServiceTaller {
  codigo: string
  area_privada: number
  orden: number
  tipo_vehiculo: VehicleTypes
}

export interface AvailableServices {
  coche: ServiceTaller[]
  moto: ServiceTaller[]
  tabs: VehicleTypes[]
}
export interface AllServices {
  serviciosCoche: ServicioTaller[]
  serviciosMoto: ServicioTaller[]
  serviciosPatinete: ServicioTaller[]
}
export interface VerifiedTallerAndService {
  taller: SelectedTaller
  servicio: SelectedService
}

export interface CitaPreviaScooterDetails {
  scooterBrands: AvailableScooterBrand[]
  selectedBrand: AvailableScooterBrand
}
export interface AvailableScooterBrand {
  marca: string
  imagen: string
  orden: number
}

export interface VehicleTypes {
  id: number
  tab: 'Coche' | 'Coche electrico' | 'Moto'
  displayName_es: string
  displayName_ca: string
  active: boolean
}

export const key = 'citaPreviaData'

export interface SelectedService extends ServiceTaller {
  vehicle: VehicleTypes
}

export type InfoCitaModificar = {
  id_navision: string
  hora: ITime
  taller: string
}

export const initialState = {
  _version: version as number,
  codigoPostalIntroducido: '' as string,
  selectedTaller: {} as SelectedTaller,
  loading: false as boolean,
  step: 0,
  availableServices: null as AvailableServices | null,
  vehicleTypes: null as VehicleTypes[] | null,
  scooter: {} as CitaPreviaScooterDetails,
  error: null as string | null,
  errors: null as ValidationErrors[],
  selectedService: null as SelectedService | null,
  selectedDate: null as ITime | null,
  citaPreviaId: null as string | null,
  origin: null as string | null,
  userData: null as Actions.UserDataProps,
  anulado: false as boolean,
  modificando: null as InfoCitaModificar | null,
  vehicleInfo: null as Vehicle,
  fromRevision: false,
}
export type CitaPreviaState = typeof initialState

export const reducer = (
  state: CitaPreviaState = initialState,
  action
): CitaPreviaState => {
  switch (action.type) {
    case Actions.CITA_PREVIA_SELECTED_CP:
      return {
        ...state,
        codigoPostalIntroducido: action.payload.cp,
      }
    case Actions.CITA_PREVIA_SELECTED_TALLER:
      return {
        ...state,
        selectedTaller: { ...action.payload.selectedTaller },
        codigoPostalIntroducido:
          state.codigoPostalIntroducido ||
          action.payload.selectedTaller.cpostal,
        origin: state.origin || action.payload.origin,
        selectedDate: !state.modificando
          ? initialState.selectedDate
          : state.selectedDate,
        citaPreviaId: !state.modificando
          ? initialState.citaPreviaId
          : state.citaPreviaId,
      }
    case Actions.CITA_PREVIA_SET_STEP:
      return {
        ...state,
        step: action.payload.step,
      }
    case Actions.CITA_PREVIA_SERVICES:
      if (state.loading) {
        return {
          ...state,
          error: null,
        }
      }
      return {
        ...state,
        loading: true,
        error: null,
      }
    case Actions.CITA_PREVIA_SERVICES_OK:
      return {
        ...state,
        loading: false,
        availableServices: action.payload.availableServices,
        vehicleTypes: action.payload.vehicleTypes,
      }
    case Actions.CITA_PREVIA_SERVICES_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case Actions.CITA_PREVIA_SET_USER_DATA:
      return {
        ...state,
        userData: action.payload.userData,
      }
    case Actions.CITA_PREVIA_CHANGE_TAB:
      return {
        ...state,
        vehicleTypes: action.payload.vehicleTypes,
      }
    case Actions.CITA_PREVIA_SELECT_SERVICE:
      return {
        ...state,
        selectedService: action.payload.selectedService,
        origin: action.payload.url || state.origin,
        selectedDate: !state.modificando
          ? initialState.selectedDate
          : state.selectedDate,
        citaPreviaId: !state.modificando
          ? initialState.citaPreviaId
          : state.citaPreviaId,
      }
    case Actions.CITA_PREVIA_SELECT_TALLER_AND_SERVICE:
      return {
        ...state,
        selectedTaller: action.payload.selectedTaller,
        selectedService: action.payload.selectedService,
        origin: action.payload.origin,
        step: 2,
        selectedDate: initialState.selectedDate,
        citaPreviaId: initialState.citaPreviaId,
      }
    case Actions.CITA_PREVIA_SET_SELECTED_DATE:
      return {
        ...state,
        selectedDate: action.payload.selectedDate,
      }
    case Actions.STORE_CITA_PREVIA:
      return {
        ...state,
        loading: true,
      }
    case Actions.MODIFY_CITA_PREVIA_OK:
    case Actions.STORE_CITA_PREVIA_OK:
      return {
        ...state,
        loading: false,
        error: null,
        modificando: null,
        citaPreviaId: action.payload.citaPreviaId,
      }
    case Actions.STORE_CITA_PREVIA_VALIDATION_ERRORS:
      return {
        ...state,
        loading: false,
        errors: action.payload.errors,
      }
    case Actions.STORE_CITA_PREVIA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case Actions.CITA_PREVIA_RESET:
      return {
        ...initialState,
      }
    case Actions.CITA_PREVIA_ORIGIN:
      return {
        ...state,
        origin: action.payload.url,
      }
    case Actions.MODIFY_CITA_PREVIA:
    case Actions.DELETE_CITA:
    case Actions.RETRIEVE_CITA:
      return {
        ...state,
        loading: true,
      }
    case Actions.RETRIEVE_CITA_OK:
      return {
        ...state,
        loading: false,
        ...action.payload,
      }
    case Actions.MODIFY_CITA_PREVIA_FAILED:
    case Actions.DELETE_CITA_FAILED:
    case Actions.RETRIEVE_CITA_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case Actions.DELETE_CITA_OK:
      return {
        ...state,
        loading: false,
        modificando: null,
        anulado: true,
      }
    case Actions.CITA_PREVIA_MODIFY_STATUS:
      return {
        ...state,
        modificando: action.payload.cita,
      }
    case Actions.RESET_ERROR:
      return {
        ...state,
        error: null,
      }
    case Actions.RESET_SELECTION:
      return {
        ...state,
        selectedDate: initialState.selectedDate,
        selectedService: initialState.selectedService,
      }
    case Actions.START_CITA_PREVIA:
    case Actions.START_CITA_PREVIA_PATINETE:
      return {
        ...state,
        origin: action.payload.url,
      }
    case Actions.CITA_PREVIA_SCOOTER_BRANDS_OK:
      return {
        ...state,
        loading: false,
        scooter: {
          ...state.scooter,
          scooterBrands: action.payload.scooterBrands,
        },
      }
    case Actions.CITA_PREVIA_SCOOTER_BRANDS_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case Actions.CITA_PREVIA_SET_SCOOTER_BRAND:
      return {
        ...state,
        loading: false,
        scooter: {
          ...state.scooter,
          selectedBrand: action.payload.selectedBrand,
        },
      }
    case Actions.CITA_PREVIA_SET_VEHICLE:
      return {
        ...state,
        loading: false,
        vehicleInfo: {
          ...action.payload.vehicleInfo,
        },
      }
    case Actions.CITA_PREVIA_SET_FROM_REVISION:
      return {
        ...state,
        loading: false,
        fromRevision: action.payload.fromRevision,
      }
    default:
      return state
  }
}
