import { all, put, select, takeLatest } from 'redux-saga/effects'
import {
  loadDefaultFeaturesModeloMoto,
  loadDefaultMedidasMoto,
  loadMedidasMoto,
  loadValuesFeaturesModeloMoto,
  registerMarcaMoto,
} from '../../api/selectorMoto'
import * as SelectorMotoActions from '../actions/selectorMotoActions'
import {
  loadOptionsFeaturesModelo,
  updateRegistroMarca,
} from '../actions/selectorMotoActions'
import {
  MedidaFeature,
  SelectorMotoState,
} from '../reducers/selectorMotoReducer'
import {
  selectMedidasMoto,
  selectSelectorMoto,
} from '../selectors/selectorMotoSelectors'
import { selectUserInfo } from '../selectors/userSelectors'
import { addSearchToken } from '../actions/cestaActions'

function* fetchMedidas() {
  const state = yield select()
  const medidas = selectMedidasMoto(state)
  const selectorState = selectSelectorMoto(state)
  const opciones = yield loadMedidasMoto(
    medidas,
    selectorState
  ) as Partial<SelectorMotoState>
  // debugger
  yield put(SelectorMotoActions.loadOptions(opciones))
}

function* loadOptions() {
  const state = yield select()
  const selectorMotoState = selectSelectorMoto(state)
  // si el campo alto solo trae un valor lo seteamos directamente
  // esto lo hacemos porque si el valor es nulo los select estan disabled
  // y se necesita setear el valor de alto para cargar las opciones de llanta
  if (
    selectorMotoState.ancho.value &&
    selectorMotoState.llanta.options.length === 0 &&
    selectorMotoState.alto.options.length === 1
  ) {
    yield put(
      SelectorMotoActions.selectMedidaMoto({
        name: 'alto',
        value: selectorMotoState.alto.options[0].value || '',
      })
    )
  }
}

function* fetchDefaults(action: ReturnType<typeof SelectorMotoActions.reset>) {
  const defaults = yield loadDefaultMedidasMoto()
  if (action.payload.keepValues) {
    action.payload.keepMedidas.forEach((m) => {
      defaults[m.name].value = m.value
    })
  }

  yield put({
    type: SelectorMotoActions.RESET_OK_MOTO,
    payload: defaults,
  })
}

function* setMedidas() {
  const state = yield select()
  const selectorMotoState = selectSelectorMoto(state)
  const opciones = yield loadMedidasMoto(
    [
      { name: 'ancho', value: selectorMotoState.ancho.value },
      { name: 'alto', value: selectorMotoState.alto.value },
      { name: 'llanta', value: selectorMotoState.llanta.value },
    ],
    selectorMotoState
  ) as Partial<SelectorMotoState>
  // debugger
  yield put(SelectorMotoActions.loadOptions(opciones))
  yield put(SelectorMotoActions.setErrorMedidaMoto(false))
}

interface ActionSelectFeatures {
  type: string
  payload: MedidaFeature
}

function* getFeaturesModeloMoto(action: ActionSelectFeatures) {
  if (
    action.payload.name !== 'aplicacion' &&
    action.payload.name !== 'fabricacion'
  ) {
    const state = yield select()
    const selectorMotoState = selectSelectorMoto(state)
    const selectorModeloMoto = selectorMotoState.selectorModelo
    const newValues = yield loadValuesFeaturesModeloMoto(selectorModeloMoto)
    yield put(loadOptionsFeaturesModelo(newValues))
  }
}

function* getFeaturesModeloMotoDefaults() {
  const defaults = yield loadDefaultFeaturesModeloMoto()
  yield put(loadOptionsFeaturesModelo(defaults))
}

function* registrarMarca(action) {
  const state = yield select()
  const selectorMotoState = selectSelectorMoto(state)
  const registroMarca = selectorMotoState.registroMarca
  if (
    selectorMotoState.ancho.value !== registroMarca.ancho ||
    selectorMotoState.alto.value !== registroMarca.alto ||
    selectorMotoState.llanta.value !== registroMarca.llanta ||
    selectorMotoState.marca !== registroMarca.marca
  ) {
    const newRegistro = {
      ancho: selectorMotoState.ancho.value,
      alto: selectorMotoState.alto.value,
      llanta: selectorMotoState.llanta.value,
      marca: selectorMotoState.marca,
    }
    // registramos los datos en redux
    yield put(updateRegistroMarca(newRegistro))
    const userInfo = selectUserInfo(state)
    const data = action.payload
    // guardamos los datos en server
    const { token } = yield registerMarcaMoto(
      data.device,
      data.userAgent,
      newRegistro,
      userInfo
    )

    if (token) {
      yield put(addSearchToken(token))
    }
  }
}

function* sagas() {
  return all([
    yield takeLatest(SelectorMotoActions.SELECT_MEDIDA_MOTO, fetchMedidas),
    yield takeLatest(SelectorMotoActions.RESET_MOTO, fetchDefaults),
    yield takeLatest(SelectorMotoActions.LOAD_OPTIONS_MOTO, loadOptions),
    yield takeLatest(
      SelectorMotoActions.RESET_FEATURES_MODELO,
      getFeaturesModeloMotoDefaults
    ),
    yield takeLatest(
      SelectorMotoActions.SELECT_FEATURE_MODELO,
      getFeaturesModeloMoto
    ),
    yield takeLatest(SelectorMotoActions.SET_MEDIDAS, setMedidas),
    yield takeLatest(SelectorMotoActions.REGISTRAR_MARCA, registrarMarca),
  ])
}

export default sagas
