import { FilterValues } from '../../api/getBaterias'
export const BATERIAS_FILTER_VALUES = 'BATERIAS_FILTER_VALUES::GET'
export const BATERIAS_FILTER_VALUES_OK = 'BATERIAS_FILTER_VALUES::GET_OK'
export const BATERIAS_FILTER_VALUES_FAILED =
  'BATERIAS_FILTER_VALUES::GET_FAILED'
export const BATERIAS_FILTER_VALUES_RESET = 'BATERIAS_FILTER_VALUES::GET_RESET'

export function bateriasFilterValuesAction() {
  return {
    type: BATERIAS_FILTER_VALUES,
  }
}

export function bateriasFilterValuesOkAction(data: FilterValues) {
  return { type: BATERIAS_FILTER_VALUES_OK, payload: { data } }
}

export function bateriasFilterValuesKoAction(message: string) {
  return { type: BATERIAS_FILTER_VALUES_FAILED, payload: { error: message } }
}
export function bateriasFilterValuesResetAction() {
  return { type: BATERIAS_FILTER_VALUES_RESET }
}
