import { all, put, takeLatest } from 'redux-saga/effects'
import { t } from '../../../../common/i18n'
import {
  bateriasListKoAction,
  bateriasListOkAction,
} from './bateriasListAction'
import * as BateriaListAction from './bateriasListAction'

import { getBaterias as getBateriasApi } from '../../api/getBaterias'

export function* getBaterias(
  data: ReturnType<typeof BateriaListAction.bateriasListAction>
) {
  try {
    const bateriasData = yield getBateriasApi(data.payload)
    yield put(bateriasListOkAction(bateriasData))
  } catch (err) {
    yield put(bateriasListKoAction(t('No hay baterias')))
  }
}

function* sagas() {
  return all([yield takeLatest(BateriaListAction.BATERIAS_LIST, getBaterias)])
}

export default sagas
