import {
  IMedida,
  SelectorState,
  Temporada,
  URLOrigen,
} from '../reducers/selectorReducer'
import { Marca } from '../../components/withMarcas'
import * as api from '../../api/selector'
import { NeumaticoMatricula } from '../../types/Neumatico'

export const SET_ORIGEN = 'SELECTOR::SET_ORIGEN'
export const SET_STEP = 'SELECTOR::SET_STEP'
export const SELECT_MEDIDA = 'SELECTOR::SELECT_MEDIDA'
export const SELECT_MEDIDAS_NEUMATICO = 'SELECTOR::SELECT_MEDIDAS_NEUMATICO'
export const LOAD_OPTIONS = 'SELECTOR::LOAD_OPTIONS'
export const SELECT_ESTACION = 'SELECTOR::SELECT_ESTACION'
export const SELECT_RUNFLAT = 'SELECTOR::SELECT_RUNFLAT'
export const SELECT_MARCA = 'SELECTOR::SELECT_MARCA'
export const RESET = 'SELECTOR::RESET'
export const RESET_OK = 'SELECTOR::RESET_OK'
export const LOAD_MARCAS = 'SELECTOR::LOAD_MARCAS'
export const LOAD_MARCAS_OK = 'SELECTOR::LOAD_MARCAS_OK'
export const CHANGE_FILTRO_MARCAS = 'SELECTOR::CHANGE_FILTRO_MARCAS'
export const REPORT_STEP = 'SELECTOR::REPORT_STEP'
export const RESTORE = 'SELECTOR::RESTORE'
export const SET_MARCAS = 'SELECTOR::SET_MARCAS'

interface resetWithValues {
  keepMedidas: IMedida[]
  keepValues: true
}
interface resetWithoutValues {
  keepValues: false
}

export type ResetOptions = (resetWithValues | resetWithoutValues) & {
  origen: URLOrigen
  tipoVehiculo: number | null
  temporada: {
    selectable: boolean
    value: Temporada | null
  }
  marca?: number | null
}

export function setOrigen(origen: string) {
  return {
    type: SET_ORIGEN,
    payload: origen,
  }
}

export function reset(options: ResetOptions) {
  return { type: RESET, payload: options }
}

export function selectMedida(medida: IMedida) {
  return {
    type: SELECT_MEDIDA,
    payload: medida,
  }
}

export function selectFeaturesFromTyre(neumatico: NeumaticoMatricula) {
  return {
    type: SELECT_MEDIDAS_NEUMATICO,
    payload: { neumatico },
  }
}

export function getTireFromLicense(
  matricula: string
): Promise<NeumaticoMatricula> {
  return api.getTireFromLicense(matricula)
}

export function loadOptions(options: Partial<SelectorState>) {
  return {
    type: LOAD_OPTIONS,
    payload: options,
  }
}

export function selectEstacion(estacion: Temporada) {
  return {
    type: SELECT_ESTACION,
    payload: estacion,
  }
}

export function selectRunflat(runflat: boolean) {
  return {
    type: SELECT_RUNFLAT,
    payload: runflat,
  }
}

export function selectMarca(id_marca: number) {
  return {
    type: SELECT_MARCA,
    payload: id_marca,
  }
}

export function changeFiltroMarcas(id_marca: number, selected: boolean) {
  return {
    type: CHANGE_FILTRO_MARCAS,
    payload: {
      id: id_marca,
      selected,
    },
  }
}

export function restore(state: SelectorState) {
  return {
    type: RESTORE,
    payload: state,
  }
}

export function reportStep(step, device) {
  return {
    type: REPORT_STEP,
    payload: {
      step,
      device,
    },
  }
}

export function setMarcas(marcas: Marca[]) {
  return { type: SET_MARCAS, payload: marcas }
}
