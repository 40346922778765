import { all, put, takeLatest } from 'redux-saga/effects'
import { t } from '../../../../common/i18n'
import * as Actions from './revisionMotoMarcasAction'
import {
  revisionMarcas,
  revisionMarcasKo,
  revisionMarcasOk,
} from './revisionMotoMarcasAction'
import {
  getMarcasByCilindrada,
  GetRevisionMarcasResponse,
} from '../../api/getMarcasByCilindrada'

export function* getRevisionMarcas(action: ReturnType<typeof revisionMarcas>) {
  const cilindrada = action.payload.data.cilindrada
  try {
    const res: GetRevisionMarcasResponse = yield getMarcasByCilindrada(
      cilindrada,
      false
    )
    yield put(revisionMarcasOk(res.result))
  } catch (err) {
    yield put(revisionMarcasKo(t('revisionesMoto.errores.get_marcas')))
  }
}
function* sagas() {
  return all([yield takeLatest(Actions.REVISION_MARCAS, getRevisionMarcas)])
}

export default sagas
