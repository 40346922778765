import { IAppState } from '../context'
import { key, RevisionState } from '../reducers/revisionReducer'
import { AceiteSeleccionado } from '../../types/Aceite'
import {
  Revision,
  RevisionComingFrom,
  RevisionType,
  RevisionTypes,
} from '../../types/Revision'

export const selectRevision = (state: IAppState) => state[key]

const getSaeAndNormativa = (aceiteSeleccionado: AceiteSeleccionado) => {
  return `${[aceiteSeleccionado.sae, aceiteSeleccionado.normativa]
    .filter(Boolean)
    .join(' ')}`
}

const getSaeFromListaAceites = (revisionState: RevisionState) => {
  const aceite = revisionState.listaAceites.find(
    (item) => item.id_site_revision_aceite === revisionState.aceiteSeleccionado
  )
  return aceite?.tipo_aceite || ''
}
export const getAceiteSeleccionadoString = (revisionState: RevisionState) => {
  return revisionState.aceiteSeleccionadoInfo
    ? getSaeAndNormativa(revisionState.aceiteSeleccionadoInfo)
    : getSaeFromListaAceites(revisionState)
}

export const getRevision = (
  revisiones: Revision[],
  type: RevisionType
): Revision | null => {
  return revisiones.find((rev) => rev.tipo === type)
}

export const getAllRevisions = (revisionState: RevisionState) =>
  Object.fromEntries(
    RevisionTypes.map((type) => [
      type.toLowerCase(),
      getRevision(revisionState.revisiones, type),
    ])
  ) as {
    [k in Lowercase<RevisionType>]: Revision
  }

export const getVehicleInfo = (state: IAppState) => {
  const revisionState = selectRevision(state)
  return revisionState.vehiculoInfo
}

export const checkComingFromHome = (state: IAppState) => {
  const revisionState = selectRevision(state)
  return revisionState.comingFrom === RevisionComingFrom.HOME
}
