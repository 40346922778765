import {
  IMedida,
  key,
  OrderedMedidas,
  Selectable,
  SelectorMotoState,
} from '../reducers/selectorMotoReducer'
import { IAppState } from '../../../../src/common/context/context'
import { selectMinimalUserInfo } from '../../../../src/common/context/selectors/userSelectors'

export function selectSelectorMoto(state: IAppState): SelectorMotoState {
  return state[key]
}

/**
 * Array de medidas seleccionadas (objectos nombre/valor)
 * @param state Application state
 */
export function selectMedidasMoto(state: IAppState): IMedida[] {
  const selectorState = selectSelectorMoto(state)
  const result = [] as IMedida[]
  const ms = selectorState.pristine
    ? OrderedMedidas.slice(0, 1)
    : OrderedMedidas
  ms.forEach((m) => {
    const state = selectorState[m] as Selectable<any>
    if (!state.loading && state.value !== null) {
      result.push({
        name: m,
        value: state.value,
      } as IMedida)
    }
  })

  return result
}

export function selectTipoVehiculoMoto(state: IAppState) {
  // const selectorState = selectSelectorMoto(state)
  // return selectorState.tipoVehiculo
}

export function selectOrigenMoto(state: IAppState) {
  const selectorState = selectSelectorMoto(state)
  return selectorState.origen
}

export function selectSkipInfoMoto(state: IAppState) {
  const selectorState = selectSelectorMoto(state)
  return {
    // temporada: !selectorState.temporada.selectable,
    marca: selectorState.marca !== null,
    ...selectMinimalUserInfo(state),
  }
}
