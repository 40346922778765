import * as Actions from '../actions/aireAcondicionadoActions'
import { Vehicle } from '../../types/Vehiculo'
import { Device } from '../../types/Device'

export const version = 1
export const key = 'aireAcondicionado'

export const AIRE_ACONDICIONADO_ID = 'CARGAGAS'

export const initialState = {
  loading: false as boolean,
  error: null as string | null,
  vehicle: null as Vehicle | null,
  postCode: null as string | null,
  step: 1 as number,
  device: null as Device | null,
}

export type AireAcondicionadoState = typeof initialState

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.AIRE_ACONDICIONADO_FIND:
      return {
        ...state,
        loading: true,
      }
    case Actions.AIRE_ACONDICIONADO_FIND_OK:
      return {
        ...state,
        loading: false,
      }
    case Actions.AIRE_ACONDICIONADO_FIND_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    case Actions.AIRE_ACONDICIONADO_SET_POSTCODE:
      return {
        ...state,
        postCode: action.payload.postCode,
      }
    case Actions.AIRE_ACONDICIONADO_SET_VEHICLE: {
      const vehicle = action.payload.vehicle
      return {
        ...state,
        vehicle: {
          ...vehicle,
          ano_fabric:
            vehicle.ano_fabric !== 0 ? vehicle.ano_fabric : vehicle.ano_matric,
        },
      }
    }
    case Actions.AIRE_ACONDICIONADO_SET_STEP:
      return {
        ...state,
        step: action.payload.step,
      }
    case Actions.AIRE_ACONDICIONADO_SET_DEVICE:
      return {
        ...state,
        device: action.payload.device,
      }
    case Actions.AIRE_ACONDICIONADO_RESET:
      return {
        ...initialState,
      }
    default:
      return state
  }
}
