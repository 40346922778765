import { RouteComponentProps } from '@reach/router'
import {
  SelectorState,
  URLOrigen,
} from '../../context/reducers/selectorReducer'
import { MinimalUserInfo } from '../../context/selectors/userSelectors'
import route from '../../utils/route'
import dataLayer from '../../helpers/functions/dataLayer'

export enum Step {
  INITIAL,
  SELECT_ANCHO,
  SELECT_SERIE,
  SELECT_LLANTA,
  SELECT_CARGA,
  SELECT_VELOCIDAD,
  SELECT_ESTACION,
  SELECT_RUNFLAT,
  SELECT_MARCAS,
  INSERT_DATOS_LEAD,
  SHOW_RESULTADOS,
  REGISTER,
  SHOW_ALL_RESULTADOS,
}

export interface InSkipInfo extends MinimalUserInfo {
  temporada: boolean
  runflat: boolean
  marca: boolean
  datos: boolean
}

type IDevice = 'mobile' | 'desktop'

export interface InStepDetails {
  step: Step
  routes: {
    mobile?: string
    desktop?: string
  }
  replace: boolean
  skip?: (state: InSkipInfo, device: IDevice) => boolean
  isLoadable: (state: SelectorState, info: InSkipInfo) => boolean
}

export interface SelectorProps {
  origin?: URLOrigen
  skipInfo: InSkipInfo
  selectorState: SelectorState
  dispatch: (action: any) => void
  showMarcas?: boolean
  showTemporada?: boolean
  handleChange: (e: any) => void
  handleChangeRunflat: (e: any) => void
  handleChangeEstacion: (e: any) => void
  isValid: (state: SelectorState) => boolean
}

export const StepDetails = [
  {
    step: Step.INITIAL,
    routes: {
      desktop: route('selector.medidas'),
      mobile: route('selector.ancho'),
    },
    replace: false,
    isLoadable: () => true,
  },
  {
    step: Step.SELECT_ANCHO,
    routes: {
      mobile: route('selector.ancho'),
    },
    replace: false,
    isLoadable: () => true,
  },
  {
    step: Step.SELECT_SERIE,
    routes: {
      mobile: route('selector.serie'),
    },
    replace: false,
    isLoadable: (state: SelectorState) => state.ancho.value !== null,
  },
  {
    step: Step.SELECT_LLANTA,
    routes: {
      mobile: route('selector.llanta'),
    },
    replace: false,
    isLoadable: (state: SelectorState, info: InSkipInfo) =>
      StepDetails[Step.SELECT_LLANTA - 1].isLoadable(state, info) &&
      state.serie.value !== null,
  },
  {
    step: Step.SELECT_CARGA,
    routes: {
      mobile: route('selector.carga'),
    },
    replace: false,
    isLoadable: (state: SelectorState, info: InSkipInfo) =>
      StepDetails[Step.SELECT_CARGA - 1].isLoadable(state, info) &&
      state.llanta.value !== null,
  },
  {
    step: Step.SELECT_VELOCIDAD,
    routes: {
      mobile: route('selector.velocidad'),
    },
    replace: false,
    isLoadable: (state: SelectorState, info: InSkipInfo) =>
      StepDetails[Step.SELECT_VELOCIDAD - 1].isLoadable(state, info) &&
      state.carga.value !== null,
  },
  {
    step: Step.SELECT_ESTACION,
    routes: {
      mobile: route('selector.estacion'),
      desktop: route('selector.preferencias'),
    },
    replace: false,
    isLoadable: (state: SelectorState, info: InSkipInfo) =>
      StepDetails[Step.SELECT_ESTACION - 1].isLoadable(state, info) &&
      state.velocidad.value !== null,
    skip: (skipInfo: InSkipInfo, device: IDevice) =>
      device === 'mobile' && skipInfo.temporada,
  },
  {
    step: Step.SELECT_RUNFLAT,
    routes: {
      mobile: route('selector.runflat'),
    },
    replace: false,
    isLoadable: (state: SelectorState, info: InSkipInfo) =>
      StepDetails[Step.SELECT_RUNFLAT - 1].isLoadable(state, info) &&
      state.temporada !== null,
    skip: (skipInfo: InSkipInfo) => {
      if (skipInfo.runflat) {
        dataLayer.push({
          event: 'buscar_neumaticos_runflat',
          action: 'general',
          label: 'mobile',
          runflat: false,
        })
        return true
      }
      return false
    },
  },
  {
    step: Step.SELECT_MARCAS,
    routes: {
      mobile: route('selector.marcas'),
    },
    replace: false,
    isLoadable: (state: SelectorState, info: InSkipInfo) =>
      StepDetails[Step.SELECT_MARCAS - 1].isLoadable(state, info),
    skip: (skipInfo: InSkipInfo, device: IDevice) =>
      device === 'mobile' && skipInfo.marca,
  },
  {
    step: Step.INSERT_DATOS_LEAD,
    routes: {
      mobile: route('selector.datos'),
      desktop: route('selector.datos'),
    },
    skip: (info: InSkipInfo) => {
      return info.isLead || info.isLoggedIn || !info.datos
    },
    replace: true,
    isLoadable: (state: SelectorState, info: InSkipInfo) => {
      return StepDetails[Step.INSERT_DATOS_LEAD - 1].isLoadable(state, info)
    },
  },
  {
    step: Step.SHOW_RESULTADOS,
    routes: {
      mobile: route('selector.resultados'),
      desktop: route('selector.resultados'),
    },
    replace: false,
    isLoadable: (state: SelectorState, info: InSkipInfo) =>
      StepDetails[Step.SHOW_RESULTADOS - 1].isLoadable(state, info) &&
      StepDetails[Step.INSERT_DATOS_LEAD].skip(info, 'desktop'),
  },
  {
    step: Step.REGISTER,
    routes: {
      mobile: route('selector.resultados.registro'),
      desktop: route('selector.resultados.registro'),
    },
    skip: (info: InSkipInfo) =>
      process.env.GATSBY_LEAD_CAN_ACCESS_ALL_RESULTS || info.isLoggedIn,
    replace: true,
    isLoadable: (state: SelectorState, info: InSkipInfo) =>
      StepDetails[Step.SHOW_RESULTADOS].isLoadable(state, info),
  },
  {
    step: Step.SHOW_ALL_RESULTADOS,
    routes: {
      mobile: route('selector.resultados.todos'),
      desktop: route('selector.resultados.todos'),
    },
    replace: false,
    isLoadable: (state: SelectorState, info) =>
      StepDetails[Step.SHOW_RESULTADOS].isLoadable(state, info) &&
      StepDetails[Step.REGISTER].skip(info, 'desktop'),
  },
] as InStepDetails[]

export function stepFromRoute(path: string) {
  for (let i = StepDetails.length - 1; i >= 0; i--) {
    const s = StepDetails[i]
    if (
      (s.routes.desktop && s.routes.desktop === path) ||
      (s.routes.mobile && s.routes.mobile === path)
    ) {
      return s
    }
  }

  return null
}

export function findPrevious(
  current: Step,
  device: 'desktop' | 'mobile',
  skipInfo: InSkipInfo
) {
  for (let i = current - 1; i > 0; i--) {
    const details = StepDetails[i]
    if (
      details.routes[device] &&
      (!details.skip || !details.skip(skipInfo, device))
    ) {
      return details
    }
  }
  // TODO: Go to initial?
  // return {
  //   step: Step.INITIAL,
  //   routes: {
  //     mobile: selectOrigen(state),
  //     desktop: selectOrigen(state),
  //   },
  // }
  return StepDetails[Step.INITIAL]
}

export function findNext(
  current: Step,
  device: 'desktop' | 'mobile',
  skipInfo: InSkipInfo
) {
  for (let i = current + 1; i < StepDetails.length; i++) {
    const details = StepDetails[i]
    if (
      details.routes[device] &&
      (!details.skip || !details.skip(skipInfo, device))
    ) {
      return details
    }
  }
  return StepDetails[Step.INITIAL]
}

export interface FunnelScreenProps extends RouteComponentProps {
  nextStep: () => void
  previousStep: (useBrowserHistory?: boolean) => void
  dispatch: (action: any) => void
  selectorState: SelectorState
  totalSteps?: number
}

export default Step
