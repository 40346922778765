import { t } from '../../i18n'
import { UserState } from '../reducers/userReducer'

export const CAMARA_RESET = 'SEARCH::CAMARA_RESET'
export const SEARCH_CAMARA = 'SEARCH::SEARCH_CAMARA_MOTO'
export const SEARCH_CAMARA_OK = 'SEARCH::SEARCH_CAMARA_MOTO_OK'
export const SEARCH_CAMARA_FAILED = 'SEARCH::SEARCH_CAMARA_MOTO_FAILED'
export const DEFAULT_PAGE_SIZE = 10

type OrderKey = '' | 'marca' | 'precio' | 'orden'
type DirectionKey = 'ASC' | 'DESC'
export interface OrderEntry {
  label: string
  value: string
  field: OrderKey
  direction?: DirectionKey
}
export const ORDER_OPTIONS = [
  {
    label: t('resultados_neumaticos.recomendado'),
    value: 'orden.asc',
    field: 'orden',
    direction: 'ASC',
  },
  {
    label: `${t('resultados_neumaticos.marcas')} A->Z`,
    value: 'marca.asc',
    field: 'marca',
    direction: 'ASC',
  },
  {
    label: `${t('resultados_neumaticos.marcas')} Z->A`,
    value: 'marca.desc',
    field: 'marca',
    direction: 'DESC',
  },
  {
    label: t('resultados_neumaticos.mas_caros'),
    value: 'precio.desc',
    field: 'precio',
    direction: 'DESC',
  },
  {
    label: t('resultados_neumaticos.mas_baratos'),
    value: 'precio.asc',
    field: 'precio',
    direction: 'ASC',
  },
] as OrderEntry[]

export interface SearchParametersCamaraMoto {
  filters: {
    medidas?: {
      ancho?: string
      alto?: string
      llanta?: string
    }
    id_site_marca_neumatico?: string[]
  }
  order: OrderKey
  direction?: DirectionKey
  pagination: {
    pageSize: number
    page: number
  }
  userInfo: UserState
  web: 'rodi' | 'rodimotor' | 'elpaso'
}

const defaultSearchMotoOptions = {
  order: '',
  direction: 'ASC',
  pagination: {
    pageSize: DEFAULT_PAGE_SIZE,
    page: 0,
  },
} as Partial<SearchParametersCamaraMoto>

export function searchCamaraMoto(
  options: Partial<SearchParametersCamaraMoto> = defaultSearchMotoOptions
) {
  return {
    type: SEARCH_CAMARA,
    payload: {
      ...defaultSearchMotoOptions,
      ...options,
    },
  }
}

export function searchCamaraMotoReset() {
  return {
    type: CAMARA_RESET,
    payload: {},
  }
}
