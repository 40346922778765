import { AvailableScooterBrand } from '../context/reducers/citaPreviaReducer'
import { apiCall } from './api'

const baseUrl = `${process.env.GATSBY_API_URL}`

export const getAvailableScooterBrands = (): Promise<
  AvailableScooterBrand[]
> => {
  return apiCall(`${baseUrl}/marcas-patinete`)
}
