import { addLocaleData, IntlProvider, MessageValue } from 'react-intl'
import esData from 'react-intl/locale-data/es'
import caData from 'react-intl/locale-data/ca'
import ptData from 'react-intl/locale-data/pt'
import esMessageDefinitions from '../../specific/i18n/es.json'
import caMessageDefinitions from '../../specific/i18n/ca.json'
import ptMessageDefinitions from '../../specific/i18n/pt.json'
import flatten from 'flat'

const locale = (process.env.GATSBY_LOCALE || 'es') as 'ca' | 'es' | 'pt'
addLocaleData([...caData, ...esData, ...ptData])
const esMessages = flatten(esMessageDefinitions) as { [key: string]: string }
const caMessages = flatten(caMessageDefinitions) as { [key: string]: string }
const ptMessages = flatten(ptMessageDefinitions) as { [key: string]: string }
let messages
switch (locale) {
  case 'ca':
    messages = caMessages
    break
  case 'pt':
    messages = ptMessages
    break
  case 'es':
  default:
    messages = esMessages
    break
}
const { intl } = new IntlProvider({ locale, messages }, {}).getChildContext()
const { intl: esIntl } = new IntlProvider(
  { locale: 'es', messages: esMessages },
  {}
).getChildContext()

// para forzar a que el formato de número siempre salga en modo español
intl.formatNumber = function (number, options) {
  return esIntl.formatNumber(number, options)
}

/**
 * Fetches a translation to the current locale
 * @param id translation key
 * @param args mappings for any translation placeholders
 */
const t = (id: string, args?: { [key: string]: MessageValue }) =>
  intl.formatMessage({ id }, args)

/**
 * Returns the input text with "_{locale}" appended to it.
 * @param text
 */
const textWithLocale = (text: string) => `${text}_${locale}`

const catalanUrl = process.env.GATSBY_WEB_URL_ca
const espUrl = process.env.GATSBY_WEB_URL_es
const alternateUrl = locale === 'ca' ? espUrl : catalanUrl
const currentUrl = locale === 'ca' ? catalanUrl : espUrl

export {
  locale,
  esMessages,
  caMessages,
  ptMessages,
  messages,
  intl,
  esIntl,
  t,
  textWithLocale,
  alternateUrl,
  currentUrl,
}
