import { SearchParametersMoto } from '../context/actions/searchMotoActions'
import { CategoriaNeumatico } from '../types/Neumatico'
import { apiCall } from './api'
import { Promocion as PromocionCesta } from '../context/reducers/cestaReducer'
import { OptionMedida } from './selectorMoto'

const baseUrl = `${process.env.GATSBY_API_URL}`

export interface Promocion {
  texto_promociones: string
  color_background: string
  color_texto: string
  visible_cesta: boolean
}

export interface NeumaticoMotoResult {
  modelo: string
  sku: string
  precio: number
  id: string
  tipo_iva: number
  descuento: number
  promo: string
  descripcion_es: string
  descripcion_ca: string
  descripcion_pt: string
  id_site_marca_neumatico: number
  marca: string
  logo: string
  categoria: CategoriaNeumatico | 'Premium' | 'Quality' | 'Budget'
  ancho: string
  alto: string
  radial?: string
  llanta: string
  serie: string
  normativa?: string
  flanco?: string
  marcaje?: string
  compuesto?: string
  terreno?: string
  homologacion?: string
  aplicacion?: string
  tipo: string
  ecotasa: string
  id_marca: number
  id_modelo: number
  urlImagen: string
  precioSinPromo: number
  precioConEcotasa: number
  promocion?: Promocion
  promocionCesta: PromocionCesta
}
export interface SearchMotoResults {
  neumaticos: NeumaticoMotoResult[]
  pages: number
  total: number
  tipos: OptionMedida[]
  token: string | null
}

export function searchNeumaticosMoto(
  query: SearchParametersMoto
): Promise<SearchMotoResults> {
  return apiCall(`${baseUrl}/neumaticos-moto/selector`, {
    method: 'POST',
    body: JSON.stringify(query),
  })
}
