/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from 'react'
import { StoreProvider, store } from './src/common/context/context'
import { onPreRoute, onRoute } from './src/common/context/actions/routeActions'
import Size from './src/common/utils/media-queries-setup'
import { locale } from './src/common/i18n'

import './src/common/styles/minireset.scss'
import './src/common/styles/global.scss'
import { originalRoute } from './src/common/utils/route'
import dataLayer from './src/common/helpers/functions/dataLayer'

export const wrapRootElement = ({ element }) => (
  <StoreProvider>
    <Size.Provider>{element}</Size.Provider>
  </StoreProvider>
)

export const onPreRouteUpdate = (() => {
  const _location = null
  const _prevLocation = null
  return ({ location, prevLocation }) => {
    const newLocation = prevLocation === null ? location : prevLocation
    const newPrevLocation = prevLocation !== null ? location : prevLocation
    if (newLocation !== _location || newPrevLocation !== _prevLocation) {
      store.dispatch(onPreRoute(newPrevLocation, newLocation))
    }
  }
})()

const findEsLocation = path => {
  return locale === 'es'
    ? Promise.resolve(path)
    : window.___loader
        .loadPage(path)
        .then(({ json, page }) => {
          if (page.matchPath) {
            return originalRoute(path)
          }
          if (
            json &&
            typeof json.data === 'object' &&
            typeof json.data.seoData === 'object'
          ) {
            return json.data.seoData.url_es
          }

          return 'unknown'
        })
        .catch(e => e.message)
}

export const onRouteUpdate = (() => {
  let _location = null
  let _prevLocation = null

  return ({ location, prevLocation }) => {
    if (location !== _location || prevLocation !== _prevLocation) {
      _location = location
      _prevLocation = prevLocation
      store.dispatch(onRoute(prevLocation, location))
    }

    // Notify Google Tag Manager of the page change
    if (dataLayer.enabled()) {
      setTimeout(() => {
        findEsLocation(location.pathname).then(esLocation => {
          const data = {
            event: 'page_view',
            route: esLocation,
            title: document.title,
          }
          dataLayer.push(data)
        })
      }, 100)
    }
  }
})()
