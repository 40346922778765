import route from '../../utils/route'

export enum CitaPreviaEnum {
  SELECCION_TALLER,
  SELECCION_SERVICIO,
  SELECCION_HORARIO,
  DATOS_USUARIO,
  CONFIRMACION,
  MODIFICAR,
}

export enum AreaPrivadaCitaPreviaEnum {
  OPTIONAL_COMMENT,
  SELECCION_TALLER,
  SELECCION_HORARIO,
  CONFIRMACION,
}

export enum PatineteCitaPreviaEnum {
  MARCA,
  SELECCION_SERVICIO,
  SELECCION_TALLER,
  SELECCION_HORARIO,
  DATOS_USUARIO,
  CONFIRMACION,
}

export interface InStepDetails {
  step: CitaPreviaEnum | AreaPrivadaCitaPreviaEnum | PatineteCitaPreviaEnum
  name: string
  route: string
}

export const citaPreviaSteps: InStepDetails[] = [
  {
    step: CitaPreviaEnum.SELECCION_TALLER,
    name: 'steps_cita_previa.talleres',
    route: route('cita-previa.seleccion'),
  },
  {
    step: CitaPreviaEnum.SELECCION_SERVICIO,
    name: 'steps_cita_previa.servicios',
    route: route('cita-previa.servicios'),
  },
  {
    step: CitaPreviaEnum.SELECCION_HORARIO,
    name: 'steps_cita_previa.fecha_y_hora',
    route: route('cita-previa.fecha_hora'),
  },
  {
    step: CitaPreviaEnum.DATOS_USUARIO,
    name: 'steps_cita_previa.datos',
    route: route('cita-previa.tus_datos'),
  },
  {
    step: CitaPreviaEnum.CONFIRMACION,
    name: 'steps_cita_previa.confirmacion',
    route: route('cita-previa.confirmacion'),
  },
]

// This is only used for the upper right flux display in area privada case:
export const areaPrivadaCitaSteps = [
  {
    step: AreaPrivadaCitaPreviaEnum.OPTIONAL_COMMENT,
    name: 'steps_cita_previa.comentario',
    route: route('cita-previa.comentario'),
  },
  {
    step: AreaPrivadaCitaPreviaEnum.SELECCION_TALLER,
    name: 'steps_cita_previa.talleres',
    route: route('cita-previa.seleccion'),
  },
  {
    step: AreaPrivadaCitaPreviaEnum.SELECCION_HORARIO,
    name: 'steps_cita_previa.fecha_y_hora',
    route: route('cita-previa.fecha_hora'),
  },
  {
    step: AreaPrivadaCitaPreviaEnum.CONFIRMACION,
    name: 'steps_cita_previa.confirmacion',
    route: route('cita-previa.confirmacion'),
  },
]

// This is only used for the upper right flux display in patinete case:
export const patineteCitaSteps: InStepDetails[] = [
  {
    step: PatineteCitaPreviaEnum.MARCA,
    name: 'steps_cita_previa.marca_patinete',
    route: route('cita-previa.marca_patinete'),
  },
  {
    step: PatineteCitaPreviaEnum.SELECCION_SERVICIO,
    name: 'steps_cita_previa.servicios_patinete',
    route: route('cita-previa.servicios_patinete'),
  },
  {
    step: PatineteCitaPreviaEnum.SELECCION_TALLER,
    name: 'steps_cita_previa.talleres_patinete',
    route: route('cita-previa.seleccion_taller_patinete'),
  },
  {
    step: PatineteCitaPreviaEnum.SELECCION_HORARIO,
    name: 'steps_cita_previa.fecha_y_hora',
    route: route('cita-previa.fecha_hora_patinete'),
  },
  {
    step: PatineteCitaPreviaEnum.DATOS_USUARIO,
    name: 'steps_cita_previa.datos',
    route: route('cita-previa.tus_datos_patinete'),
  },
  {
    step: PatineteCitaPreviaEnum.CONFIRMACION,
    name: 'steps_cita_previa.confirmacion',
    route: route('cita-previa.confirmacion'),
  },
]

export function stepFromRoute(path: string) {
  for (let i = citaPreviaSteps.length - 1; i >= 0; i--) {
    const s = citaPreviaSteps[i]
    if (s.route === path) {
      return s
    }
  }

  return null
}
