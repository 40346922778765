import { MessageValue } from 'react-intl'

export enum MenuPrincipal {
  HOME,
  NEUMATICOS,
  REVISIONES,
  COCHE,
  MOTO,
  CAMION,
  OFERTAS,
  TALLERES,
  TELLAMAMOS,
  CITAPREVIA,
}

export type Route = string | ({ path: string } & { [k: string]: MessageValue })

interface BaseEntry {
  id: number
  name: string
}
type SubSubmenuEntry = BaseEntry & SubmenuEntryRoute
interface SubmenuEntrySubmenu {
  submenu: SubSubmenuEntry[]
}
interface SubmenuEntryRoute {
  route: Route
}
type SubmenuDetails = SubmenuEntrySubmenu | SubmenuEntryRoute
type SubmenuEntry = BaseEntry & SubmenuDetails

type RootEntry = {
  id: MenuPrincipal
  name: string
  icon: string
  route: string
} & {
  submenu: SubmenuEntry[]
}
interface Menu {
  menu: RootEntry[]
}

const menu = {
  menu: [
    {
      id: MenuPrincipal.NEUMATICOS,
      name: 'menuHeader.neumaticos',
      icon: 'neumaticos',
      route: 'neumaticos.index',
      submenu: [
        {
          id: 1,
          name: 'menuHeader.tipo',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.economy',
              route: 'landing-economy',
            },
            {
              id: 2,
              name: 'menuHeader.quality',
              route: 'landing-quality',
            },
            {
              id: 3,
              name: 'menuHeader.premium',
              route: 'landing-premium',
            },
            {
              id: 4,
              name: 'menuHeader.4x4',
              route: 'landing-4x4',
            },
            {
              id: 5,
              name: 'menuHeader.furgoneta',
              route: 'landing-furgoneta',
            },
            {
              id: 6,
              name: 'menuHeader.neumaticos_moto',
              route: 'landing-moto',
            },
            {
              id: 7,
              name: 'menuHeader.quad',
              route: 'landing-quad',
            },
          ],
        },
        {
          id: 2,
          name: 'menuHeader.marca',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.michelin',
              route: {
                path: 'neumaticos.marca',
                marca: 'michelin',
              },
            },
            {
              id: 2,
              name: 'menuHeader.continental',
              route: {
                path: 'neumaticos.marca',
                marca: 'continental',
              },
            },
            {
              id: 3,
              name: 'menuHeader.pirelli',
              route: {
                path: 'neumaticos.marca',
                marca: 'pirelli',
              },
            },
            {
              id: 4,
              name: 'menuHeader.kleber',
              route: {
                path: 'neumaticos.marca',
                marca: 'kleber',
              },
            },
            {
              id: 5,
              name: 'menuHeader.falken',
              route: {
                path: 'neumaticos.marca',
                marca: 'falken',
              },
            },
            {
              id: 6,
              name: 'menuHeader.mabor',
              route: {
                path: 'neumaticos.marca',
                marca: 'mabor',
              },
            },
            {
              id: 7,
              name: 'menuHeader.todas',
              route: 'landing-allbrands',
            },
          ],
        },
        {
          id: 3,
          name: 'menuHeader.servicio_neumatico',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.alineado_neumaticos',
              route: 'neumaticos.alineado',
            },
            {
              id: 2,
              name: 'menuHeader.reparacion_pinchazos',
              route: 'neumaticos.pinchazos',
            },
            {
              id: 3,
              name: 'menuHeader.seguro_neumatico',
              route: 'neumaticos.seguro',
            },
          ],
        },
      ],
    },
    {
      id: MenuPrincipal.REVISIONES,
      name: 'menuHeader.cambio_aceite',
      icon: 'revision',
      route: 'revisiones.index',
      submenu: [
        {
          id: 1,
          name: 'menuHeader.cambio_aceite_filtros',
          route: 'revisiones.index',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.pack_basic',
              route: 'revisiones.revision-basic',
            },
            {
              id: 2,
              name: 'menuHeader.pack_plus',
              route: 'revisiones.revision-plus',
            },
            {
              id: 3,
              name: 'menuHeader.pack_premium',
              route: 'revisiones.revision-premium',
            },
            {
              id: 4,
              name: 'menuHeader.pack_confort',
              route: 'revisiones.revision-confort',
            },
            {
              id: 5,
              name: 'menuHeader.todos_packs',
              route: 'revisiones.index',
            },
          ],
        },
        {
          id: 2,
          name: 'menuHeader.seguridad',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.test_seguridad',
              route: 'revisiones.test-seguridad',
            },
          ],
        },
      ],
    },
    {
      id: MenuPrincipal.COCHE,
      name: 'menuHeader.coche',
      icon: 'coches',
      route: 'coche.index',
      submenu: [
        {
          id: 1,
          name: 'menuHeader.mecanica',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.frenos',
              route: 'coche.frenos',
            },
            {
              id: 2,
              name: 'menuHeader.amortiguadores',
              route: 'coche.amortiguadores',
            },
            {
              id: 3,
              name: 'menuHeader.correa_distribución',
              route: 'coche.correa',
            },
          ],
        },
        {
          id: 2,
          name: 'menuHeader.mantenimiento',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.filtros',
              route: 'coche.filtros',
            },
            {
              id: 2,
              name: 'menuHeader.baterías',
              route: 'coche.baterias',
            },
            {
              id: 3,
              name: 'menuHeader.escobillas',
              route: 'coche.escobillas',
            },
          ],
        },
        {
          id: 3,
          name: 'menuHeader.puesta_punto',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.revision_ITV',
              route: 'coche.revision_ITV',
            },
            {
              id: 2,
              name: 'menuHeader.desinfeccion_ozono',
              route: 'coche.desinfeccion_ozono',
            },
            {
              id: 3,
              name: 'menuHeader.aire_acondicionado',
              route: 'coche.aire_acondicionado',
            },
          ],
        },
      ],
    },
    {
      id: MenuPrincipal.MOTO,
      name: 'menuHeader.moto',
      icon: 'moto',
      route: 'moto.index',
      submenu: [
        {
          id: 1,
          name: 'menuHeader.mecanica',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.frenos',
              route: 'moto.frenos',
            },
            {
              id: 2,
              name: 'menuHeader.kit_transmisión',
              route: 'moto.kit',
            },
            {
              id: 3,
              name: 'menuHeader.embrague',
              route: 'moto.embrague',
            },
          ],
        },
        {
          id: 2,
          name: 'menuHeader.filtros',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.filtro_aceite',
              route: 'moto.filtros_aceite',
            },
            {
              id: 2,
              name: 'menuHeader.filtro_aire',
              route: 'moto.filtros_aire',
            },
          ],
        },
        {
          id: 3,
          name: 'menuHeader.puesta_punto',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.cambio_aceite',
              route: 'moto.cambio_aceite',
            },
            {
              id: 2,
              name: 'menuHeader.baterías',
              route: 'moto.baterias',
            },
          ],
        },
      ],
    },
    {
      id: MenuPrincipal.CAMION,
      name: 'menuHeader.camion',
      icon: 'camion',
      route: 'camion.index',
      submenu: [
        {
          id: 1,
          name: 'menuHeader.neumaticos',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.c-industria',
              route: 'camion.neumaticos',
            },
          ],
        },
        {
          id: 2,
          name: 'menuHeader.servicios',
          submenu: [
            {
              id: 1,
              name: 'menuHeader.c-asistencia',
              route: 'camion.asistencia',
            },
            {
              id: 2,
              name: 'menuHeader.c-gestion',
              route: 'camion.gestion',
            },
          ],
        },
      ],
    },
    {
      id: MenuPrincipal.OFERTAS,
      name: 'menuHeader.ofertas',
      icon: 'percent',
      route: 'promociones.defecto',
    },
    {
      id: MenuPrincipal.TALLERES,
      name: 'menuHeader.talleres',
      icon: 'wrench',
      route: 'talleres',
    },
    {
      id: MenuPrincipal.TELLAMAMOS,
      name: 'home.call',
      route: 'te-llamamos',
      icon: 'phoneIcon',
    },
    {
      id: MenuPrincipal.CITAPREVIA,
      name: 'taller-card.appointment',
      route: 'cita-previa.seleccion',
      icon: 'calendar',
    },
  ],
} as Menu

export const findMenuEntry = (id: MenuPrincipal) =>
  menu.menu.find(e => e.id === id)

export default menu
