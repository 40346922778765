import { apiCall } from '../../../common/api/api'
import { RevisionMotoGroupedModelo } from '../components/datos/moto/modelo/modelo'

export interface PostRevisionLead {
  idRegistroLeadRevisionMoto: number
  idSiteMarcaMoto: number
  idSiteModeloMoto: RevisionMotoGroupedModelo
  idMoto: string
  fabricacion: number | null
}
export interface RevisionLead {}

export function postRevisionLead(
  id_registro_lead_revision_moto: number,
  id_site_marca_moto: number,
  id_moto: string
): Promise<{ RevisionLead }> {
  return apiCall(`${process.env.GATSBY_API_URL}/moto/revision/lead`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify({
      id_registro_lead_revision_moto,
      id_site_marca_moto,
      id_moto,
    }),
  })
}
