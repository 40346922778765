import { intl, esIntl, esMessages, messages } from '../../common/i18n'
import { MessageValue } from 'react-intl'

const routeCache = {} as { [url: string]: string }

function _resolve(
  intl: ReactIntl.InjectedIntl,
  messages: { [key: string]: string },
  id: string,
  values?: { [key: string]: MessageValue }
): string {
  const base = `route.${id}`

  if (messages[base]) {
    return intl.formatMessage({ id: base }, values)
  }

  const index = `${base}.index`
  if (messages[index]) {
    return intl.formatMessage({ id: index }, values)
  }

  return intl.formatMessage({ id: base }, values)
}

function maybeCache(
  url: string,
  id: string,
  values?: { [key: string]: MessageValue }
) {
  if (values && Object.keys(values).length > 0) {
    return
  }

  routeCache[url] = _route(esIntl, esMessages, id, values)
}

function _route(
  intl: ReactIntl.InjectedIntl,
  messages: { [key: string]: string },
  id: string,
  values?: { [key: string]: MessageValue }
) {
  const path = _resolve(intl, messages, id, values)
  return path.endsWith('/') || path.endsWith('.html') ? path : `${path}/`
}

function route(id: string, values?: { [key: string]: MessageValue }) {
  const url = _route(intl, messages, id, values)
  maybeCache(url, id, values)
  return url
}

export function originalRoute(url: string): string {
  return url in routeCache ? routeCache[url] : url
}

export default route
