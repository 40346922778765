import * as Actions from '../actions/altaVehiculoActions'
import { Vehicle } from '../../types/Vehiculo'

export const version = 1
export const key = 'altaVehiculo'

export interface IVehicleImages {
  [name: string]: { id_vehiculo: number; imagen: string }[]
}
interface AltaVehiculo {
  _version: number
  modelo: string | null
  marca: string | null
  matricula: string | null
  motor: string | null
  vehicleInformation: Array<Vehicle> | null
  selectedVehicle: Vehicle
  vehicleImages: IVehicleImages | null
  informationFrom: string | null
  step: number
  loading: boolean
  error: boolean
}

export const initialState: AltaVehiculo = {
  _version: version,
  modelo: null,
  marca: null,
  matricula: null,
  motor: null,
  vehicleInformation: null,
  selectedVehicle: null,
  vehicleImages: null,
  informationFrom: null,
  step: 0,
  loading: false,
  error: false,
}

export type AltaVehiculoState = typeof initialState

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.ADD_ALTA_VEHICULO_OBJECT:
      return {
        ...action.payload.altaVehiculo,
      }
    case Actions.ADD_MODELO_VEHICULO:
      return {
        ...state,
        modelo: action.payload.modelo,
      }
    case Actions.ADD_MARCA_VEHICULO:
      return {
        ...state,
        marca: action.payload.marca,
      }
    case Actions.ADD_MOTOR_VEHICULO:
      return {
        ...state,
        motor: action.payload.motor,
      }
    case Actions.ADD_MATRICULA:
      return {
        ...state,
        matricula: action.payload.matricula,
      }
    case Actions.ADD_VEHICLE_INFORMATION:
      return {
        ...state,
        loading: true,
        error: false,
      }
    case Actions.ADD_VEHICLE_INFORMATION_OK:
      return {
        ...state,
        vehicleInformation: action.payload.vehicleInformation,
        loading: false,
        error: false,
      }
    case Actions.ADD_VEHICLE_INFORMATION_FAILED:
      return {
        ...state,
        loading: false,
        error: true,
        step: 5,
      }
    case Actions.ADD_INFORMATION_FROM:
      return {
        ...state,
        informationFrom: action.payload.informationFrom,
      }
    case Actions.ADD_VEHICLE_IMAGES:
      return {
        ...state,
        vehicleImages: action.payload.vehicleImages,
      }
    case Actions.ADD_ALTA_VEHICULO_STEP:
      return {
        ...state,
        step: action.payload.step,
      }
    case Actions.RESET_ALTA_VEHICULO:
      return {
        ...initialState,
      }
    case Actions.RESET_ALTA_VEHICULO_ERROR:
      return {
        ...state,
        error: false,
      }
    case Actions.SET_ALTA_VEHICULO_LOADING:
      return {
        ...state,
        loading: true,
      }
    case Actions.RESET_ALTA_VEHICULO_LOADING:
      return {
        ...state,
        loading: false,
      }
    case Actions.SET_SELECTED_VEHICLE:
      return {
        ...state,
        selectedVehicle: action.payload.selectedVehicle,
      }
    default:
      return state
  }
}
