import { PostRevisionDatos, RevisionDatos } from '../../api/postRevisionDatos'

export const REVISION_DATOS = 'REVISION_MOTO::POST_DATOS'
export const REVISION_DATOS_OK = 'REVISION_MOTO::POST_DATOS_OK'
export const REVISION_DATOS_FAILED = 'REVISION_MOTO::POST_DATOS_FAILED'
export const REVISION_DATOS_RESET = 'REVISION_MOTO::POST_DATOS_RESET'

export function revisionDatos(data: PostRevisionDatos) {
  return { type: REVISION_DATOS, payload: { data } }
}

export function revisionDatosOk(datos: RevisionDatos) {
  return { type: REVISION_DATOS_OK, payload: datos }
}

export function revisionDatosKo(message) {
  return { type: REVISION_DATOS_FAILED, payload: { error: message } }
}
export function revisionDatosReset() {
  return { type: REVISION_DATOS_RESET }
}
