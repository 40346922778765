import { apiCall } from './api'
import { SearchParametersCamaraMoto } from '../context/actions/searchCamaraMotoActions'
import { CategoriaCesta } from '../types/Cesta'

const baseUrl = `${process.env.GATSBY_API_URL}`

export interface CamaraMotoResult {
  sku: string
  precio: number
  id: string
  tipo_iva: number
  descuento: number
  promo: string
  descripcion_es: string
  descripcion_ca: string
  descripcion_pt: string
  nombre_producto_es: string
  nombre_producto_ca: string
  nombre_producto_pt: string
  id_site_marca_neumatico: number
  marca: string
  logo: string
  urlImagen: string
  categoria: CategoriaCesta
  tipo_producto: 'camara' | 'mousse'
}
export interface SearchCamaraMotoResults {
  camaras: CamaraMotoResult[]
  pages: number
  total: number
}

export function searchCamarasMoto(
  query: SearchParametersCamaraMoto
): Promise<SearchCamaraMotoResults> {
  return apiCall(`${baseUrl}/camaras-moto/selector`, {
    method: 'POST',
    body: JSON.stringify(query),
  })
}
