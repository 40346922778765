import { PRE_ROUTE, ROUTE } from '../actions/routeActions'

export const key = 'location'

export const initialState = {
  previous: null as null | Location,
  current: null as null | Location,
  next: null as null | Location,
}

export type RouteState = typeof initialState

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case PRE_ROUTE:
      return { ...state, next: action.payload.to }

    case ROUTE:
      return {
        ...state,
        previous: action.payload.from,
        current: action.payload.to,
        next: null,
      }

    default:
      return state
  }
}
