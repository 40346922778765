import { Vehicle } from '../../types/Vehiculo'

export const CLIENT_VEHICLES_FIND = 'CLIENT_VEHICLES::FIND'
export const CLIENT_VEHICLES_FIND_OK = 'CLIENT_VEHICLES::FIND_OK'
export const CLIENT_VEHICLES_FIND_FAILED = 'CLIENT_VEHICLES::FIND_FAILED'

export function findClientVehicles() {
  return {
    type: CLIENT_VEHICLES_FIND,
  }
}
export function findClientVehiclesOk(vehicles: Vehicle[]) {
  return {
    type: CLIENT_VEHICLES_FIND_OK,
    payload: {
      vehicles,
    },
  }
}
export function findClientVehiclesFailed(message) {
  return {
    type: CLIENT_VEHICLES_FIND_FAILED,
    payload: { error: message },
  }
}
