import {
  IMedida,
  OrderedMedidas,
  SelectorState,
} from '../context/reducers/selectorReducer'
import { apiCall } from './api'

const baseUrl = `${process.env.GATSBY_API_URL}`
const nameMap = {
  ancho: 'ancho',
  serie: 'serie',
  llanta: 'llanta',
  carga: 'ic',
  velocidad: 'cv',
}

export const loadOpciones = (
  medidas: IMedida[],
  runflat: null | boolean,
  tipoVehiculo: null | number
): Promise<Partial<SelectorState>> => {
  const query = medidas.map((medida) => {
    return `${encodeURIComponent(nameMap[medida.name])}=${encodeURIComponent(
      medida.value
    )}`
  })
  if (tipoVehiculo !== null) {
    query.push(`tipo-vehiculo=${encodeURIComponent(tipoVehiculo)}`)
  }

  return apiCall(`${baseUrl}/tyres-with-values?${query.join('&')}`).then(
    (items) => {
      const output = {} as { [k: string]: any }

      let medidaToLoad = null
      for (let i = 0; i < OrderedMedidas.length; i++) {
        const name = OrderedMedidas[i]
        const current = medidas.find((m) => m.name === name)
        if (!current) {
          medidaToLoad = name
          break
        }
        output[name] = {
          loading: false,
          value: current.value,
          options: items[nameMap[name]].output,
        }
      }

      let runflatValue = runflat
      if (items.filterables) {
        output.filterables = items.filterables
        if (
          items.filterables.runflat &&
          items.filterables.runflat.length === 1
        ) {
          runflatValue = items.filterables.runflat[0]
        }
      }
      output.runflat = {
        selectable: items.rf,
        value: runflatValue,
      }

      if (!medidaToLoad) {
        return output
      }
      output[medidaToLoad] = {
        loading: false,
        value: null,
        options: items[nameMap[medidaToLoad]].output,
      }
      return output
    }
  )
}

export function loadDefaultOptions(tipo_vehiculo: number | null) {
  const query =
    typeof tipo_vehiculo === 'number' ? `?tipo-vehiculo=${tipo_vehiculo}` : ''
  return apiCall(
    `${process.env.GATSBY_API_URL}/tyres-default-values${query}`
  ).then((res) => {
    const data = {
      pristine: true,
      ancho: {
        loading: false,
        value: parseInt(res.initialValues.ancho),
        options: res.ancho.output,
      },
      serie: {
        loading: false,
        value: parseInt(res.initialValues.serie),
        options: res.serie.output,
      },
      llanta: {
        loading: false,
        value: parseInt(res.initialValues.llanta),
        options: res.llanta.output,
      },
      carga: {
        loading: false,
        value: parseInt(res.initialValues.ic),
        options: res.ic.output,
      },
      velocidad: {
        loading: false,
        value: res.initialValues.cv,
        options: res.cv.output,
      },
      filterables: res.filterables,
    }

    return data as Partial<SelectorState>
  })
}

export function loadMarcas() {
  return apiCall(`${process.env.GATSBY_API_URL}/marcas/lista`)
}

export function getTireFromLicense(matricula: string) {
  return apiCall(`${baseUrl}/neumatico-por-matricula`, {
    method: 'POST',
    body: JSON.stringify({ matricula }),
  })
}
