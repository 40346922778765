import * as Actions from './revisionMotoModelosAction'
import { RevisionModelo } from '../../api/getModelosByMarcaAndCilindrada'

export const version = 1
export const key = 'revisionMotoModelos'

interface State {
  _version: number
  loading: boolean
  error: string | null
  modelos: RevisionModelo[] | null
}

export const initialState: State = {
  _version: version,
  loading: false,
  error: null,
  modelos: null,
}

export type RevisionModelosState = typeof initialState
export const revisionMotoModelosReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.REVISION_MODELOS:
      return {
        ...state,
        loading: true,
      }
    case Actions.REVISION_MODELOS_OK:
      return {
        ...state,
        loading: false,
        modelos: action.payload,
      }
    case Actions.REVISION_MODELOS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case Actions.REVISION_MODELOS_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        modelos: null,
      }

    default:
      return state
  }
}
