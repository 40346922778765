import { Marca } from '../../type/marca'
import * as Actions from './marcasAction'

export const version = 1
export const key = 'bateriasMarcas'

interface State {
  _version: number
  loading: boolean
  error: string | null
  data: Marca[]
}

export const initialState: State = {
  _version: version,
  loading: false,
  error: null,
  data: [],
}

export type MarcaState = typeof initialState
export const marcasReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.BATERIAS_MARCA_LIST:
      return {
        ...state,
        loading: true,
      }
    case Actions.BATERIAS_MARCA_LIST_OK:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
      }
    case Actions.BATERIAS_MARCA_LIST_KO:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case Actions.BATERIAS_MARCA_LIST_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        data: [],
      }

    default:
      return state
  }
}
