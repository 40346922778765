import {
  GetRevisionMarcasApiRequest,
  RevisionMarca,
} from '../../api/getMarcasByCilindrada'

export const REVISION_MARCAS = 'REVISION_MOTO::GET_MARCAS'
export const REVISION_MARCAS_OK = 'REVISION_MOTO::GET_MARCAS_OK'
export const REVISION_MARCAS_FAILED = 'REVISION_MOTO::GET_MARCAS_FAILED'
export const REVISION_MARCAS_RESET = 'REVISION_MOTO::GET_MARCASS_RESET'

export function revisionMarcas(data: GetRevisionMarcasApiRequest) {
  return { type: REVISION_MARCAS, payload: { data } }
}

export function revisionMarcasOk(datos: RevisionMarca[]) {
  return { type: REVISION_MARCAS_OK, payload: datos }
}

export function revisionMarcasKo(message) {
  return { type: REVISION_MARCAS_FAILED, payload: { error: message } }
}

export function revisionMarcasReset() {
  return { type: REVISION_MARCAS_RESET }
}
