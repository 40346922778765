import { all, put, select, takeLatest } from 'redux-saga/effects'
import * as ClientVehiclesActions from '../actions/clientVehiclesActions'
import {
  findClientVehiclesFailed,
  findClientVehiclesOk,
} from '../actions/clientVehiclesActions'
import { selectUserInfo } from '../selectors/userSelectors'
import { findClientVehicles } from '../../logic'

function* findVehicles() {
  const state = yield select()
  const userInfo = selectUserInfo(state)
  try {
    if (userInfo.idUser) {
      const result = yield findClientVehicles(userInfo.idUser)
      yield put(findClientVehiclesOk(result))
    } else {
      yield put(findClientVehiclesOk([]))
    }
  } catch (err) {
    yield put(findClientVehiclesFailed(err))
  }
}
function* sagas() {
  return all([
    yield takeLatest(ClientVehiclesActions.CLIENT_VEHICLES_FIND, findVehicles),
  ])
}

export default sagas
