import { apiCall } from '../../../common/api/api'

export interface PostRevisionDatos {
  matricula: string
  cilindrada: string
}
export interface RevisionDatos {
  idNavision: string
  idLead: number
  precio: number
  matricula: string
  cilindrada: string
}

export function postRevisionDatos(
  matricula: string,
  cilindrada: string
): Promise<{ RevisionDatos }> {
  return apiCall(`${process.env.GATSBY_API_URL}/moto/revision/datos`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ matricula, cilindrada }),
  })
}
