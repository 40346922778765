import Step, { StepDetails } from '../components/FunnelSelector/SelectorSteps'
import { SelectorState, Temporada } from '../context/reducers/selectorReducer'
import dataLayer from '../helpers/functions/dataLayer'
import { Marca } from '../components/withMarcas'

export type GmtTemporada = 'invierno' | 'verano' | 'cuatro_estaciones'

export const temporadaMapping: Record<Temporada, GmtTemporada> = {
  invierno: 'invierno',
  verano: 'verano',
  allseason: 'cuatro_estaciones',
}

const reportAllPreferencias = (state: SelectorState) => {
  const marca = state.marcas.find(marca => marca.id === state.marca)
  dataLayer.push({
    event: 'buscar_neumaticos',
    action: 'general',
    label: 'desktop',
    tipo_neumaticos: temporadaMapping[state.temporada.value],
    runflat: state.runflat.value,
    marca: marca ? marca.label : 'all',
  })
}

const reportEstacion = (estacion: Temporada) => {
  dataLayer.push({
    event: 'buscar_neumaticos_tipo',
    action: 'general',
    label: 'mobile',
    tipo_neumaticos: temporadaMapping[estacion],
  })
}

const reportRunFlat = (value: null | boolean) => {
  dataLayer.push({
    event: 'buscar_neumaticos_runflat',
    action: 'general',
    label: 'mobile',
    runflat: value,
  })
}

const reportMarcas = (marca: string | null) => {
  dataLayer.push({
    event: 'buscar_neumaticos_marca',
    action: 'general',
    label: 'mobile',
    marca,
  })
}

export const reportSelectorSteps = (
  state: SelectorState,
  device: 'desktop' | 'mobile',
  step: Step,
  marcas?: Marca[]
) => {
  switch (step) {
    case StepDetails[Step.SELECT_ESTACION].step:
      if (device === 'desktop') {
        reportAllPreferencias(state)
      } else {
        reportEstacion(state.temporada.value)
      }
      break
    case StepDetails[Step.SELECT_RUNFLAT].step:
      if (device === 'mobile') {
        reportRunFlat(state.runflat.value)
      }
      break
    case StepDetails[Step.SELECT_MARCAS].step:
      if (device === 'mobile') {
        const marca = marcas.find(marca => marca.id === state.marca)
        reportMarcas(marca ? marca.label : 'all')
      }
      break
  }
}
