import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import { searchNeumaticosMoto } from '../../api/searchMoto'
import * as SearchMotoActions from '../actions/searchMotoActions'
import * as SearchCamaraMotoActions from '../actions/searchCamaraMotoActions'
import { SelectorMotoState } from '../reducers/selectorMotoReducer'
import { selectSelectorMoto } from '../selectors/selectorMotoSelectors'
import { UserState } from '../reducers/userReducer'
import { selectUserInfo } from '../selectors/userSelectors'
import { searchCamarasMoto } from '../../api/searchCamaraMoto'
import { OptionMedida } from '../../api/selectorMoto'
import { restoreFiltro } from '../actions/selectorMotoActions'

function mapSelectorMotoToSearch(
  state: SelectorMotoState,
  userInfo: UserState,
  options: Partial<SearchMotoActions.SearchParametersMoto>
): SearchMotoActions.SearchParametersMoto {
  return {
    pagination: options.pagination,
    order: options.order,
    direction: options.direction || '',
    web: process.env.GATSBY_WEB,
    medidas: {
      ancho: state.ancho.value,
      alto: state.alto.value,
      llanta: state.llanta.value,
    },
    filtros: state.filtros,
    userInfo,
  } as SearchMotoActions.SearchParametersMoto
}

function mapSelectorCamaraMotoToSearch(
  state: SelectorMotoState,
  userInfo: UserState,
  options: Partial<SearchCamaraMotoActions.SearchParametersCamaraMoto>
): SearchCamaraMotoActions.SearchParametersCamaraMoto {
  const query = {
    pagination: options.pagination,
    order: options.order,
    direction: options.direction || '',
    web: process.env.GATSBY_WEB,
    filters: {
      medidas: {
        ancho: state.ancho.value,
        alto: state.alto.value,
        llanta: state.llanta.value,
      },
    },
    userInfo,
  } as SearchCamaraMotoActions.SearchParametersCamaraMoto

  query.filters.id_site_marca_neumatico = state.filtros.marcas

  return query
}

function* updateSelectedTipos(
  selectorTipos: string[],
  newSearchTipos: OptionMedida[]
) {
  const newSelected = selectorTipos.filter((tipo) =>
    newSearchTipos.find((t) => t.value === tipo)
  )
  yield put(restoreFiltro('tipos', newSelected))
}
function* searchMoto(action: ReturnType<typeof SearchMotoActions.searchMoto>) {
  const state = yield select()
  const selectorMotoState = selectSelectorMoto(state)
  const userInfo = selectUserInfo(state)
  const query = mapSelectorMotoToSearch(
    selectorMotoState,
    userInfo,
    action.payload
  )
  // reportSearch(selectorState)
  try {
    const results = yield searchNeumaticosMoto(query)
    yield call(
      updateSelectedTipos,
      selectorMotoState.filtros.tipos,
      results.tipos
    )
    yield put({
      type: SearchMotoActions.SEARCH_OK,
      payload: results,
    })
  } catch (err) {
    yield put({
      type: SearchMotoActions.SEARCH_FAILED,
      payload: err.message,
    })
  }
}

function* searchCamaraMoto(
  action: ReturnType<typeof SearchCamaraMotoActions.searchCamaraMoto>
) {
  const state = yield select()
  const selectorState = selectSelectorMoto(state)
  const userInfo = selectUserInfo(state)
  const query = mapSelectorCamaraMotoToSearch(
    selectorState,
    userInfo,
    action.payload
  )
  try {
    const results = yield searchCamarasMoto(query)
    yield put({
      type: SearchCamaraMotoActions.SEARCH_CAMARA_OK,
      payload: results,
    })
  } catch (err) {
    yield put({
      type: SearchCamaraMotoActions.SEARCH_CAMARA_FAILED,
      payload: err.message,
    })
  }
}
function* sagas() {
  return all([
    yield takeLatest(SearchMotoActions.SEARCH, searchMoto),
    yield takeLatest(SearchCamaraMotoActions.SEARCH_CAMARA, searchCamaraMoto),
  ])
}

export default sagas
