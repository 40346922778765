import selectorSagas from './selectorSagas'
import cestaSagas from './cestaSagas'
import userSagas from './userSagas'
import searchSagas from './searchSagas'
import selectorMotoSagas from './selectorMotoSagas'
import searchMotoSagas from './searchMotoSagas'
import appSetupSagas from './appSetupSagas'
import areaPrivadaSagas from './areaPrivadaSagas'
import revisionSagas from './revisionSagas'
import { all } from 'redux-saga/effects'
import clientVehiclesSagas from './clientVehiclesSagas'
import aireAcondicionadoSagas from './aireAcondicionadoSagas'
import revisionMotoSagas from '../../../moto/revisiones/store/matricula/revisionMotoMatriculaSaga'
import revisionMotoMarcasSagas from '../../../moto/revisiones/store/marcas/revisionMotoMarcasSaga'
import revisionMotoModelosSagas from '../../../moto/revisiones/store/modelos/revisionMotoModelosSaga'
import revisionMotoLeadsSagas from '../../../moto/revisiones/store/lead/revisionMotoLeadSaga'
import bateriasListSaga from '../../../coche/baterias/store/baterias/bateriasListSaga'
import bateriasMarcasSaga from '../../../coche/baterias/store/baterias/marcasSaga'
import bateriasFilterValuesSaga from '../../../coche/baterias/store/baterias/bateriasFilterValuesSaga'
export default function* () {
  while (true) {
    yield all([
      selectorSagas(),
      cestaSagas(),
      userSagas(),
      searchSagas(),
      appSetupSagas(),
      selectorMotoSagas(),
      searchMotoSagas(),
      areaPrivadaSagas(),
      revisionSagas(),
      clientVehiclesSagas(),
      aireAcondicionadoSagas(),
      revisionMotoSagas(),
      revisionMotoMarcasSagas(),
      revisionMotoModelosSagas(),
      revisionMotoLeadsSagas(),
      bateriasListSaga(),
      bateriasMarcasSaga(),
      bateriasFilterValuesSaga(),
    ])
  }
}
