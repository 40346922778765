class MemoryStorage {
  items = {} as { [k: string]: string }

  key = (index: number): string | null => {
    const keys = Object.keys(this.items)
    return index < keys.length ? this.items[keys[index]] : null
  }

  getItem = (keyName: string): string | null =>
    keyName in this.items ? this.items[keyName] : null

  setItem = (keyName: string, value: string): void => {
    this.items[keyName] = value
  }

  removeItem = (keyName: string): void => {
    delete this.items[keyName]
  }
}

export const localStorage =
  typeof window === 'undefined' || typeof window.localStorage === 'undefined'
    ? new MemoryStorage()
    : window.localStorage

export const sessionStorage =
  typeof window === 'undefined' || typeof window.sessionStorage === 'undefined'
    ? new MemoryStorage()
    : window.sessionStorage
