import * as Actions from '../actions/areaPrivadaActions'
import { AreaPrivadaVehicle } from '../../types/AreaPrivada'
import { Vehicle } from '../../types/Vehiculo'
import { ICitaPrevia } from '../../types/CitaPrevia'
import { FavouriteTaller } from '../../types/Taller'

export const version = 1
export const key = 'areaPrivada'
export interface ServiceTaller {
  service: string
  nombre_es: string
  nombre_ca: string
  nombre_pt: string
}

interface State {
  _version: number
  userLoading: boolean
  vehicleLoading: boolean
  error: string | null
  vehicles: AreaPrivadaVehicle[]
  favouriteTaller: FavouriteTaller | null
  citasPreviasLoading: boolean
  citasPrevias: ICitaPrevia[] | null
  availableServices: ServiceTaller[] | []
}

export const initialState: State = {
  _version: version,
  userLoading: false,
  error: null,
  vehicleLoading: false,
  vehicles: [],
  favouriteTaller: null,
  citasPreviasLoading: false,
  citasPrevias: null,
  availableServices: [],
}

export type AreaPrivadaState = typeof initialState
export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.DELETE_VEHICLES:
    case Actions.FIND_VEHICLES:
      return {
        ...state,
        vehicleLoading: true,
      }
    case Actions.DELETE_VEHICLES_OK:
    case Actions.FIND_VEHICLES_OK:
      return {
        ...state,
        vehicleLoading: false,
        vehicles: [
          ...action.payload.vehicles.map((vehicle: Vehicle) => ({
            vehicle: { ...vehicle },
          })),
        ],
      }
    case Actions.FIND_VEHICLES_FAILED:
      return {
        ...state,
        vehicleLoading: false,
        error: action.payload.error,
      }
    case Actions.RESET_ERROR:
      return {
        ...state,
        error: null,
        vehicleLoading: false,
      }
    case Actions.SET_TALLER:
      return {
        ...state,
      }
    case Actions.SET_TALLER_OK:
      return {
        ...state,
        favouriteTaller: action.payload.favouriteTaller,
      }
    case Actions.FIND_CITAS_PREVIAS:
      return {
        ...state,
      }
    case Actions.FIND_CITAS_PREVIAS_OK:
      return {
        ...state,
        citasPrevias: action.payload.citasPrevias,
      }
    case Actions.FIND_CITAS_PREVIAS_FAILED:
      return {
        ...state,
        citasPrevias: [],
      }
    case Actions.FIND_SERVICES:
      return {
        ...state,
      }
    case Actions.FIND_SERVICES_OK:
      return {
        ...state,
        availableServices: action.payload.services,
      }
    case Actions.FIND_SERVICES_FAILED:
      return {
        ...state,
        availableServices: [],
      }
    case Actions.FIND_ACEITE:
      return {
        ...state,
      }
    case Actions.FIND_ACEITE_OK: {
      return {
        ...state,
        vehicles: [
          ...state.vehicles.map(
            (item): AreaPrivadaVehicle =>
              item.vehicle.cod_matricula ===
              action.payload.aceite?.matricula_normalizada
                ? {
                    ...item,
                    aceite: action.payload.aceite,
                  }
                : {
                    ...item,
                  }
          ),
        ],
      }
    }
    case Actions.FIND_NEUMATICO:
      return {
        ...state,
      }
    case Actions.FIND_NEUMATICO_OK: {
      return {
        ...state,
        vehicles: [
          ...state.vehicles.map(
            (item): AreaPrivadaVehicle =>
              item.vehicle.cod_matricula === action.payload.neumatico.matricula
                ? {
                    ...item,
                    neumatico: action.payload.neumatico,
                  }
                : {
                    ...item,
                  }
          ),
        ],
      }
    }
    case Actions.SET_CAR_REVISION:
    case Actions.SET_CAR_REVISION_OK:
    case Actions.FIND_NEUMATICO_FAILED:
    case Actions.SET_CAR_REVISION_FAILED:
    case Actions.FIND_ACEITE_FAILED: {
      return {
        ...state,
      }
    }

    default:
      return state
  }
}
