import { IAppState } from '../context'
import {
  CestaDiscount,
  CestaOrigin,
  CestaState,
  key,
  NULL_DISCOUNT,
  Product,
  Promocion,
  Service,
} from '../reducers/cestaReducer'
import {
  MONTAJE_TALLER_BATERIA_ID,
  MONTAJE_TALLER_ID,
  RECOGIDA_TALLER_ID,
} from '../sagas/cestaSagas'
import { CategoriaServicio } from '../../types/CategoriaServicio'
import { calculateImporte } from '../../utils/cestaLogic'

const ID_NAVISION_SEGURO_NEUMATICO = 'SEGURO_NEUMATICO'

export const isPromocionForServicios = (promocion: Promocion) =>
  promocion.tipo === 'seguro' ||
  promocion.tipo === 'alineacion' ||
  promocion.tipo === 'nitrogeno' ||
  promocion.tipo === 'packinvierno' ||
  promocion.tipo === 'kitur' ||
  promocion.tipo === 'kit4x4' ||
  promocion.tipo === 'kitcta' ||
  promocion.tipo === 'montaje'

export const isPromocionForProductos = (promocion: Promocion) =>
  !isPromocionForServicios(promocion)

export const isPromocionForKits = (promocion: Promocion) => {
  return (
    promocion.tipo === 'kitur' ||
    promocion.tipo === 'kit4x4' ||
    promocion.tipo === 'kitcta'
  )
}
// given a set of products, this function will provide the service
// promotion if there is and applies (client specified that only those
// cestas with the promoted brand can access this kind of promotion)
export const findApplyingServicePromotion = (products: Product[]) => {
  const productWithervicePromotion = products.find(
    (product) => product.promocion && isPromocionForServicios(product.promocion)
  )
  if (!productWithervicePromotion) return NULL_DISCOUNT

  const servicePromotion = productWithervicePromotion.promocion

  // the next condition forces that if there are more than one
  // brand of neumáticos in this cesta, we will not apply the promotion,
  // by client petition
  const hasMoreBrandsInCart = products.some(
    (p) =>
      p.tipo_producto === 'neumatico' &&
      p.marca !== productWithervicePromotion.marca
  )
  return hasMoreBrandsInCart ? NULL_DISCOUNT : servicePromotion
}

export const itIsDiscountedService = (
  item: Service,
  promotion: Promocion | CestaDiscount
) => {
  return (
    (item.categoriaServicio === CategoriaServicio.Nitrogeno &&
      promotion.tipo === 'nitrogeno') ||
    (item.categoriaServicio === CategoriaServicio.Seguro &&
      promotion.tipo === 'seguro') ||
    (item.categoriaServicio === CategoriaServicio.PackInvierno &&
      promotion.tipo === 'packinvierno') ||
    (item.categoriaServicio === CategoriaServicio.Alineacion &&
      promotion.tipo === 'alineacion') ||
    (item.categoriaServicio === CategoriaServicio.Kitur &&
      promotion.tipo === 'kitur') ||
    (item.categoriaServicio === CategoriaServicio.Kit4x4 &&
      promotion.tipo === 'kit4x4') ||
    (item.categoriaServicio === CategoriaServicio.Kitcta &&
      promotion.tipo === 'kitcta')
  )
}

export const selectCesta = (appState: IAppState) => appState[key]

export const selectNumeroNeumaticos = (cestaState: CestaState) =>
  cestaState.products
    .filter((prod) => prod.tipo_producto === 'neumatico')
    .reduce((acc, p) => acc + Number(p.cantidad), 0)

export const selectNumeroProductos = (cestaState: CestaState) =>
  cestaState.products.reduce((acc, p) => acc + Number(p.cantidad), 0)

export const hasServices = (cestaState: CestaState) =>
  cestaState.services.length > 0

export const needsDates = (cestaState: CestaState) =>
  cestaState.products.filter((prod) => prod.tipo_producto === 'neumatico')
    .length > 0

export const needsDatesExtends = (cestaState: CestaState) => {
  if (cestaState.products.length === 1) {
    if (cestaState.products[0].tipo_producto === 'neumatico') {
      return 'neum'
    } else if (cestaState.products[0].tipo_producto === 'revision') {
      return 'rev'
    } else {
      return 'prod'
    }
  } else if (cestaState.products.length > 1) {
    const neumatico = cestaState.products.find(
      (prod) => prod.tipo_producto === 'neumatico'
    )
    const revision = cestaState.products.find(
      (prod) => prod.tipo_producto === 'revision'
    )
    const otro = cestaState.products.find(
      (prod) =>
        prod.tipo_producto !== 'neumatico' && prod.tipo_producto !== 'revision'
    )
    if (neumatico && revision && otro) {
      return 'prod_rev_neum'
    } else if (neumatico && revision) {
      return 'combi'
    } else if (revision && otro) {
      return 'prod_rev'
    } else if (neumatico && otro) {
      return 'prod_neum'
    } else {
      return 'neum'
    }
  }
  return 'neum'
}
export const selectImporteTotalProductos = (cestaState: CestaState) => {
  let total = 0.0
  cestaState.products.forEach((p) => {
    total +=
      p.importe -
      (p.promocion.tipo !== 'seguro' &&
      p.promocion.tipo !== 'nitrogeno' &&
      p.promocion.tipo !== 'packinvierno' &&
      p.promocion.tipo !== 'alineacion' &&
      p.promocion.tipo !== 'kitur' &&
      p.promocion.tipo !== 'kit4x4' &&
      p.promocion.tipo !== 'kitcta' &&
      p.promocion.tipo !== 'montaje'
        ? p.promocion.importePromocion
        : 0)
    p.servicios.forEach((s) => {
      if (s.categoriaServicio !== CategoriaServicio.Montaje) {
        total +=
          s.importe -
          ((p.promocion.tipo === 'kitur' ||
            p.promocion.tipo === 'kit4x4' ||
            p.promocion.tipo === 'kitcta') &&
          s.categoriaServicio === p.promocion.tipo
            ? (s.importe * p.promocion.importePromocion) / 100
            : 0)
      }
    })
  })
  return Math.round(total * 100) / 100
}

// TODO: This function will now have to check if there is
// an available service discount
export const selectImporteTotalServicios = (cestaState: CestaState) => {
  const servicePromotion = findApplyingServicePromotion(cestaState.products)
  const selectedServices = cestaState.services.filter((s) => s.selected)

  let totals = 0
  selectedServices.forEach((service) => {
    if (itIsDiscountedService(service, servicePromotion)) {
      totals +=
        service.importe -
        (service.importe * servicePromotion.importe_cupon) / 100
    } else {
      totals += service.importe
    }
  })
  return totals
}

const calculateGlobalDiscount = (
  products: Product[],
  importe_cesta: number,
  tipo: string | null,
  importe_cupon: number,
  tipo_producto: string
) => {
  const matches = tipo_producto
    ? products.some((p) => p.tipo_producto === tipo_producto)
    : true

  if (!matches) {
    return 0
  }

  switch (tipo) {
    case 'global-absoluto':
      return Math.round(100 * Number(importe_cupon)) / 100
    case 'global-porcentaje':
      return (
        Math.round(((100 * Number(importe_cupon)) / 100) * importe_cesta) / 100
      )
    default:
      return 0
  }
}

export const selectGlobalDiscount = (cestaState: CestaState) => {
  return cestaState.discount
}

// TODO: En la página Tu Solicitud hay que calcular el descuento global sólo sobre los productos

export const selectGlobalDiscountAmount = (cestaState: CestaState) => {
  const globalDiscount = selectGlobalDiscount(cestaState)
  const importe_cesta =
    selectImporteTotalProductos(cestaState) +
    selectImporteTotalServicios(cestaState)

  return calculateGlobalDiscount(
    cestaState.products,
    importe_cesta,
    globalDiscount.tipo,
    globalDiscount.importe_cupon,
    globalDiscount.tipo_producto
  )
}

const selectMontajeMotoDiscount = (cestaState: CestaState) => {
  const service = cestaState.services.find(
    (s) => s.id_navision === MONTAJE_TALLER_ID && s.selected && s.promocion
  )
  return service ? service.promocion.importePromocion : 0
}
export const selectImporteTotal = (cestaState: CestaState) => {
  const total =
    selectImporteTotalProductos(cestaState) +
    selectImporteTotalServicios(cestaState) -
    selectGlobalDiscountAmount(cestaState) -
    selectMontajeMotoDiscount(cestaState)
  return Math.round(total * 100) / 100
}

export const isNullDiscount = (discount: CestaDiscount | Promocion) =>
  !discount.id_site_promocion_cesta

export const hasSeguroNeumatico = (cestaState: CestaState) => {
  const servicio = cestaState.services.find(
    (s) => s.id_navision === ID_NAVISION_SEGURO_NEUMATICO
  )
  return servicio && servicio.selected
}

export const hasRecogidaNeumatico = (cestaState: CestaState) => {
  const servicio = cestaState.services.find(
    (s) => s.id_navision === RECOGIDA_TALLER_ID
  )
  return servicio && servicio.selected
}

export const hasMontajeNeumatico = (cestaState: CestaState) => {
  const servicio = cestaState.services.find(
    (s) => s.id_navision === MONTAJE_TALLER_ID
  )
  return servicio && servicio.selected
}

export const hasMontajeBateria = (cestaState: CestaState) => {
  const servicio = cestaState.services.find(
    (s) => s.id_navision === MONTAJE_TALLER_BATERIA_ID
  )
  return servicio && servicio.selected
}
export const isCestaRevisionMoto = (cestaState: CestaState) => {
  return cestaState.products.every(
    (product) =>
      product.categoria === 'moto' && product.tipo_producto === 'revision_moto'
  )
}

export const isCestaMoto = (products: Product[]) => {
  return products.every((product) => product.categoria === 'moto')
}

export const isCestaCoche = (products: Product[]) => {
  return products.every(
    (product) =>
      product.categoria === 'coche' && product.tipo_producto !== 'bateria'
  )
}

export const isCestaBateria = (products: Product[]) => {
  return (
    products.length &&
    products.every((product) => product.tipo_producto === 'bateria')
  )
}

export const needsRemoveNonUnitaryServices = (
  products: Product[],
  services: Service[]
) => {
  const remainingTyresCoche = products.some(
    (p) => p.tipo_producto === 'neumatico'
  )
  const remainingProductsMoto = products.some((p) =>
    ['neumatico', 'camara', 'mousse'].includes(p.tipo_producto)
  )
  return (
    services.length > 0 &&
    ((isCestaCoche(products) && !remainingTyresCoche) ||
      (isCestaMoto(products) && !remainingProductsMoto))
  )
}
export const canCestaMontaje = (products: Product[]) => {
  return (
    products.some((product) => product.tipo_producto === 'neumatico') &&
    products.every((product) =>
      product.servicios.find(
        (service) => service.categoriaServicio === CategoriaServicio.Montaje
      )
    )
  )
}

export const hasMontajeDiscount = (products: Product[]) => {
  return (
    products.some(
      (product) => product.promocion && product.promocion.tipo === 'montaje'
    ) ||
    (products.some((product) => product.tipo_producto === 'neumatico') &&
      products.some((product) =>
        product.servicios.find(
          (service) =>
            service.categoriaServicio === CategoriaServicio.Montaje &&
            parseFloat(service.descuento) > 0
        )
      ))
  )
}

export const calculatePriceWithoutDiscount = (service: Service) => {
  return calculateImporte(
    {
      precio: service.precio,
      cantidad: '1',
      descuento: '0.00',
      promo: '0.00',
      impuesto: service.impuesto,
    },
    {
      ecotasa: false,
      promo: false,
      impuesto: true,
    }
  )
}
export const hasMousseProduct = (products: Product[]) => {
  return products.some((p) => p.tipo_producto === 'mousse')
}

export const getSpecialService = (cestaState: CestaState) => {
  if (isCestaMoto(cestaState.products)) {
    switch (true) {
      case hasRecogidaNeumatico(cestaState):
        return 'moto_recogida'
      case isCestaRevisionMoto(cestaState):
        return 'revision_moto'
      case hasMontajeNeumatico(cestaState) &&
        !hasMousseProduct(cestaState.products):
        return 'moto_montaje'
      case hasMontajeNeumatico(cestaState) &&
        hasMousseProduct(cestaState.products):
        return 'moto_montaje_mouse'
    }
  }
  if (isCestaBateria(cestaState.products)) {
    return 'bateria'
  }
}

export const isHighCapacityCarter = (cestaState: CestaState, item: Product) => {
  return (
    item.tipo_producto === 'revision' &&
    cestaState.newVehicleData &&
    cestaState.newVehicleData.capacidad_carter &&
    parseFloat(cestaState.newVehicleData.capacidad_carter) > 5
  )
}

export const hasAireAcondicionadoMessage = (
  cestaState: CestaState,
  item: Product | Service
) => {
  return (
    item.tipo_producto === 'ac' &&
    cestaState.newVehicleData &&
    cestaState.newVehicleData.ano_fabric &&
    cestaState.newVehicleData.ano_fabric >= 2014 &&
    cestaState.newVehicleData.ano_fabric <= 2017
  )
}

export const getPromocionFechaLimite = (cestaState: CestaState) => {
  const { promocion: { fecha_limite: fechaLimite = null } = {} } =
    cestaState.products.find(
      (p) => p.promocion.id_site_promocion_cesta !== null
    ) ||
    cestaState.services.find(
      (s) => s.promocion.id_site_promocion_cesta !== null
    ) ||
    {}

  return fechaLimite
}
export const isCestaExternal = (cestaState: CestaState) =>
  cestaState.origin === CestaOrigin.External
