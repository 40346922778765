import * as Actions from './revisionMotoMarcasAction'
import { RevisionMarca } from '../../api/getMarcasByCilindrada'

export const version = 1
export const key = 'revisionMotoMarcas'

interface State {
  _version: number
  loading: boolean
  error: string | null
  marcas: RevisionMarca[] | null
}

export const initialState: State = {
  _version: version,
  loading: false,
  error: null,
  marcas: null,
}

export type RevisionMarcasState = typeof initialState
export const revisionMotoMarcasReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.REVISION_MARCAS:
      return {
        ...state,
        loading: true,
      }
    case Actions.REVISION_MARCAS_OK:
      return {
        ...state,
        loading: false,
        marcas: action.payload,
      }
    case Actions.REVISION_MARCAS_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case Actions.REVISION_MARCAS_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        marcas: null,
      }

    default:
      return state
  }
}
