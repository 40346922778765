import logic from '../../logic'

import { IVehicleImages } from '../reducers/altaVehiculoReducer'
import { Vehicle } from '../../types/Vehiculo'

export const ADD_ALTA_VEHICULO_OBJECT = 'ADD_ALTA_VEHICULO_OBJECT'
export const ADD_MODELO_VEHICULO = 'ADD_MODELO_VEHICULO'
export const ADD_MARCA_VEHICULO = 'ADD_MARCA_VEHICULO'
export const ADD_MOTOR_VEHICULO = 'ADD_MOTOR_VEHICULO'
export const ADD_MATRICULA = 'ADD_MATRICULA'
export const ADD_VEHICLE_INFORMATION = 'ADD_VEHICLE_INFORMATION'
export const ADD_VEHICLE_INFORMATION_OK = 'ADD_VEHICLE_INFORMATION_OK'
export const ADD_VEHICLE_INFORMATION_FAILED = 'ADD_VEHICLE_INFORMATION_FAILED'
export const ADD_INFORMATION_FROM = 'ADD_INFORMATION_FROM'
export const ADD_VEHICLE_IMAGES = 'ADD_VEHICLE_IMAGES'
export const ADD_ALTA_VEHICULO_STEP = 'ADD_ALTA_VEHICULO_STEP'
export const RESET_ALTA_VEHICULO = 'RESET_ALTA_VEHICULO'
export const RESET_ALTA_VEHICULO_ERROR = 'RESET_ALTA_VEHICULO_ERROR'
export const SET_ALTA_VEHICULO_LOADING = 'SET_ALTA_VEHICULO_LOADING'
export const SET_SELECTED_VEHICLE = 'SET_SELECTED_VEHICLE'
export const RESET_ALTA_VEHICULO_LOADING = 'RESET_ALTA_VEHICULO_LOADING'

export function setMatricula(matricula: string) {
  return {
    type: ADD_MATRICULA,
    payload: { matricula },
  }
}

export function setVehicleInfo(vehicleInformation: Vehicle) {
  return {
    type: ADD_VEHICLE_INFORMATION_OK,
    payload: { vehicleInformation },
  }
}

export function setVehicleImages(vehicleImages: IVehicleImages) {
  return {
    type: ADD_VEHICLE_IMAGES,
    payload: { vehicleImages },
  }
}

export function setInfoFrom(informationFrom: string) {
  return {
    type: ADD_INFORMATION_FROM,
    payload: { informationFrom },
  }
}

export function setAltaVehiculoStep(step: number) {
  return {
    type: ADD_ALTA_VEHICULO_STEP,
    payload: { step },
  }
}
export function resetAltaVehiculos() {
  return {
    type: RESET_ALTA_VEHICULO,
  }
}

export function resetAltaVehiculosError() {
  return {
    type: RESET_ALTA_VEHICULO_ERROR,
  }
}

export function setAltaVehiculoLoading() {
  return {
    type: SET_ALTA_VEHICULO_LOADING,
  }
}

export function resetAltaVehiculoLoading() {
  return {
    type: RESET_ALTA_VEHICULO_LOADING,
  }
}

export function retrieveVehicleInfo(matricula: string) {
  return (dispatch) => {
    dispatch({
      type: ADD_VEHICLE_INFORMATION,
    })
    logic
      .retrieveVehicleInformation(matricula)
      .then((res) => {
        dispatch(setVehicleImages(res.vehicleImages || {}))
        dispatch(setInfoFrom(res.informationFrom))
        dispatch(setMatricula(matricula))
        dispatch(setVehicleInfo(res.vehicleData))
        dispatch(setAltaVehiculoStep(2))
      })
      .catch((err) => {
        dispatch({
          type: ADD_VEHICLE_INFORMATION_FAILED,
        })
      })
  }
}

export function storeCachedVehicles(vehicleInformation, images) {
  // api.storeVehiclesCache(vehicleInformation, images)
  // TODO
}

export function setSelectedVehicle(selectedVehicle) {
  return {
    type: SET_SELECTED_VEHICLE,
    payload: {
      selectedVehicle,
    },
  }
}
