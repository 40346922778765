import { all, call, put, select, takeLatest } from 'redux-saga/effects'
import * as AreaPrivadaActions from '../actions/areaPrivadaActions'
import {
  deleteVehicleFailed,
  deleteVehicleOk,
  findAceiteFailed,
  findAceiteOk,
  findCitasPreviasFailed,
  findCitasPreviasOk,
  findNeumaticoFailed,
  findNeumaticoOk,
  findServicesFailed,
  findServicesOK,
  findVehiclesFailed,
  findVehiclesOk,
  setCarRevisionOK,
  setTallerOk,
} from '../actions/areaPrivadaActions'
import { selectUserInfo } from '../selectors/userSelectors'
import {
  deleteVehicleFromUser,
  findAreaPrivadaServices,
  findClientVehicles,
  retrieveFavouriteTaller,
} from '../../logic'
import { retrieveCitas } from '../../api/citaPrevia'
import { t } from '../../i18n'
import { getUserAceite } from '../../api/aceite'
import {
  addPostCode,
  setOilInfo,
  setRevisionComingFrom,
  setVehicleInfo,
  startOilJourney,
} from '../actions/revisionActions'
import { getTireFromLicense } from '../../api/selector'
import { navigate } from 'gatsby'
import route from '../../utils/route'
import { RevisionComingFrom } from '../../types/Revision'

function* findAceiteMatricula(matricula) {
  const state = yield select()
  const userInfo = selectUserInfo(state)
  try {
    const result = yield getUserAceite(matricula, userInfo)
    yield put(findAceiteOk(result))
  } catch (err) {
    yield put(findAceiteFailed())
  }
}

function* findNeumaticoMatricula(matricula) {
  try {
    const result = yield getTireFromLicense(matricula)
    yield put(findNeumaticoOk(result))
  } catch (err) {
    yield put(findNeumaticoFailed())
  }
}
function* findVehicles() {
  const state = yield select()
  const userInfo = selectUserInfo(state)
  try {
    const vehicles = yield findClientVehicles(userInfo.idUser)
    yield put(findVehiclesOk(vehicles))
    for (const vehicle of vehicles) {
      yield call(findAceiteMatricula, vehicle.cod_matricula)
      yield call(findNeumaticoMatricula, vehicle.cod_matricula)
    }
  } catch (err) {
    yield put(findVehiclesFailed(t('alta_vehiculo.error_buscar_vehiculo')))
  }
}

function* setTaller() {
  const state = yield select()
  const userInfo = selectUserInfo(state)
  try {
    const result = yield retrieveFavouriteTaller(userInfo.idUser)
    yield put(setTallerOk(!Object.keys(result).length ? null : result))
  } catch (err) {
    yield put(setTallerOk(null))
  }
}

function* findCitasPrevias() {
  const state = yield select()
  const userInfo = selectUserInfo(state)
  try {
    const result = yield retrieveCitas(userInfo.idUser as unknown as number)
    yield put(findCitasPreviasOk(result))
  } catch (err) {
    yield put(findCitasPreviasFailed(err.message))
  }
}

function* findServices() {
  try {
    const result = yield findAreaPrivadaServices()
    yield put(findServicesOK(result))
  } catch (err) {
    yield put(findServicesFailed(t('cita_previa.servicios.error_cargando')))
  }
}

function* deleteVehicle(
  action: ReturnType<typeof AreaPrivadaActions.deleteVehicle>
) {
  const state = yield select()
  const userInfo = selectUserInfo(state)
  const matricula = action.payload.matricula
  try {
    const results = yield deleteVehicleFromUser(userInfo.idUser, matricula)
    yield put(deleteVehicleOk(results))
  } catch (err) {
    yield put(deleteVehicleFailed(t('alta_vehiculo.error_buscar_vehiculo')))
  }
}

function* setCarRevision(
  action: ReturnType<typeof AreaPrivadaActions.setCarRevision>
) {
  const state = yield select()
  const userInfo = selectUserInfo(state)

  const vehicle = action.payload.vehicle
  const aceite = action.payload.aceite
  yield put(setRevisionComingFrom(RevisionComingFrom.AREA_PRIVADA))
  yield put(addPostCode(userInfo.postCode))
  yield put(setVehicleInfo(vehicle))

  if (aceite) {
    yield put(setOilInfo(aceite))
  }
  yield put(startOilJourney())
  yield put(setCarRevisionOK())
}
function* setCarRevisionOk() {
  yield navigate(route('revisiones.asistente'))
}
function* sagas() {
  return all([
    yield takeLatest(AreaPrivadaActions.FIND_VEHICLES, findVehicles),
    yield takeLatest(AreaPrivadaActions.SET_TALLER, setTaller),
    yield takeLatest(AreaPrivadaActions.FIND_CITAS_PREVIAS, findCitasPrevias),
    yield takeLatest(AreaPrivadaActions.FIND_SERVICES, findServices),
    yield takeLatest(AreaPrivadaActions.DELETE_VEHICLES, deleteVehicle),
    yield takeLatest(AreaPrivadaActions.SET_CAR_REVISION, setCarRevision),
    yield takeLatest(AreaPrivadaActions.SET_CAR_REVISION_OK, setCarRevisionOk),
  ])
}

export default sagas
