import { all, put, takeLatest } from 'redux-saga/effects'
import { postRevisionDatos } from '../../api/postRevisionDatos'
import { t } from '../../../../common/i18n'
import {
  revisionDatos,
  revisionDatosKo,
  revisionDatosOk,
} from './revisionMotoMatriculaAction'
import * as Actions from './revisionMotoMatriculaAction'
import { navigate } from 'gatsby'
import route from '../../../../common/utils/route'
import { revisionMarcas } from '../marcas/revisionMotoMarcasAction'

export function* saveRevisionDatos(action: ReturnType<typeof revisionDatos>) {
  const matricula = action.payload.data.matricula
  const cilindrada = action.payload.data.cilindrada
  try {
    const { precio, idLead, idNavision } = yield postRevisionDatos(
      matricula,
      cilindrada
    )
    yield put(
      revisionDatosOk({ idNavision, idLead, precio, matricula, cilindrada })
    )
    yield put(revisionMarcas({ cilindrada }))
    yield navigate(route('moto.revisiones.asistente.datosMarcaRevisionMoto'))
  } catch (err) {
    yield put(revisionDatosKo(t('alta_vehiculo.error_buscar_vehiculo')))
  }
}
function* sagas() {
  return all([yield takeLatest(Actions.REVISION_DATOS, saveRevisionDatos)])
}

export default sagas
