import { apiCall } from '../../../common/api/api'

export interface GetRevisionMarcasApiRequest {
  cilindrada: string
}

export interface RevisionMarca {
  label: string
  value: number
  principal: boolean
  orden: number
}

export interface GetRevisionMarcasResponse {
  result: RevisionMarca[]
}
export function getMarcasByCilindrada(
  cilindrada: string,
  electrica: boolean | null | undefined
): Promise<GetRevisionMarcasResponse> {
  return apiCall(
    `${process.env.GATSBY_API_URL}/moto/marca/${cilindrada}?electrica=${electrica}`,
    {
      method: 'GET',
      auth: true,
    }
  )
}
