import { t } from '../../../common/i18n'
import * as api from '../../api/citaPrevia'
import { ITime } from '../../api/citaPrevia'
import * as apiVehicles from '../../api/vehicles'
import { SelectedTaller, Taller } from '../../types/Taller'
import {
  AvailableScooterBrand,
  InfoCitaModificar,
  SelectedService,
  VehicleTypes,
} from '../reducers/citaPreviaReducer'
import { navigate } from 'gatsby'
import {
  CitaPreviaEnum,
  citaPreviaSteps,
} from '../../components/CitaPrevia/CitaPreviaSteps'
import { Vehicle } from '../../types/Vehiculo'

export const CITA_PREVIA_SELECTED_CP = 'CITA_PREVIA_SELECTED_CP'
export const CITA_PREVIA_SELECTED_TALLER = 'CITA_PREVIA_SELECTED_TALLER'

export const CITA_PREVIA_SET_STEP = 'CITA_PREVIA_SET_STEP'

export const CITA_PREVIA_SERVICES = 'CITA_PREVIA_SERVICES'
export const CITA_PREVIA_SERVICES_OK = 'CITA_PREVIA_SERVICES_OK'
export const CITA_PREVIA_SERVICES_FAILED = 'CITA_PREVIA_SERVICES_FAILED'
export const CITA_PREVIA_SET_USER_DATA = 'CITA_PREVIA_SET_USER_DATA'
export const CITA_PREVIA_CHANGE_TAB = 'CITA_PREVIA_CHANGE_TAB'
export const CITA_PREVIA_SELECT_SERVICE = 'CITA_PREVIA_SELECT_SERVICE'
export const CITA_PREVIA_SELECT_TALLER_AND_SERVICE =
  'CITA_PREVIA_SELECT_TALLER_AND_SERVICE'

export const CITA_PREVIA_SET_SELECTED_DATE = 'CITA_PREVIA_SET_SELECTED_DATE'

export const STORE_CITA_PREVIA = 'STORE_CITA_PREVIA'
export const STORE_CITA_PREVIA_OK = 'STORE_CITA_PREVIA_OK'
export const STORE_CITA_PREVIA_VALIDATION_ERRORS =
  'STORE_CITA_PREVIA_VALIDATION_ERRORS'
export const STORE_CITA_PREVIA_FAILED = 'STORE_CITA_PREVIA_FAILED'

export const MODIFY_CITA_PREVIA = 'MODIFY_CITA_PREVIA'
export const MODIFY_CITA_PREVIA_OK = 'MODIFY_CITA_PREVIA_OK'
export const MODIFY_CITA_PREVIA_FAILED = 'MODIFY_CITA_PREVIA_FAILED'

export const CITA_PREVIA_RESET = 'CITA_PREVIA_RESET'

export const CITA_PREVIA_ORIGIN = 'CITA_PREVIA_ORIGIN'

export const RETRIEVE_CITA = 'RETRIEVE_CITA'
export const RETRIEVE_CITA_OK = 'RETRIEVE_CITA_OK'
export const RETRIEVE_CITA_FAILED = 'RETRIEVE_CITA_FAILED'

export const DELETE_CITA = 'DELETE_CITA'
export const DELETE_CITA_OK = 'DELETE_CITA_OK'
export const DELETE_CITA_FAILED = 'DELETE_CITA_FAILED'

export const CITA_PREVIA_MODIFY_STATUS = 'CITA_PREVIA_MODIFY'

export const RESET_ERROR = 'RESET_ERROR'
export const RESET_SELECTION = 'RESET_SELECTION'

export const START_CITA_PREVIA = 'START_CITA_PREVIA'
export const START_CITA_PREVIA_PATINETE = 'START_CITA_PREVIA_PATINETE'

export const CITA_PREVIA_SCOOTER_BRANDS_OK = 'CITA_PREVIA_SCOOTER_BRANDS_OK'
export const CITA_PREVIA_SCOOTER_BRANDS_FAILED =
  'CITA_PREVIA_SCOOTER_BRANDS_FAILED'
export const CITA_PREVIA_SET_SCOOTER_BRAND = 'CITA_PREVIA_SET_SCOOTER_BRAND'
export const CITA_PREVIA_SET_VEHICLE = 'CITA_PREVIA_SET_VEHICLE'
export const CITA_PREVIA_SET_FROM_REVISION = 'CITA_PREVIA_SET_FROM_REVISION'

export function fromRevision() {
  return {
    type: CITA_PREVIA_SET_FROM_REVISION,
    payload: {
      fromRevision: true,
    },
  }
}

export function setCodigoPostalIntroducido(cp: string) {
  return {
    type: CITA_PREVIA_SELECTED_CP,
    payload: {
      cp,
    },
  }
}
export function setVehicle(vehicle: Vehicle) {
  return {
    type: CITA_PREVIA_SET_VEHICLE,
    payload: {
      vehicleInfo: vehicle,
    },
  }
}
export function setSelectedTaller(
  selectedTaller: SelectedTaller,
  origin: string
) {
  return {
    type: CITA_PREVIA_SELECTED_TALLER,
    payload: {
      selectedTaller,
      origin,
    },
  }
}

export function setCitaPreviaStep(step: number) {
  return {
    type: CITA_PREVIA_SET_STEP,
    payload: {
      step,
    },
  }
}

export function setVehicleType(tabs: VehicleTypes[]) {
  return {
    type: CITA_PREVIA_CHANGE_TAB,
    payload: {
      vehicleTypes: tabs,
    },
  }
}

export function getAvailableServices(
  idTaller: string,
  selectedService: SelectedService
) {
  return (dispatch) => {
    api
      .getAvailableServices(idTaller)
      .then((res) => {
        const newTabs = [...res.tabs]
        if (selectedService && selectedService.vehicle) {
          const index = newTabs.findIndex((item) => {
            return (
              t(
                `cita_previa.servicios.${item.tab
                  .toLowerCase()
                  .replace(' ', '_')}`
              ) === selectedService.vehicle
            )
          })
          newTabs[index].active = true
        } else {
          newTabs[0].active = true
        }
        dispatch({
          type: CITA_PREVIA_SERVICES_OK,
          payload: {
            availableServices: {
              coche: res.coche,
              moto: res.moto,
            },
            vehicleTypes: res.tabs,
          },
        })
      })
      .catch((err) => {
        console.log(err)
        dispatch({
          type: CITA_PREVIA_SERVICES_FAILED,
          payload: {
            error: err.message,
          },
        })
      })
  }
}
export function resetCitaPrevia() {
  return {
    type: CITA_PREVIA_RESET,
  }
}
export function verifyPresetCitaPrevia(
  idTaller: string,
  service: string,
  origin: string
) {
  return (dispatch) => {
    api
      .verifyPresetCitaPrevia(idTaller, service)
      .then((res) => {
        const { taller, servicio } = res
        if (!taller) {
          dispatch(resetCitaPrevia())
          navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_TALLER].route)
        } else if (!servicio) {
          dispatch({
            type: CITA_PREVIA_SELECTED_TALLER,
            payload: {
              selectedTaller: taller,
              origin,
            },
          })
          dispatch(setCitaPreviaStep(1))
          navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_SERVICIO].route)
        } else {
          dispatch(setPresetTallerAndService(taller, servicio, origin))
          navigate(citaPreviaSteps[CitaPreviaEnum.SELECCION_HORARIO].route)
        }
      })
      .catch((err) => {
        dispatch({
          type: CITA_PREVIA_SERVICES_FAILED,
          payload: {
            error: err.message,
          },
        })
      })
  }
}

export function setSelectedService(
  selectedService: SelectedService,
  url?: string
) {
  return {
    type: CITA_PREVIA_SELECT_SERVICE,
    payload: {
      selectedService,
      url,
    },
  }
}

export function setPresetTallerAndService(
  selectedTaller: SelectedTaller,
  selectedService: SelectedService,
  origin: string
) {
  return {
    type: CITA_PREVIA_SELECT_TALLER_AND_SERVICE,
    payload: {
      selectedService,
      selectedTaller,
      origin,
    },
  }
}

export function setUserData(userData: UserDataProps) {
  return {
    type: CITA_PREVIA_SET_USER_DATA,
    payload: {
      userData,
    },
  }
}

export function setSelectedDate(time: api.ITime) {
  return {
    type: CITA_PREVIA_SET_SELECTED_DATE,
    payload: {
      selectedDate: time,
    },
  }
}

export interface UserDataProps {
  email: string
  marca: string
  matricula: string
  modelo: string
  name: string
  phone: string
  device: 'MOBILE' | 'TABLET' | 'DESKTOP'
  comment: string
  origin: string
}

export function storeCitaPrevia(
  userData: UserDataProps,
  selectedDate: ITime,
  selectedService: SelectedService,
  selectedTaller: Taller
) {
  return (dispatch) => {
    dispatch({
      type: STORE_CITA_PREVIA,
    })
    api
      .createCitaPrevia(userData, selectedDate, selectedService, selectedTaller)
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: STORE_CITA_PREVIA_VALIDATION_ERRORS,
            payload: {
              errors: res.errors,
            },
          })
        } else {
          dispatch({
            type: STORE_CITA_PREVIA_OK,
            payload: {
              citaPreviaId: res.citaPreviaId,
            },
          })
        }
      })
      .catch((err) => {
        if (err.message === 'Already booked cita previa') {
          dispatch({
            type: STORE_CITA_PREVIA_FAILED,
            payload: {
              error: t('cita_previa.error_cita_booked', {
                matricula: userData.matricula,
                taller: selectedTaller.alias,
              }),
            },
          })
        } else {
          dispatch({
            type: STORE_CITA_PREVIA_FAILED,
            payload: {
              error: t('cita_previa.error_guardar_cita'),
            },
          })
        }
      })
  }
}

export function setCitaPreviaOrigin(url) {
  return (dispatch) => {
    dispatch({
      type: CITA_PREVIA_ORIGIN,
      payload: {
        url,
      },
    })
  }
}

export function retrieveCitaInfo(id: number) {
  return (dispatch) => {
    dispatch({
      type: RETRIEVE_CITA,
    })
    api
      .retrieveCitaInfo(id)
      .then((res) => {
        dispatch({
          type: RETRIEVE_CITA_OK,
          payload: {
            selectedTaller: res.selectedTaller,
            citaPreviaId: res.citaPreviaId,
            selectedService: res.selectedService,
            selectedDate: res.selectedDate,
            userData: res.userData,
            step: 10,
          },
        })
      })
      .catch(() => {
        dispatch({
          type: RETRIEVE_CITA_FAILED,
          payload: {
            error: 'Ha habido un error',
          },
        })
      })
  }
}

export function deleteCitaPrevia(id: string) {
  return (dispatch) => {
    dispatch({
      type: DELETE_CITA,
    })
    api
      .deleteCitaPrevia(id)
      .then(() => {
        dispatch({
          type: DELETE_CITA_OK,
        })
      })
      .catch(() => {
        dispatch({
          type: DELETE_CITA_FAILED,
          payload: {
            error: t('cita_previa.error_anular'),
          },
        })
      })
  }
}

export function setModifyingCitaPrevia(cita: InfoCitaModificar) {
  return {
    type: CITA_PREVIA_MODIFY_STATUS,
    payload: {
      cita,
    },
  }
}

export function modifyCitaPrevia(
  userData: UserDataProps,
  selectedDate: api.ITime,
  selectedService: SelectedService,
  selectedTaller: SelectedTaller,
  oldCitaid: string
) {
  return (dispatch) => {
    dispatch({
      type: MODIFY_CITA_PREVIA,
    })
    api
      .modifyCitaPrevia(
        userData,
        selectedDate,
        selectedService,
        selectedTaller,
        oldCitaid
      )
      .then((res) => {
        if (res.errors) {
          dispatch({
            type: STORE_CITA_PREVIA_VALIDATION_ERRORS,
            payload: {
              errors: res.errors,
            },
          })
        } else {
          dispatch({
            type: MODIFY_CITA_PREVIA_OK,
            payload: {
              citaPreviaId: res.citaPreviaId,
            },
          })
        }
      })
      .catch((err) => {
        dispatch({
          type: MODIFY_CITA_PREVIA_FAILED,
          payload: {
            error: err.message,
          },
        })
      })
  }
}

export function resetError() {
  return {
    type: RESET_ERROR,
  }
}

export function resetSelection() {
  return {
    type: RESET_SELECTION,
  }
}

export function startCitaPrevia(url: string) {
  return {
    type: START_CITA_PREVIA,
    payload: {
      url,
    },
  }
}

export function startCitaPreviaPatinete(url: string) {
  return {
    type: START_CITA_PREVIA_PATINETE,
    payload: {
      url,
    },
  }
}

export function getAvailableScooterBrands() {
  return (dispatch) => {
    apiVehicles
      .getAvailableScooterBrands()
      .then((res) => {
        dispatch({
          type: CITA_PREVIA_SCOOTER_BRANDS_OK,
          payload: {
            scooterBrands: res,
          },
        })
      })
      .catch((err) => {
        dispatch({
          type: CITA_PREVIA_SCOOTER_BRANDS_FAILED,
          payload: {
            error: err.message,
          },
        })
      })
  }
}

export function setScooterBrand(marca: AvailableScooterBrand) {
  return {
    type: CITA_PREVIA_SET_SCOOTER_BRAND,
    payload: {
      selectedBrand: marca,
    },
  }
}
