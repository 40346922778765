import {
  RESET_SEARCH,
  SEARCH,
  SEARCH_FAILED,
  SEARCH_OK,
  SearchParametersMoto,
} from '../actions/searchMotoActions'
import { SearchMotoResults } from '../../api/searchMoto'
import { LOGOUT } from '../actions/userActions'
import { OptionMedida } from '../../api/selectorMoto'

export const key = 'searchMoto'

export const initialState = {
  loading: false as boolean,
  error: null as null | string,
  query: {
    order: '',
    direction: 'ASC',
    pagination: {
      pageSize: 10,
      page: 0,
    },
  } as Partial<SearchParametersMoto>,
  results: {
    pages: 0,
    total: 0,
    neumaticos: [],
    tipos: [] as OptionMedida[],
  } as SearchMotoResults,
}

export type SearchMotoState = typeof initialState

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGOUT:
      return { ...initialState }
    case RESET_SEARCH:
      return {
        ...initialState,
      }

    case SEARCH:
      return {
        ...state,
        query: action.payload,
        loading: true,
      }

    case SEARCH_OK:
      return {
        ...state,
        loading: false,
        results: action.payload,
      }

    case SEARCH_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload,
      }

    default:
      return state
  }
}
