import { Bateria } from '../../type/bateria'
import { PaginationResult } from '../../type/pagination'
import * as Actions from './bateriasListAction'

export const version = 1
export const key = 'bateriasList'

interface State {
  _version: number
  loading: boolean
  error: string | null
  baterias: Bateria[] | null
  pagination: PaginationResult
}
const initalPagination = {
  page: 1,
  total: 1,
  pageSize: 10,
  pages: 1,
}
export const initialState: State = {
  _version: version,
  loading: false,
  error: null,
  baterias: [],
  pagination: initalPagination,
}

export type BateriasListState = typeof initialState
export const bateriasListReducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.BATERIAS_LIST:
      return {
        ...state,
        loading: true,
      }
    case Actions.BATERIAS_LIST_OK:
      return {
        ...state,
        loading: false,
        baterias: action.payload.data.baterias,
        pagination: {
          page: action.payload.data.page,
          pageSize: action.payload.data.pageSize,
          pages: action.payload.data.pages,
          total: action.payload.data.total,
        },
      }
    case Actions.BATERIAS_LIST_FAILED:
      return {
        ...state,
        loading: false,
        error: action.payload.error,
      }
    case Actions.BATERIAS_LIST_RESET:
      return {
        ...state,
        loading: false,
        error: null,
        baterias: null,
        pagination: initalPagination,
      }

    default:
      return state
  }
}
