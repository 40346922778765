import { Vehicle } from '../../types/Vehiculo'
import { Device } from '../../types/Device'

export const AIRE_ACONDICIONADO_FIND = 'AIRE_ACONDICIONADO::FIND'
export const AIRE_ACONDICIONADO_FIND_OK = 'AIRE_ACONDICIONADO::FIND_OK'
export const AIRE_ACONDICIONADO_FIND_FAILED = 'AIRE_ACONDICIONADO::FIND_FAILED'

export const AIRE_ACONDICIONADO_SET_POSTCODE =
  'AIRE_ACONDICIONADO::SET_POSTCODE'
export const AIRE_ACONDICIONADO_SET_VEHICLE = 'AIRE_ACONDICIONADO::SET_VEHICLE'
export const AIRE_ACONDICIONADO_SET_STEP = 'AIRE_ACONDICIONADO::SET_STEP'
export const AIRE_ACONDICIONADO_SET_DEVICE = 'AIRE_ACONDICIONADO::SET_DEVICE'
export const AIRE_ACONDICIONADO_RESET = 'AIRE_ACONDICIONADO::RESET'

export function findAireAcondicionado() {
  return {
    type: AIRE_ACONDICIONADO_FIND,
  }
}
export function findAireAcondicionadoOk() {
  return {
    type: AIRE_ACONDICIONADO_FIND_OK,
  }
}
export function findAireAcondicionadoFailed(message) {
  return {
    type: AIRE_ACONDICIONADO_FIND_FAILED,
    payload: { error: message },
  }
}

export function setAireAcondicionadoPostCode(postCode: string) {
  return {
    type: AIRE_ACONDICIONADO_SET_POSTCODE,
    payload: { postCode },
  }
}

export function setAireAcondicionadoVehicle(vehicle: Vehicle) {
  return {
    type: AIRE_ACONDICIONADO_SET_VEHICLE,
    payload: { vehicle },
  }
}

export function setAireAcondicionadoStep(step: number) {
  return {
    type: AIRE_ACONDICIONADO_SET_STEP,
    payload: { step },
  }
}

export function setAireAcondicionadoDevice(device: Device) {
  return {
    type: AIRE_ACONDICIONADO_SET_DEVICE,
    payload: { device },
  }
}

export function resetAireAcondicionado() {
  return {
    type: AIRE_ACONDICIONADO_RESET,
  }
}
