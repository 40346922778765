export enum CategoriaServicio {
  Seguro = 'seguro',
  Alineacion = 'alineacion',
  PackInvierno = 'packinvierno',
  Nitrogeno = 'nitrogeno',
  Kitur = 'kitur',
  Kitcta = 'kitcta',
  Kit4x4 = 'kit4x4',
  Montaje = 'montaje',
}
