import { all, put, takeLatest } from 'redux-saga/effects'
import { t } from '../../../../common/i18n'
import * as Actions from './revisionMotoModelosAction'
import {
  revisionModelosOk,
  revisionModelosKo,
  revisionModelos,
} from './revisionMotoModelosAction'
import {
  getModelosByMarcaAndCilindrada,
  GetRevisionModelosResponse,
} from '../../api/getModelosByMarcaAndCilindrada'

export function* getRevisionModelos(
  action: ReturnType<typeof revisionModelos>
) {
  const cilindrada = action.payload.data.cilindrada
  const marca = action.payload.data.marca
  try {
    const res: GetRevisionModelosResponse =
      yield getModelosByMarcaAndCilindrada(marca, cilindrada, false)
    yield put(revisionModelosOk(res.result))
  } catch (err) {
    yield put(revisionModelosKo(t('revisionesMoto.errores.get_modelos')))
  }
}
function* sagas() {
  return all([yield takeLatest(Actions.REVISION_MODELOS, getRevisionModelos)])
}

export default sagas
