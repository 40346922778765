import { VehicleOil } from '../context/actions/revisionActions'
import { apiCall } from './api'
import { Vehicle } from '../types/Vehiculo'

const baseUrl = `${process.env.GATSBY_API_URL}`

interface VehicleData {}

interface VerifiedRevisionPreset {
  matricula: string
  vehicle: Vehicle
  kms: string
  aceite: VehicleOil
}

export const findOils = (
  vehicle: VehicleData,
  matricula: string,
  kms: string | undefined
): Promise<object> => {
  return apiCall(`${baseUrl}/selector-aceite`, {
    method: 'POST',
    body: JSON.stringify({ vehicle, matricula, kms }),
  })
}

export const verifyPresetRevisiones = (
  matricula: string,
  kms: string | undefined,
  aceite: string
): Promise<VerifiedRevisionPreset> => {
  return apiCall(`${baseUrl}/verificar-revision-preset`, {
    method: 'POST',
    body: JSON.stringify({ kms, matricula, aceite }),
  })
}
