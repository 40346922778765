import { DatosUsuarioFormGuest } from '../components/CitaPrevia/DatosUsuario/DatosUsuarioForm/DatosUsuarioForm'
import { UserDataProps } from '../context/actions/citaPreviaActions'
import {
  AvailableServices,
  SelectedService,
  VerifiedTallerAndService,
} from '../context/reducers/citaPreviaReducer'
import { SelectedTaller, Taller } from '../types/Taller'
import { apiCall } from './api'
import { ICitaPrevia } from '../types/CitaPrevia'

const baseUrl = `${process.env.GATSBY_API_URL}`

export const getAvailableServices = (
  idTaller: string
): Promise<AvailableServices> => {
  return apiCall(`${baseUrl}/talleres-servicios/${idTaller}`)
}

export const verifyPresetCitaPrevia = (
  idTaller: string,
  service: string
): Promise<VerifiedTallerAndService> => {
  return apiCall(`${baseUrl}/verificar-taller-servicio/${idTaller}/${service}`)
}

export interface IDate {
  formattedDay: string
  mesFormated: string
  originalDate: string
}

export interface ITime {
  moment: string
  date: string
  time: string
}

interface InitialDatesRequest {
  horarios: ITime[]
  fechas: IDate[]
}
export const getInitialDates = (
  idTaller: string,
  idNavisionCitaModificada?: string
): Promise<InitialDatesRequest> => {
  return apiCall(`${baseUrl}/cita-previa-dates/taller/${idTaller}`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ idNavisionCitaModificada }),
  })
}

interface TimesResponse {
  horarios: ITime[]
}
export const getTimes = (
  idTaller: string,
  date: string,
  idNavisionCitaModificada?: string
): Promise<TimesResponse> => {
  return apiCall(`${baseUrl}/cita-previa-times/taller/${idTaller}`, {
    method: 'POST',
    auth: true,
    body: JSON.stringify({ date, idNavisionCitaModificada }),
  })
}

export const createCitaPrevia = (
  userData: UserDataProps,
  selectedDate: ITime,
  selectedService: SelectedService,
  selectedTaller: Taller
) => {
  return apiCall(`${baseUrl}/cita-previa`, {
    method: 'POST',
    body: JSON.stringify({
      userData,
      selectedDate,
      selectedService,
      selectedTaller,
    }),
  })
}

export const retrieveCitas = (idUser: number): Promise<ICitaPrevia[]> => {
  // TODO: This should probably be authenticated (why isn't it?)
  return apiCall(`${baseUrl}/citas-previas/${idUser}`)
}

interface CitaInfoResponse {
  selectedTaller: SelectedTaller
  selectedService: SelectedService
  selectedDate: ITime
  citaPreviaId: string
  userData: DatosUsuarioFormGuest
}
export const retrieveCitaInfo = (idCita: number): Promise<CitaInfoResponse> => {
  // TODO: This should probably be authenticated (why isn't it?)
  return apiCall(`${baseUrl}/cita-previa/${idCita}`)
}

interface DeleteCitaResponse {
  id_orden_navision: string
}
export const deleteCitaPrevia = (
  idNavisionCita: string
): Promise<DeleteCitaResponse> => {
  // TODO: This should probably be authenticated (why isn't it?)
  return apiCall(`${baseUrl}/cita-previa/${idNavisionCita}`, {
    method: 'DELETE',
  })
}

export const modifyCitaPrevia = (
  userData: UserDataProps,
  selectedDate: ITime,
  selectedService: SelectedService,
  selectedTaller: SelectedTaller,
  oldCitaId: string
) => {
  // TODO: This should probably be authenticated (why isn't it?)
  return apiCall(`${baseUrl}/cita-previa`, {
    method: 'PATCH',
    body: JSON.stringify({
      userData,
      selectedDate,
      selectedService,
      selectedTaller,
      oldCitaId,
    }),
  })
}
