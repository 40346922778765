import { apiCall } from '../../../common/api/api'
import { BornesBateria, TipoBateria } from '../type/bateria'

export type FilterValues = {
  tipo_bateria: TipoBateria[]
  amperaje: number[]
  potencia_arranque: number[]
  posicion_positivo: BornesBateria[]
}

export function getBateriasFilterValues(): Promise<FilterValues> {
  return apiCall(
    `${process.env.GATSBY_API_URL}/baterias/filter-values`,

    {
      method: 'GET',
      auth: true,
    }
  )
}
