import { all, put, select, takeLatest } from 'redux-saga/effects'
import { postRevisionLead } from '../../api/postRevisionLead'
import { t } from '../../../../common/i18n'
import * as Actions from './revisionMotoLeadAction'
import {
  revisionLead,
  revisionLeadKo,
  revisionLeadOk,
} from './revisionMotoLeadAction'
import * as CestaActions from '../../../../common/context/actions/cestaActions'
import {
  selectRevisionMotoIdNavision,
  selectRevisionMotoMatricula,
} from '../matricula/revisionMotoMatriculaSelectors'
import { DataSteps, Steps } from '../../../../common/components/cesta/Steps'
import { navigate } from 'gatsby'

export function* saveRevisionLead(action: ReturnType<typeof revisionLead>) {
  const state = yield select()
  const idNavision = yield selectRevisionMotoIdNavision(state)
  const matricula = yield selectRevisionMotoMatricula(state)

  const id_registro_lead_revision_moto =
    action.payload.data.idRegistroLeadRevisionMoto
  const id_site_marca_moto = action.payload.data.idSiteMarcaMoto
  const id_moto = action.payload.data.idMoto
  try {
    yield postRevisionLead(
      id_registro_lead_revision_moto,
      id_site_marca_moto,
      id_moto
    )
    yield put(revisionLeadOk())
    yield put(CestaActions.resetCesta())
    yield put(CestaActions.addMatricula(matricula))
    yield put(
      CestaActions.addProduct(
        {
          id: idNavision,
          type: 'revision_moto',
        },
        'revisiones'
      )
    )
    yield put(CestaActions.setStep(Steps.TU_SOLICITUD))
    yield navigate(DataSteps[Steps.TU_SOLICITUD].route)
  } catch (err) {
    yield put(revisionLeadKo(t('alta_vehiculo.error_buscar_vehiculo')))
  }
}
function* sagas() {
  return all([yield takeLatest(Actions.REVISION_LEAD, saveRevisionLead)])
}

export default sagas
