import { IAppState } from '../context'
import {
  AireAcondicionadoState,
  key,
} from '../reducers/aireAcondicionadoReducer'

export const selectAireAcondicionado = (
  state: IAppState
): AireAcondicionadoState => state[key] as AireAcondicionadoState

export const isAireAcondicionadoLoading = (state: IAppState): boolean => {
  const acState = selectAireAcondicionado(state)
  return acState.loading
}
export const getAireAcondicionadoStep = (state: IAppState): number => {
  const acState = selectAireAcondicionado(state)
  return acState.step
}
