import { SET } from '../../../specific/context/actions/menuActions'
import { MenuPrincipal } from '../../../specific/constants/menu'

export const key = 'menu'
export const initialState = MenuPrincipal.HOME
export type MenuState = typeof initialState

export const reducer = (state: MenuPrincipal = initialState, action) => {
  switch (action.type) {
    case SET:
      return action.payload
    default:
      return state
  }
}
