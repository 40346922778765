import { navigate } from 'gatsby'
import { all, put, select, takeLatest } from 'redux-saga/effects'
import { setToken } from '../../api/api'
import { retrieveUserInfo } from '../../api/user'
import * as GlobalActions from '../actions/global'
import { checkLogin, loginOk, LOGOUT, logout } from '../actions/userActions'
import { selectUserInfo } from '../selectors/userSelectors'

function* tryLogin() {
  const state = yield select()
  const userInfo = selectUserInfo(state)
  if (userInfo.loggedIn) {
    setToken(userInfo.token)

    yield put(checkLogin())

    try {
      const response = yield retrieveUserInfo()
      yield put(loginOk(response, 'login'))
    } catch {
      yield put(logout(false))
    }
  }
}

function logoutCleanup(logoutAction: ReturnType<typeof logout>) {
  if (logoutAction.redirect) {
    setToken(null)
    navigate('/')
  }
}

function* sagas() {
  return all([
    yield takeLatest(GlobalActions.INIT, tryLogin),
    yield takeLatest(LOGOUT, logoutCleanup),
  ])
}

export default sagas
