import { INIT } from '../actions/appSetupActions'

export const version = 1
export const key = 'appSetup'
export const initialState = {
  _version: version as number,
  allowMixedOrders: true as boolean,
}
export type AppSetupState = typeof initialState

export const reducer = (state: AppSetupState = initialState, action) => {
  switch (action.type) {
    case INIT:
      return {
        ...state,
        ...action.payload,
      }
    default:
      return state
  }
}
