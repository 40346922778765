import fetchWithRetry from 'fetch-retry'
import { localStorage } from '../utils/storage'

const inDevelopment = process.env.NODE_ENV === 'development'

const sleep = (ms: number) => new Promise((resolve) => setTimeout(resolve, ms))

export let _token = localStorage.getItem('token') || null

export function setToken(token: string | null) {
  _token = token
  if (token === null) {
    localStorage.removeItem('token')
  } else {
    localStorage.setItem('token', token)
  }
}

function _processResponse(
  promise: Promise<Response>,
  opts: { error?: string } = {}
) {
  const p = promise
    .then((res) => Promise.all([res.json(), res]))
    .then(([json, _res]: [any, Response]) => {
      if ('error' in json) {
        throw new Error(json.error)
      }
      return json
    })
  if ('error' in opts) {
    return p.catch((err) => {
      throw new Error(opts.error)
    })
  }

  return p as Promise<any>
}

type FetchOptions = RequestInit & {
  auth?: true
  error?: string
}
async function processFetchOptions(options?: FetchOptions) {
  // if (inDevelopment) await sleep(2000)

  if (!options) {
    return {}
  }

  let opts = {
    ...options,
  }
  if ('method' in opts && opts.method.toUpperCase() !== 'GET') {
    opts = {
      ...opts,
      headers: {
        ...opts.headers,
        'Content-Type': 'application/json; charset=utf-8',
      },
    }
  }
  if (_token && opts.auth) {
    opts = {
      ...opts,
      headers: {
        ...opts.headers,
        Authorization: `Bearer ${_token}`,
      },
    }
  }

  return opts
}

/**
 * Calls the api
 * @param url url to call
 * @param options request options, all those of fetch plus:
 * @param options.auth request needs authentication (the user token will be used)
 * @param options.error throw an exception with the specified error string instead of
 * whatever error comes from the api or from the browser itself.
 */
export function apiCall(url, options?: FetchOptions) {
  return processFetchOptions(options).then((opts) =>
    _processResponse(fetch(url, opts), opts)
  )
}

interface RetryOptions {
  retries?: number
  retryDelay?:
    | number
    | ((attempt: number, error: Error, response: Response) => number)
  retryOn?: number[]
}

/**
 * Calls the api with automatic retries
 * @param url
 * @param options
 */
export function apiCallRetry(url, options?: FetchOptions & RetryOptions) {
  return processFetchOptions(options).then((opts) =>
    _processResponse(fetchWithRetry(fetch, opts)(url, opts), opts)
  )
}
