export const PRE_ROUTE = 'ROUTE::PRE'
export const ROUTE = 'ROUTE::ROUTE'

export function onPreRoute(from: Location | null, to: Location) {
  return {
    type: PRE_ROUTE,
    payload: { from, to },
  }
}
export function onRoute(from: Location | null, to: Location) {
  return {
    type: ROUTE,
    payload: { from, to },
  }
}
