import * as Actions from '../actions/clientVehiclesActions'
import { Vehicle } from '../../types/Vehiculo'

export const version = 1
export const key = 'clientVehicles'

export const initialState = {
  loading: false as boolean,
  error: null as string | null,
  vehicles: [] as Vehicle[] | null,
}

export type ClientVehiclesState = typeof initialState

export const reducer = (state = initialState, action) => {
  switch (action.type) {
    case Actions.CLIENT_VEHICLES_FIND:
      return {
        ...state,
        loading: true,
      }
    case Actions.CLIENT_VEHICLES_FIND_OK:
      return {
        ...state,
        loading: false,
        vehicles: action.payload.vehicles,
      }
    case Actions.CLIENT_VEHICLES_FIND_FAILED:
      return {
        ...state,
        error: action.payload.error,
        loading: false,
      }
    default:
      return state
  }
}
