import {
  IMedida,
  MedidaFeature,
  registroMarca,
  SelectorModeloMoto,
  SelectorMotoState,
  URLOrigen,
} from '../reducers/selectorMotoReducer'
import { resetSearchMoto } from './searchMotoActions'
import { searchCamaraMotoReset } from './searchCamaraMotoActions'
import { Marca } from '../../components/withMarcas'
import {
  getMedidasFromModeloMoto,
  ResponseGetMedidas,
} from '../../api/selectorMoto'

export const SET_ORIGEN_MOTO = 'SELECTOR_MOTO::SET_ORIGEN'
export const SET_STEP_MOTO = 'SELECTOR_MOTO::SET_STEP'
export const SELECT_MEDIDA_MOTO = 'SELECTOR_MOTO::SELECT_MEDIDA'
export const LOAD_OPTIONS_MOTO = 'SELECTOR_MOTO::LOAD_OPTIONS'
export const SEARCH_MEDIDAS_MOTO = 'SELECTOR_MOTO::SEARCH_MEDIDAS_MOTO'
export const SET_MEDIDAS = 'SELECTOR_MOTO::SET_MEDIDAS'
export const ERROR_SEARCH_MEDIDAS_MOTO =
  'SELECTOR_MOTO::ERROR_SEARCH_MEDIDAS_MOTO'
export const SELECT_MARCA_MOTO = 'SELECTOR_MOTO::SELECT_MARCA'
export const RESET_MOTO = 'SELECTOR_MOTO::RESET'
export const BUSCAR_POR_MEDIDAS = 'SELECTOR_MOTO::BUSCAR_POR_MEDIDAS'
export const BUSCAR_POR_MODELO = 'SELECTOR_MOTO::BUSCAR_POR_MODELO'
export const RESET_OK_MOTO = 'SELECTOR_MOTO::RESET_OK'
export const LOAD_MARCAS_MOTO = 'SELECTOR_MOTO::LOAD_MARCAS'
export const LOAD_MARCAS_OK_MOTO = 'SELECTOR_MOTO::LOAD_MARCAS_OK'
export const CHANGE_FILTRO = 'SELECTOR_MOTO::CHANGE_FILTRO'
export const RESTORE_FILTRO = 'SELECTOR_MOTO::RESTORE_FILTRO'
export const REPORT_STEP_MOTO = 'SELECTOR_MOTO::REPORT_STEP'
export const RESTORE_MOTO = 'SELECTOR_MOTO::RESTORE'
export const RESET_MARCAS_MOTO = 'SELECTOR_MOTO::RESET_MARCAS_MOTO'
export const RESET_FEATURES_MODELO = 'SELECTOR_MOTO::RESET_FEATURES_MODELO'
export const SELECT_FEATURE_MODELO = 'SELECTOR_MOTO::SELECT_FEATURE_MODELO'
export const LOAD_OPTIONS_FEATURES_MODELO =
  'SELECTOR_MOTO::LOAD_OPTIONS_FEATURES_MODELO'

export const REGISTRAR_MARCA = 'SELECTOR_MOTO::REGISTRAR_MARCA'
export const SET_REGISTRO_MARCA = 'SELECTOR_MOTO::SET_REGISTRO_MARCA'

interface resetWithValues {
  keepMedidas: IMedida[]
  keepValues: true
}
interface resetWithoutValues {
  keepValues: false
}

export type ResetOptionsMoto = (resetWithValues | resetWithoutValues) & {
  origen: URLOrigen
  marca: number | null
}

export function setOrigen(origen: string) {
  return {
    type: SET_ORIGEN_MOTO,
    payload: origen,
  }
}

export function reset(options: ResetOptionsMoto) {
  return { type: RESET_MOTO, payload: options }
}

export function selectMedidaMoto(medida: IMedida) {
  medida.value = medida.value !== '-' ? medida.value : ''
  return {
    type: SELECT_MEDIDA_MOTO,
    payload: medida,
  }
}

export function loadOptions(options: Partial<SelectorMotoState>) {
  return {
    type: LOAD_OPTIONS_MOTO,
    payload: options,
  }
}

export function selectMarca(id_marca: number) {
  return {
    type: SELECT_MARCA_MOTO,
    payload: id_marca,
  }
}
export function changeFiltro(name: string, value: string, checked: boolean) {
  return {
    type: CHANGE_FILTRO,
    payload: {
      name,
      value,
      checked,
    },
  }
}
export function restoreFiltro(name: string, newValues?: string[]) {
  return {
    type: RESTORE_FILTRO,
    payload: {
      name,
      newValues,
    },
  }
}

export function restore(state: SelectorMotoState) {
  return {
    type: RESTORE_MOTO,
    payload: state,
  }
}

export function reportStep(step, device) {
  return {
    type: REPORT_STEP_MOTO,
    payload: {
      step,
      device,
    },
  }
}

export function resetMarca() {
  return { type: RESET_MARCAS_MOTO }
}

export function resetFeaturesModelo() {
  return { type: RESET_FEATURES_MODELO }
}

export function loadOptionsFeaturesModelo(
  options: Partial<SelectorModeloMoto>
) {
  return {
    type: LOAD_OPTIONS_FEATURES_MODELO,
    payload: options,
  }
}

export function selectFeatureModeloMoto(feature: MedidaFeature) {
  return {
    type: SELECT_FEATURE_MODELO,
    payload: feature,
  }
}
export function setMedidasFromModelo(medidas: ResponseGetMedidas) {
  return {
    type: SET_MEDIDAS,
    payload: medidas,
  }
}

export function setErrorMedidaMoto(error: boolean) {
  return {
    type: ERROR_SEARCH_MEDIDAS_MOTO,
    payload: { error },
  }
}

export function registrarMarcaMoto(device: 'MOBILE' | 'TABLET' | 'DESKTOP') {
  const userAgent = navigator.userAgent
  return {
    type: REGISTRAR_MARCA,
    payload: {
      device,
      userAgent,
    },
  }
}

export function updateRegistroMarca(data: registroMarca) {
  return {
    type: SET_REGISTRO_MARCA,
    payload: data,
  }
}

export async function buscarMedidasTyres(
  selectorMotoState: SelectorMotoState,
  dispatch: (action: object) => void
) {
  // lanzamos el evento para recoger las medidas del neumático
  const medidas = await getMedidasFromModeloMoto(
    selectorMotoState.selectorModelo
  )
  if (medidas.ancho && medidas.llanta) {
    dispatch(setMedidasFromModelo(medidas))
    return true
  } else {
    dispatch(setErrorMedidaMoto(true))
    return false
  }
}

export async function buscarPorMedidas(
  selectorMotoState: SelectorMotoState,
  dispatch: (action: object) => void
) {
  // reiniciamos la búsqueda de neumáticos
  await dispatch(resetSearchMoto())
  // reiniciamos la búsqueda de las cámaras
  await dispatch(searchCamaraMotoReset())
  // reiniciamos las medidas con el ancho anteriormente seleccionado
  await dispatch(
    selectFeatureModeloMoto({
      name: 'marca',
      value: selectorMotoState.selectorModelo.marca.value,
    })
  )
  await dispatch({ type: BUSCAR_POR_MEDIDAS })
}
export async function buscarPorModelo(
  selectorMotoState: SelectorMotoState,
  dispatch: (action: object) => void
) {
  // reiniciamos la búsqueda de neumáticos
  await dispatch(resetSearchMoto())
  // reiniciamos la búsqueda de las cámaras
  await dispatch(searchCamaraMotoReset())
  // reiniciamos las medidas con el ancho anteriormente seleccionado
  await dispatch(
    selectMedidaMoto({
      name: 'ancho',
      value: selectorMotoState.ancho.value,
    })
  )
  await dispatch({ type: BUSCAR_POR_MODELO })
}
