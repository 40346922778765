import { apiCall } from '../../../common/api/api'
import { Bateria, BornesBateria, TipoBateria } from '../type/bateria.d'
import { OrderEntry } from '../type/order'
import { GetPagination, PaginationResult } from '../type/pagination.d'

export interface GetBateriasList {
  baterias: Bateria[]
  pagination: PaginationResult
}
export type FiltersParams = {
  marca: string[]
  startStop: boolean
  tipo_bateria: TipoBateria | ''
  amperaje: number[]
  potencia_arranque: number[]
  posicion_positivo: BornesBateria | ''
}
export type BateriasParams = {
  filters?: FiltersParams
  pagination: GetPagination
  order: OrderEntry
}

export function getBaterias(data: BateriasParams): Promise<GetBateriasList> {
  return apiCall(
    `${process.env.GATSBY_API_URL}/baterias`,

    {
      method: 'POST',
      auth: true,
      body: JSON.stringify(data),
    }
  )
}
