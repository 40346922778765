import { all, put, takeLatest } from 'redux-saga/effects'
import { t } from '../../../../common/i18n'
import {
  BATERIAS_FILTER_VALUES,
  bateriasFilterValuesKoAction,
  bateriasFilterValuesOkAction,
} from './bateriasFilterValuesAction'
import { getBateriasFilterValues as getBateriasFilterValuesApi } from '../../api/getBateriasFilterValues'

export function* getBateriasFilterValues() {
  try {
    const bateriasData = yield getBateriasFilterValuesApi()
    yield put(bateriasFilterValuesOkAction(bateriasData))
  } catch (err) {
    yield put(bateriasFilterValuesKoAction(t('No hay baterias')))
  }
}

function* sagas() {
  return all([
    yield takeLatest(BATERIAS_FILTER_VALUES, getBateriasFilterValues),
  ])
}

export default sagas
